import React from "react";
import "../../../components/Styles/ConsultantPendingLeads.css";
import ConsultantAssingmentsLeadsData from "./ConsultantAssingmentsLeadsData";
import { useState } from "react";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

const Dashboard = (props) => {
  const [leadsArray, setLeadsArray] = useState([]);
  const [leadsAssingmentsData, setLeadsAssingmentsData] = useState([]);
  const [leadsBasicData, setLeadsBasicData] = useState([]);
  const [leadsDetailData, setLeadsDetailData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllLeads = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/get_all_leads")
      .then((response) => response.json())
      .then(async (data) => {
        setLeadsBasicData(data);
        await fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_details")
          .then((response2) => response2.json())
          .then(async (data2) => {
            setLeadsDetailData(data2);
            await fetch(
              "https://porownywarkachfapi.ftpeople.pl/get_leads_assingments"
            )
              .then((response3) => response3.json())
              .then((data3) => {
                setLeadsAssingmentsData(data3);
                //prepareLeadsArray();
                var arr = data;
                var arrAss = arr.filter((el) =>
                  checkIfLeadAssingment(el.id, data3)
                );
                setLeadsArray(sortArrayByDate(arrAss));
                setLoading(false);
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const checkIfLeadAssingment = (id, arr) => {
    var arrTest = arr.filter((el) => el.userId === id);

    if (arrTest.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const sortArrayByDate = (arr) => {
    var arrN = arr.sort(function (a, b) {
      return new Date(b.addDate) - new Date(a.addDate);
    });
    return arrN;
  };

  const checkInAssingmentByLeadId = (id) => {
    var arr = props.leadsAssingmentsData.filter((lead) => id === lead.userId);
    return arr.length > 0 ? true : false;
  };

  const checkIfAssingment = (array) => {
    var arrFiltered = [];
    array.map((lead) => {
      if (checkInAssingmentByLeadId(lead.id)) {
        arrFiltered.push(lead);
      }
    });

    return arrFiltered;
  };

  const prepareLeadsArray = () => {
    var arrT = sortArrayByDate(props.leadsBasicData);
    var arrFinal = checkIfAssingment(arrT);
    setLeadsArray(arrFinal);
  };

  const getSubArray = (idx, _length, _array) => {
    return _array.slice(idx, idx + _length);
  };

  useEffect(() => {
    setLoading(true);
    fetchAllLeads();
  }, []);

  const changePage = (e) => {
    prepareLeadsArray(e);
  };

  const refreshPage = (e) => {
    setLoading(true);
    fetchAllLeads();
  };

  const getLeadDetailData = (id) => {
    return props.leadsDetailData.filter((lead) => lead.leadId === id)[0];
  };

  return (
    <>
      <div className="mainContainerConsultantPendingLeads">
        {loading ? (
          <ThreeDots color="black" height={50} width={50} />
        ) : (
          <>
            <button
              style={{ width: "30%" }}
              className="pendingLeadsButton"
              value={8}
              onClick={refreshPage}
            >
              ODŚWIEŻ
            </button>
            <div style={{ marginTop: "1vh" }}>
              <p style={{ fontSize: "2vw" }}>WYNIKI W TYM MIESIĄCU</p>
              <table className="leadsPendingDataTable">
                <tr>
                  <th style={{ width: "10%" }}>Id</th>
                  <th style={{ width: "60%" }}>Nazwa</th>
                  <th style={{ width: "30%" }}>Ilość przypisań</th>
                </tr>
                {props.companiesList
                  .filter((el) => +el.active === 1)
                  .map((data) => (
                    <>
                      <tr style={{ height: "3vh" }}>
                        <td>{data.id}</td>
                        <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                          {data.name}
                        </td>
                        <td>
                          {
                            leadsAssingmentsData.filter(
                              (el) =>
                                el.companyId === data.id &&
                                new Date(el.dateAssingment).getFullYear() ===
                                  new Date().getFullYear() &&
                                new Date(el.dateAssingment).getMonth() ===
                                  new Date().getMonth()
                            ).length
                          }
                        </td>
                      </tr>
                    </>
                  ))}
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
