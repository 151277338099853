import React from 'react';
import '../../../components/Styles/ToggleSwitchYesNo.css';

const ToggleSwitch = (props) => {
    return ( 
        <>
            <label className="toggle-switchYesNo">
            <input type="checkbox" id={props.id} name={props.name} checked={props.toggleChecked} onChange={props.onChangeThirdPageToggle}/>
            <span className="switch" />
            </label>
        </>
     );
}
 
export default ToggleSwitch;