import React from 'react';
import '../../../components/Styles/CreditEarlyRepaymentChange.css';
import '../../../components/Styles/FormStyle.css';

const CreditEarlyRepaymentChange = (props) => {

    const earlyRepaymentChangeData = props.creditEarlyRepaymentChanges.map(creditEarlyRepaymentChange => (
        <>
            <tr key={creditEarlyRepaymentChange.key}>
                    <td>
                        {creditEarlyRepaymentChange.date}
                    </td>
                    <td>
                        {creditEarlyRepaymentChange.value}
                    </td>
                    <td>
                        {creditEarlyRepaymentChange.currency}
                    </td>
                    <td>
                        <button id="subrow_button_table" className="submitButton" value={creditEarlyRepaymentChange.key} onClick={props.handleDeleteCreditEarlyRepaymentChange}>Usuń</button>
                    </td>
            </tr>
        </>
    ))

    const lenghtChangeDataStructure = (
        <table className="earlyRepaymentChangeTable">
                <tbody>
                    <tr>
                        <th>Data</th>
                        <th>Wartość</th>
                        <th>Waluta</th>
                        <th>Akcja</th>
                    </tr>
                    {earlyRepaymentChangeData}
                </tbody>
        </table> 
    )

    return(
        <>
            <div className="additionalInfoRowCreditEarlyRepayment">
                <div className="additionalInfoRowCreditEarlyRepaymentControls">
                    <div className="additionalInfoRowCreditEarlyRepayment_col1a">
                        <input type="number" id="subrow_input2" name="actualCreditEarlyRepaymentChangeValue" onFocus={props.handleFocus} value={props.actualCreditEarlyRepaymentChangeRowValue} onChange={props.handleActualCreditEarlyRepaymentChangeRowValueChange}/>
                    </div>
                    <div className="additionalInfoRowCreditEarlyRepayment_col2a">
                        <input type="date" id="subrow_input2" name="actualCreditEarlyRepaymentChangeDate" value={props.actualCreditEarlyRepaymentChangeRowDate} onChange={props.handleActualCreditEarlyRepaymentChangeRowDateChange}/>
                    </div>
                    <div className="additionalInfoRowCreditEarlyRepayment_col3a">
                        <select name="creditEarlyRepaymentCurrencyChange" id="subrow_input3"  className="currencySelect" value={props.actualCreditEarlyRepaymentChangeRowCurrency} onChange={props.handleActualCreditEarlyRepaymentChangeRowCurrencyChange}>
                            <option value="PLN">PLN</option>
                            <option value="CHF">CHF</option>
                        </select>
                    </div>
                    <div className="additionalInfoRowCreditEarlyRepayment_col4a">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditEarlyRepaymentChange}>DODAJ</button>
                    </div>
                </div>
                <div className="additionalInfoRowCreditEarlyRepayment_col4a_mobile">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditEarlyRepaymentChange}>DODAJ</button>
                    </div>
                <div className="tableRow">
                    {props.creditEarlyRepaymentChanges.length === 0 ? '' : lenghtChangeDataStructure}
                </div>
            </div>  
        </>
    )
}

export default CreditEarlyRepaymentChange;