import React from "react";
import '../../../components/Styles/PopUp.css';

const PopUpJudgments = (props) => {
    
    const handleClick = (e) => {
        props.toggle(e);
    };

    const judgmentsList = (
        <>
            <div style={{padding: "2%"}} className="companyOfferMainContainer">
                <table className="companyOfferTable" style={{width: "100%", fontSize: "1vw"}}>
                        <tbody>
                            <tr>
                                <th style={{width: "10%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Data</th>
                                <th style={{width: "15%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Sygnatura</th>
                                <th style={{width: "15%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Sąd</th>
                                <th style={{width: "15%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Bank</th>
                                <th style={{width: "10%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Prawomocnie?</th>
                                <th style={{width: "15%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Notatka</th>
                                <th style={{width: "10%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Korzyści</th>
                            </tr>
                            {props.companyJudgments.sort(function(a,b){
                                return new Date(b.date) - new Date(a.date);
                            }).map(el => (
                                <tr key={el.id}>
                                    <td>
                                        {el.date}
                                    </td>
                                    <td>
                                        {el.sygn}
                                    </td>
                                    <td>
                                        {el.court}
                                    </td>
                                    <td>
                                        {el.bank}
                                    </td>
                                    <td>
                                        {el.real_win}
                                    </td>
                                    <td>
                                        {el.note}   
                                    </td>
                                    <td>
                                        {el.benefits}   
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                </table> 
            </div>
        </>
    )

    const judgmentsListMobile = (
        <>
            <div style={{padding: "2%"}} className="companyOfferMainContainer">
                <table className="companyOfferTable" style={{width: "100%", fontSize: "2vw"}}>
                        <tbody>
                            <tr>
                                <th style={{width: "10%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Data</th>
                                <th style={{width: "15%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Sygnatura</th>
                                <th style={{width: "15%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Bank</th>
                                <th style={{width: "10%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Prawomocnie?</th>
                                <th style={{width: "10%", padding: "0", backgroundColor: "#b6d6ccff", fontWeight: "900", textTransform: "uppercase"}}>Korzyści</th>
                            </tr>
                            {props.companyJudgments.sort(function(a,b){
                                return new Date(b.date) - new Date(a.date);
                            }).map(el => (
                                <tr key={el.id}>
                                    <td>
                                        {el.date}
                                    </td>
                                    <td>
                                        {el.sygn}
                                    </td>
                                    <td>
                                        {el.bank}
                                    </td>
                                    <td>
                                        {el.real_win}
                                    </td>
                                    <td>
                                        {el.benefits}   
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                </table> 
            </div>
        </>
    )
    
    return (
     <div id="modalJ" className="modal">
       <div className="modal_content">
       <button className="close" value='close' onClick={handleClick}>➜</button>

        <div className="mainContainerPopUpSection">
            <div id="titleJ" style={{width: "100%", textAlign: "center", marginTop: "3vh", fontFamily: "Cinzel", marginBottom: "1vh"}}>
                LISTA WYROKÓW: {props.name}
            </div>
            <div className="jList" style={{overflowY: "scroll"}}>
                {judgmentsList}
            </div>
            <div className="jListMobile" style={{overflowY: "scroll"}}>
                {judgmentsListMobile}
            </div>
        </div>

      </div>
     </div>
    );
  }

export default PopUpJudgments;