import React from "react";
import "../../../components/Styles/ConsultantPendingLeads.css";
import ConsultantIndividualCampaignData from "./ConsultantIndividualCampaignData";
import { useState } from "react";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

const ConsultantIndividualCampaign = (props) => {
  const [leadsArray, setLeadsArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadsAssingmentsData, setLeadsAssingmentsData] = useState([]);
  const [leadsBasicData, setLeadsBasicData] = useState([]);
  const [leadsDetailData, setLeadsDetailData] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [leadsDeleteData, setLeadsDeleteData] = useState([]);
  const [leadsNote, setLeadsNote] = useState([]);
  const [leadsMark, setLeadsMark] = useState([]);

  const setCompanyIdSel = (e) => {
    setCompanyId(+e.target.value);
  };

  const fetchAssingmentsLeads = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_assingments")
      .then((response) => response.json())
      .then((data) => {
        setLeadsAssingmentsData(data);
        prepareLeadsArray();
      })
      .catch((error) => {});
  };

  const fetchDetailLeads = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_details")
      .then((response) => response.json())
      .then((data) => {
        setLeadsDetailData(data);
      })
      .catch((error) => {});
  };

  const fetchAllLeads = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/get_all_leads")
      .then((response) => response.json())
      .then(async (data) => {
        setLeadsBasicData(data);
        await fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_details")
          .then((response2) => response2.json())
          .then(async (data2) => {
            setLeadsDetailData(data2);
            await fetch(
              "https://porownywarkachfapi.ftpeople.pl/get_leads_delete"
            )
              .then((response4) => response4.json())
              .then(async (data4) => {
                setLeadsDeleteData(data4);
                await fetch(
                  "https://porownywarkachfapi.ftpeople.pl/get_leads_note"
                )
                  .then((response5) => response5.json())
                  .then(async (data5) => {
                    setLeadsNote(data5);
                    await fetch(
                      "https://porownywarkachfapi.ftpeople.pl/get_leads_mark"
                    )
                      .then((response6) => response6.json())
                      .then(async (data6) => {
                        setLeadsMark(data6);
                        await fetch(
                          "https://porownywarkachfapi.ftpeople.pl/get_leads_assingments"
                        )
                          .then((response3) => response3.json())
                          .then((data3) => {
                            setLeadsAssingmentsData(data3);
                            //prepareLeadsArray();
                            var arr = data;
                            var arrAss = arr.filter((el) =>
                              checkIfLeadAssingment(el.id, data3)
                            );
                            var arrInd = arrAss.filter(
                              (el) =>
                                checkIfLeadAssingmentForCompany(el.id, data3) &&
                                el.arch !== "1"
                            );
                            setLeadsArray(sortArrayByDate(arrInd));
                            setLoading(false);
                          })
                          .catch((error) => {});
                      })
                      .catch((error) => {});
                  })
                  .catch((error) => {});
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const sortArrayByDate = (arr) => {
    var arrN = arr.sort(function (a, b) {
      return new Date(b.addDate) - new Date(a.addDate);
    });
    return arrN;
  };

  const checkIfLeadAssingment = (id, arr) => {
    var arrTest = arr.filter((el) => el.userId === id);

    if (arrTest.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfLeadAssingmentForCompany = (id, arr) => {
    var arrTest = arr.filter(
      (el) => +el.userId === +id && +el.companyId === +companyId
    );
    if (id === 3279) {
      console.log(arrTest);
    }
    if (arrTest.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const prepareLeadsArray = () => {
    var arr = leadsBasicData;
    var arrAss = arr.filter((el) => checkIfLeadAssingment(el.id));
    var arrInd = arrAss.filter((el) => checkIfLeadAssingmentForCompany(el.id));
    setLeadsArray(sortArrayByDate(arrInd));
    setLoading(false);
  };

  useEffect(() => {}, []);

  const refreshPage = async (e) => {
    if (companyId === "") {
      return;
    }
    setLeadsAssingmentsData([]);
    setLeadsBasicData([]);
    setLeadsDetailData([]);
    setLeadsArray([]);
    setLoading(true);
    await fetchAllLeads();
  };

  const getLeadDetailData = (id) => {
    return leadsDetailData.filter((lead) => lead.leadId === id)[0];
  };

  const companiesSelect = (
    <>
      <select
        style={{ height: "3vh" }}
        onChange={setCompanyIdSel}
        value={companyId}
      >
        {companyId === "" ? <option value={0}>{"Wybierz..."}</option> : ""}
        {props.companies
          .filter((el) => el.active === 1)
          .map((el2) => (
            <>
              <option value={el2.id}>{el2.name}</option>
            </>
          ))}
      </select>
    </>
  );

  return (
    <>
      <div className="mainContainerConsultantPendingLeads">
        {loading ? (
          <ThreeDots color="black" height={50} width={50} />
        ) : (
          <>
            <div style={{ marginBottom: "2vh", marginTop: "2vh" }}>
              {companiesSelect}
            </div>
            <button
              style={{ width: "30%", marginBottom: "5vh" }}
              className="pendingLeadsButton"
              value={9}
              onClick={refreshPage}
            >
              POKAŹ
            </button>
            <h3>Ilość leadów: {leadsArray.length}</h3>
            <ConsultantIndividualCampaignData
              leadsBasicData={leadsArray}
              getLeadDetailData={getLeadDetailData}
              companiesList={props.companiesList}
              addAssingmentsToDatabase={props.addAssingmentsToDatabase}
              companyId={companyId}
              refreshPage={refreshPage}
              user={props.user}
              leadsNote={leadsNote}
              companiesData={props.companiesData}
              leadsMark={leadsMark}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ConsultantIndividualCampaign;
