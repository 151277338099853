import React from 'react';
import '../../../components/Styles/AdminCompanyUsers.css';

const AdminCompanyUsers = (props) => {

return(
    <>
        <div className="mainContainerAdminCompanyUsers">
            <h1>Admin Company Users</h1>
        </div>
    </>
)

}

export default AdminCompanyUsers;