class CreditSubsequentsTranchesChange {
    constructor(key, date, value, currency){
        this.key = key;
        this.date = date;
        this.value = value;
        this.currency = currency;
        console.log('new object');
    }
}

export default CreditSubsequentsTranchesChange;