import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react';
import '../../../components/Styles/CompaniesList.css';
import ChooseCheckBox from "./ChooseCheckBox";
import CompanyOffer from "./CompanyOffer";
import NextButtonBox from "./NextButtonBox";
import PopUp from "./PopUp";
import PopUpJudgments from "./PopUpJudgments";

const CompaniesList = (props) => {
    const [seenPopUp, setSeenPopUp] = useState(false);
    const [seenPopUpJudgments, setSeenPopUpJudgments] = useState(false);
    const [idPopUp, setIdPopUp] = useState(1);
    const [idSeenOffer, setIdSeenOffer] = useState(0);
    const [seenOffer, setSeenOffer] = useState(false);
    const [chooseCompanyArray, setChooseCompanyArray] = useState([]);
    const [choosenCompany, setChoosenCompany] = useState(false);
    const [assingmentPossibilityFlag, setAssingmentPossibilityFlag] = useState(1);

    var companiesOffer = props.companiesOffer.sort(function(a, b) {
        return a.entranceeCost - b.entranceeCost;
    });

    const togglePop = (e) => {
        setSeenPopUp(!seenPopUp);
        setIdPopUp(+e.target.value);
    };

    const togglePopJudgments = (e) => {
        setSeenPopUpJudgments(!seenPopUpJudgments);
        setIdPopUp(+e.target.value);
    };

    const showOffer = (e) => {
        if(seenOffer && +e.target.value === idSeenOffer){
            setSeenOffer(!seenOffer);
        }else if(!seenOffer){
            setSeenOffer(!seenOffer);
        }
        setIdSeenOffer(+e.target.value);
    };

    const getCompanyDescription = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "description");
        return desc ? desc.descText : 'Kancelaria';
    }

    const getCompanyOfferMin = (companyId) => {
        var desc = companiesOffer.filter(company => company.companyId === companyId).shift();
        return desc ? desc.entranceeCost : 0;
    }

    const getCompanyWinCases = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "winCases");
        return desc ? desc.descText : '0';
    }

    const getCompanyWinRealCases = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "winRealCases");
        return desc ? desc.descText : '0';
    }

    const getCompanyCaseCount = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "caseCount");
        return desc ? desc.descText : '0';
    }

    const getCompanyMarketYear = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "marketYear");
        return desc ? desc.descText : '0';
    }

    const getCompanyTitle = (companyId) => {
        var desc = 'Kancelaria Frankowa';
        desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "title");
        return desc ? desc.descText.replace("<br>"," ") : 'Kancelaria';
    }

    const getCompanyLogo = (companyId) => {
        var logo = props.companiesFiles.find(company => company.companyId === companyId && company.fileType === "logo");
        return logo.fileUrl;
    }

    const getCompanyImage = (companyId) => {
        var image = props.companiesFiles.find(company => company.companyId === companyId && company.fileType === "image");
        return image.fileUrl;
    }

    const getMainListDetail = (companyId) => {
        if(props.sortMode === "cena"){
            return <><p className="detailTitle">Opłata wstępna od:</p><p className="detailText">{getCompanyOfferMin(companyId)} zł</p></>
        } else if(props.sortMode === "koszt"){
            return <><p className="detailTitle">Koszt sprawy od:</p><p className="detailText">{getCompanyMinCaseValue(companyId)} zł</p></>
        }else if(props.sortMode === "sprawy"){
            return <><p className="detailTitle">Ilość prawomocnych:</p><p className="detailText">{getCompanyWinRealCases(companyId) === '0' ? 'brak danych' : getCompanyWinRealCases(companyId)}</p></>
        }else {
            return <><p className="detailTitle">Opłata wstępna od:</p><p className="detailText">{getCompanyOfferMin(companyId)} zł</p></>
        }
    }

    const getCompanyMinCaseValue = (companyId) => {
        var company = props.companies.find(company => company.id === companyId);
        return company ? company.minCaseValue : 0;
    }

    useEffect(() => {
        if(new Date() < props.newAssingmentDate){
            setAssingmentPossibilityFlag(0);
            props.setAssingmentPossibilityFlag(0);
        }else{
            setAssingmentPossibilityFlag(1);
            props.setAssingmentPossibilityFlag(1);
        }
        
    },[])

    const checkChoose = (e) => {
       e.preventDefault();
       setChoosenCompany(true);
       var arr = chooseCompanyArray;
       if(arr.find(el => el === +e.target.value)){
        arr = arr.filter(function(ele){
            return ele !== +e.target.value; 
        });
        if(arr.length === 0){
            setChoosenCompany(false)
        }
       }else{
        arr.push(+e.target.value);
       }
       setChooseCompanyArray(arr);
    }   

    const companiesList = props.companies.map(company => (
        <>
            <div className="companyBox">
                <div className="companyFirstCol">
                <div className="companyFirstLineChoose">
                    {assingmentPossibilityFlag === 1 ? 
                            <ChooseCheckBox 
                                key={company.id}
                                choosenArray = {chooseCompanyArray}
                                checkChoose = {checkChoose}
                                companyId = {company.id}
                                refreshedButtons = {props.refreshedButtons}
                            />
                        : ''}
                </div>
                    <div className="companyFirstLine">
                        <div className="companyLogoBox">
                            <img className="companyLogo" alt={company.name} src={getCompanyLogo(company.id)}/>
                        </div>
                        <div className="companyTitle" key={company.id}><Markup content={getCompanyTitle(company.id)}/></div>
                    </div>
                </div>
                <div className="companySecondCol">
                    <div className="companyFirstLine">
                        <div className="companyDetailsBox">
                            <p className="detailTitle">Ilość wygranych:</p>
                            <p className="detailText">{getCompanyWinCases(company.id) === '0' ? 'brak danych' : getCompanyWinCases(company.id)}</p>
                        </div>
                        <div className="companyDetailsBox">
                                {getMainListDetail(company.id)}
                        </div>
                        <div id="lastDetailBox" className="companyDetailsBox">
                            <button className="moreButton" value={company.id} onClick={togglePop}>WIĘCEJ INFORMACJI</button> 
                            <button className="offerButton" value={company.id} onClick={showOffer}>{seenOffer && idSeenOffer === company.id ? "UKRYJ OFERTĘ" : "POKAŻ OFERTĘ"}</button> 
                            {props.companyJudgments.filter(el => +el.companyId === +company.id).length > 0 ?
                            <button className="offerButton" value={company.id} onClick={togglePopJudgments}>LISTA WYROKÓW</button> 
                            : ''}
                        </div>
                    </div>
                </div>
                <div className="companyFirstLineMobile">
                        <div className="companyDetailsBox">
                            <p className="detailTitle">Ilość wygranych:</p>
                            <p className="detailText">{getCompanyWinCases(company.id)}</p>
                        </div>
                        <div className="companyDetailsBox">
                                {getMainListDetail(company.id)}
                        </div>
                        <div id="lastDetailBox" className="companyDetailsBox">
                            <button className="moreButton" value={company.id} onClick={togglePop}>WIĘCEJ INFORMACJI</button> 
                            <button className="offerButton" value={company.id} onClick={showOffer}>{seenOffer && idSeenOffer === company.id ? "UKRYJ OFERTĘ" : "POKAŻ OFERTĘ"}</button> 
                            {props.companyJudgments.filter(el => +el.companyId === +company.id).length > 0 ?
                            <button className="offerButton" value={company.id} onClick={togglePopJudgments}>LISTA WYROKÓW</button> 
                            : ''}
                        </div>
                </div>
                {seenOffer && idSeenOffer === company.id ?
                <div className="companyOfferBox">
                    <CompanyOffer 
                        companyOfferList={companiesOffer.filter(company => company.companyId === idSeenOffer)}
                        analysis = {props.analysis}
                        userDetailData = {props.userDetailData}
                        userCreditValue = {props.userCreditValue}
                    />
                </div>
                : ''}
            </div>
        </>
    ))

    return(
        <>
        {companiesList}
        {choosenCompany ?
            <NextButtonBox 
                chooseQuantity={chooseCompanyArray.length}
                chooseCompanyArray = {chooseCompanyArray}
                onChooseCompanies={props.onChooseCompanies}
            />
        : ''}
        {seenPopUp ? 
            <PopUp 
                companyWinRealCases={getCompanyWinRealCases(idPopUp)} 
                companyWinCases={getCompanyWinCases(idPopUp)} 
                companyCaseCount={getCompanyCaseCount(idPopUp)} 
                companyMarketYear={getCompanyMarketYear(idPopUp)} 
                companyTitle={getCompanyTitle(idPopUp)} 
                companyLogo={getCompanyLogo(idPopUp)} 
                companyImage={getCompanyImage(idPopUp)} 
                companyDescription={getCompanyDescription(idPopUp)}  
                value={idPopUp} 
                toggle={togglePop} 
                onClickDetailsAnalysis={props.onClickDetailsAnalysis}
                analysis={props.analysis}
            /> : null}
            {seenPopUpJudgments ? 
            <PopUpJudgments 
                companyJudgments={props.companyJudgments.filter(el => +el.companyId === +idPopUp)} 
                name = {getCompanyTitle(idPopUp)}
                value={idPopUp} 
                toggle={togglePopJudgments} 
            /> : null}
        </>
    )
}

export default CompaniesList;