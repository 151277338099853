import '../../../components/Styles/AdminOfferList.css';
import swal from 'sweetalert';

const OfferList = (props) => {

    const fetchOfferDelete = async (id) => {

        var body = {
            offerId: id  
        };

        await fetch('https://porownywarkachfapi.ftpeople.pl/delete_offer?company='+encodeURI(props.company), {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

    const deleteOffer = (e) => {
        props.startLoading(true);
        fetchOfferDelete(e.target.value);
    }

    var companyOfferList = props.companyOffers.sort(function(a, b) {
        return a.entranceeCost - b.entranceeCost;
    });

    const getOfferTypeName = (type) => {
        switch(type){
            case 'benefitValue': return 'SF od wartości korzyści';
            case 'creditValue': return 'SF od wartości kredytu';
            case 'subscription': return 'Abonament';
            default: return 'Nie znaleziono';
        }
    }

    

    const companyOfferData = companyOfferList.map(companyOffer => (
        <>
            <tr key={companyOffer.id}>
                    <td>
                        {companyOffer.name}
                    </td>
                    <td>
                        {getOfferTypeName(companyOffer.type)}
                    </td>
                    <td>
                        {companyOffer.entranceeCost+' PLN'}
                    </td>
                    <td>
                        {companyOffer.successFee}{companyOffer.successFeeType === '%' ? companyOffer.successFeeType : ' '+  companyOffer.successFeeType}
                    </td>
                    <td>
                        {companyOffer.installment}
                    </td>
                    <td>
                        {companyOffer.options.length === 0 ? '' : <ol className="optionsList">{companyOffer.options.split(',').map(option=><li key={option}>{option}</li>)}</ol>}   
                    </td>
                    <td>
                        <button id="subrow_button_table" className="submitButton" value={companyOffer.id} onClick={deleteOffer}>Usuń</button>
                    </td>
            </tr>
        </>
    ));

    const companyOfferDataStructure = (
        <>
            <div className="companyOfferMainContainer">
                <table className="companyOfferTable">
                        <tbody>
                            <tr>
                                <th>Nazwa</th>
                                <th>Rodzaj</th>
                                <th>Opłata wstępna</th>
                                <th>Success Fee</th>
                                <th>Ilość rat</th>
                                <th>Opcje dodatkowe</th>
                                <th>Akcja</th>
                            </tr>
                            {companyOfferData}
                        </tbody>
                </table> 
            </div>
        </>
    )

return(
    <>
        {companyOfferDataStructure}
    </>
)

}

export default OfferList;