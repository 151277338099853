import { useState, useEffect } from 'react';
import '../../../components/Styles/AdminCompanyDetails.css';
import { ThreeDots } from  'react-loader-spinner';
import swal from 'sweetalert';

const AdminCompanyJudgments = (props) => {
    const [loading, setLoading] = useState(true);
    const [companyJudgments, setCompanyJudgments] = useState([]);
    const [date, setDate] = useState('');
    const [sygn, setSygn] = useState('');
    const [court, setCourt] = useState('');
    const [bank, setBank] = useState('');
    const [note, setNote] = useState('');
    const [realWin, setRealWin] = useState('');
    const [benefits, setBenefits] = useState('');

    const fetchCompanyJudgments = async (e) => {
        
        var body = {
            companyId: props.company,
        };
    
        await fetch('https://porownywarkachfapi.ftpeople.pl/get_judgments_id_company', {
            method: 'POST', 
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(data => {
            setCompanyJudgments(data.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            }));
            setLoading(false);
        })
        .catch(error => {
            
        })
    }
    
    const getData = () => {
        fetchCompanyJudgments();
    }

    const fetchJudgmentDelete = async (e) => {
        setLoading(true);

        var body = {
            jId: e.target.value
        };

        await fetch('https://porownywarkachfapi.ftpeople.pl/delete_judgment', {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.text())
        .then(data => {
            getData();
        })
        .catch(error => {
            
        })
    }

    useEffect(() => {
        getData();
    },[]);

    const fetchJudgmentInsert = async () => {

        var body = {
            date: date,
            bank: bank,
            court: court,
            note: note,
            benefits: benefits,
            realWin: realWin,
            sygn: sygn,
            companyId: props.company, 
        };

        await fetch('https://porownywarkachfapi.ftpeople.pl/insert_judgment', {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.text())
        .then(data => {
            setDate('');
            setBank('');
            setCourt('');
            setNote('');
            setBenefits('');
            setRealWin('');
            setSygn('');

            getData();
        })
        .catch(error => {
            
        })
    }

    const validateInput = () => {
        if(date && bank && sygn && realWin && court && benefits){
            return true;
        }else{
            return false;
        }
    }

    const insertNewJudgment = () => {
        if(validateInput()){
            setLoading(true);
            fetchJudgmentInsert();
        }else{
            swal({
                icon: "error",
                text: "Niepoprawne dane formularza!",
                button: {
                  text: "Ok",
                },
            });
        }
    }

    const judgmentsList = (
        <>
            <div className="companyOfferMainContainer">
                <table className="companyOfferTable" style={{width: "95%", fontSize: "0.6vw"}}>
                        <tbody>
                            <tr>
                                <th style={{width: "10%", padding: "0"}}>Data</th>
                                <th style={{width: "15%", padding: "0"}}>Sygnatura</th>
                                <th style={{width: "15%", padding: "0"}}>Sąd</th>
                                <th style={{width: "15%", padding: "0"}}>Bank</th>
                                <th style={{width: "10%", padding: "0"}}>Prawomocnie?</th>
                                <th style={{width: "15%", padding: "0"}}>Notatka</th>
                                <th style={{width: "10%", padding: "0"}}>Korzyści</th>
                                <th style={{width: "10%", padding: "0"}}>Akcja</th>
                            </tr>
                            {companyJudgments.map(el => (
                                <tr key={el.id}>
                                    <td>
                                        {el.date}
                                    </td>
                                    <td>
                                        {el.sygn}
                                    </td>
                                    <td>
                                        {el.court}
                                    </td>
                                    <td>
                                        {el.bank}
                                    </td>
                                    <td>
                                        {el.real_win}
                                    </td>
                                    <td>
                                        {el.note}   
                                    </td>
                                    <td>
                                        {el.benefits}   
                                    </td>
                                    <td style={{textAlign: "center"}}>
                                        <button style={{fontSize: "0.6vw"}} id="subrow_button_table" className="submitButton" value={el.id} onClick={fetchJudgmentDelete}>Usuń</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                </table> 
            </div>
        </>
    )

return(
    <>
        <div className="mainContainerAdminCompanyDetails">
        {loading ?
            <ThreeDots/>
            :
            <>
                <div className="addDetailsInputsBox">

                    <div className="addDetailsInputBox" style={{padding: "1%", width: "45%"}}>

                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            Wybierz bank <span style={{color: "red"}}>*</span>
                        </p>
                        <select style={{boxSizing: "border-box", width: "100% !important", height: "4vh"}} className="addOfferAdminInputDefault" type="date" name="entranceeCost" value={bank} onChange={(e) => {setBank(e.target.value)}}> 
                            {!bank ? 
                            <>
                                <option value={'Wybierz...'}>Wybierz...</option>
                            </> : ''}
                            <option value='mBank S.A.'>mBank S.A.</option>
                            <option value='ING Bank Śląski S.A.'>ING Bank Śląski S.A.</option>
                            <option value='Getin Noble Bank S.A.'>Getin Noble Bank S.A.</option>
                            <option value='Raiffeisen Bank International AG'>Raiffeisen Bank International AG</option>
                            <option value='PKO BP S.A.'>PKO BP S.A.</option>
                            <option value='Santander Consumer Bank S.A.'>Santander Consumer Bank S.A.</option>
                            <option value='BPH S.A.'>BPH S.A.</option>
                            <option value='Bank Millenium S.A.'>Bank Millenium S.A.</option>
                            <option value='BNP Paribas S.A.'>BNP Paribas S.A.</option>
                            <option value='Pekao S.A.'>Pekao S.A.</option>
                            <option value='Santander Bank Polska S.A.'>Santander Bank Polska S.A.</option>
                            <option value='Credit Agricole Bank Polska S.A.'>Credit Agricole Bank Polska S.A.</option>
                            <option value='Bank Ochrony Środowiska S.A.'>Bank Ochrony Środowiska S.A.</option>
                            <option value='Deutsche Bank S.A.'>Deutsche Bank S.A.</option>
                            <option value='Bank Millennium S.A.'>Bank Millennium S.A.</option>
                            <option value='Santander Bank Polska S.A. (dawny Kredyt Bank S.A.)'>Santander Bank Polska S.A. (dawny Kredyt Bank S.A.)</option>
                            <option value='Raiffeisen Bank International AG (dawny Polbank)'>Raiffeisen Bank International AG (dawny Polbank)</option>
                            <option value='Pekao S.A. (dawny PEKAO Bank Hipoteczny S.A.)'>Pekao S.A. (dawny PEKAO Bank Hipoteczny S.A.)</option>
                            <option value='Bank Millennium S.A. (dawny Eurobank S.A.)'>Bank Millennium S.A. (dawny Eurobank S.A.)</option>
                            <option value='Raiffeisen Bank Polska S.A.'>Raiffeisen Bank Polska S.A.</option>
                            <option value='Santander Bank Polska S.A. (dawny BZ WBK S.A.)'>Santander Bank Polska S.A. (dawny BZ WBK S.A.)</option>
                            <option value='BPH S.A. (dawny GE Money Bank)'>BPH S.A. (dawny GE Money Bank)</option>
                            <option value='PKO BP S.A. (dawna Nordea Bank Polska S.A.)'>PKO BP S.A. (dawna Nordea Bank Polska S.A.)</option>
                            <option value='Credit Agricole Bank Polska S.A. (dawniej Lukas Bank S.A.)'>Credit Agricole Bank Polska S.A. (dawniej Lukas Bank S.A.)</option>
                            <option value='BNP Paribas S.A. (dawny BGŻ S.A.)'>BNP Paribas S.A. (dawny BGŻ S.A.)</option>
                            <option value='Reiffeisen Bank Polska S.A.'>Reiffeisen Bank Polska S.A.</option>
                            <option value='BNP Paribas S.A. (dawny Fortis Bank Polska S.A.)'>BNP Paribas S.A. (dawny Fortis Bank Polska S.A.)</option>
                        </select>


                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            Wprowadź datę wydania wyroku <span style={{color: "red"}}>*</span>
                        </p>
                        <input style={{boxSizing: "border-box", width: "100% !important"}} className="addOfferAdminInputDefault" type="date" name="entranceeCost" value={date} onChange={(e) => {setDate(e.target.value)}}/> 

                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            Wprowadź nazwę sądu, który wydał wyrok <span style={{color: "red"}}>*</span>
                        </p>
                        <textarea style={{boxSizing: "border-box", width: "100%"}} className="addressAdminInput" type="text" name="title" rows="4" maxLength="1000" value={court} onChange={(e) => {setCourt(e.target.value)}}/>

                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            Wprowadź zasądzone kwoty <span style={{color: "red"}}>*</span>
                        </p>
                        <textarea style={{boxSizing: "border-box", width: "100%"}} className="addressAdminInput" type="text" name="title" rows="4" maxLength="1000" value={benefits} onChange={(e) => {setBenefits(e.target.value)}}/>

                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            
                        </p>
                        <button id="subrow_button_table" className="submitButton" onClick={insertNewJudgment}>Zapisz</button>

                    </div>
                    <div className="addDetailsInputBox" style={{padding: "1%", width: "45%"}}>

                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            Wyrok prawomocny?<span style={{color: "red"}}>*</span>
                        </p>
                        <select style={{boxSizing: "border-box", width: "100% !important", height: "4vh"}} className="addOfferAdminInputDefault" type="date" name="entranceeCost" value={realWin} onChange={(e) => {setRealWin(e.target.value)}}> 
                            {!realWin ? 
                            <>
                                <option value={'Wybierz...'}>Wybierz...</option>
                            </> : ''}
                            <option value={'TAK'}>TAK</option>
                            <option value={'NIE'}>NIE</option>
                        </select>

                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            Wprowadź sygnaturę wyroku <span style={{color: "red"}}>*</span>
                        </p>
                        <input style={{boxSizing: "border-box", width: "100% !important"}} className="addOfferAdminInputDefault" type="text" name="entranceeCost" value={sygn} onChange={(e) => {setSygn(e.target.value)}}/> 

                        <p className="subTitleInput" style={{minHeight: "2vh"}}>
                            Wprowadź krótką notatkę (opcjonalnie)
                        </p>
                        <textarea style={{boxSizing: "border-box", width: "100%"}} className="addressAdminInput" type="text" name="title" rows="4" maxLength="1000" value={note} onChange={(e) => {setNote(e.target.value)}}/>

                    </div>
                </div>

                <div style={{marginTop: "3vh"}}>
                        {judgmentsList}
                </div>
            </>}
        </div>
    </>
)

}

export default AdminCompanyJudgments;