import React from 'react';
import '../../../components/Styles/CreditLengthChange.css';
import '../../../components/Styles/FormStyle.css';

const CreditLengthChange = (props) => {

    const lengthChangeData = props.creditLengthChanges.map(creditLengthChange => (
        <>
            <tr key={creditLengthChange.key}>
                    <td>
                        {creditLengthChange.date}
                    </td>
                    <td>
                        {creditLengthChange.value}
                    </td>
                    <td>
                        <button id="subrow_button_table" className="submitButton" value={creditLengthChange.key} onClick={props.handleDeleteCreditLengthChange}>Usuń</button>
                    </td>
            </tr>
        </>
    ))

    const lenghtChangeDataStructure = (
        <table className="lengthChangeTable">
                <tbody>
                    <tr>
                        <th>Data</th>
                        <th>Wartość</th>
                        <th>Akcja</th>
                    </tr>
                    {lengthChangeData}
                </tbody>
        </table> 
    )

    return(
        <>
            <div className="additionalInfoRowCreditLength">
                <div className="additionalInfoRowCreditLengthControls">
                    <div className="additionalInfoRowCreditLength_col1">
                        <input type="number" id="subrow_input" name="actualCreditLengthChangeValue" onFocus={props.handleFocus} value={props.actualCreditLengthChangeRowValue} onChange={props.handleActualCreditLengthChangeRowValueChange}/>
                    </div>
                    <div className="additionalInfoRowCreditLength_col2">
                        <input type="date" id="subrow_input" name="actualCreditLengthChangeDate" value={props.actualCreditLengthChangeRowDate} onChange={props.handleActualCreditLengthChangeRowDateChange}/>
                    </div>
                    <div className="additionalInfoRowCreditLength_col3">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditLengthChange}>DODAJ</button>
                    </div>
                </div>
                <div className="additionalInfoRowCreditLength_col3_mobile">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditLengthChange}>DODAJ</button>
                    </div>
                <div className="tableRow">
                    {props.creditLengthChanges.length === 0 ? '' : lenghtChangeDataStructure}
                </div>
            </div>  
        </>
    )
}

export default CreditLengthChange;