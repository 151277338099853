import React from 'react';
import '../Styles/Steps.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular';

const Steps = (props) => {

    const changeStep = (e) => {
        console.log(e.target.value);
        props.changeStep(e);
    }

    const stepTitle = () => {
        if(props.stepNumber === 1){
            return "1. Informacje wstępne";
        } else if(props.stepNumber === 2){
            return "2. Dane kontaktowe";
        } else if(props.stepNumber === 3){
            return "3. Szczegółowa analiza";
        } else if(props.stepNumber === 5){
            return "Zaloguj się do Porównywarki CHF";
        } else if(props.stepNumber === 4){
            return "Porównaj oferty kancelarii CHF";
        } else if(props.stepNumber === 6){
            return "Potwierdź wybór kancelarii CHF";
        }
    }

    return(
        <>
            <div className="stepsContainer">
                <div className="stepTitle"><div className="stepTitleIco"><FontAwesomeIcon icon={faCheckCircle} /></div> {stepTitle()}</div>
                {props.stepNumber === 1 || props.stepNumber === 2 || props.stepNumber === 3
                ?
                    <div className="stepButtons">
                        <div className={props.stepNumber === 1 ? "stepActive" : "step"}><button className={props.stepNumber === 1 ? "bStepActive" : "bStep"} value={1} onClick={changeStep}>1</button><span className="stepNumberDesc">Krok 1</span></div>
                        <div className="sepStep"><hr className={props.stepNumber === 2 ? "lineStepActive" : "lineStep"}/></div>
                        <div className={props.stepNumber === 2 ? "stepActive" : "step"}><button className={props.stepNumber === 2 ? "bStepActive" : "bStep"} value={2} onClick={changeStep}>2</button><span className="stepNumberDesc">Krok 2</span></div>
                        <div className="sepStep"><hr className={props.stepNumber === 3 ? "lineStepActive" : "lineStep"}/></div>
                        <div className={props.stepNumber === 3 ? "stepActive" : "step"}><button className={props.stepNumber === 3 ? "bStepActive" : "bStep"} value={3} onClick={changeStep}>3</button><span className="stepNumberDesc">Krok 3</span></div>
                    </div>
                :
                    ''
                }
            </div>
        </>
    );
}

export default Steps;