import { useState } from 'react';
import '../../../components/Styles/AdminTitleSection.css';
import swal from 'sweetalert';

const TitleSection = (props) => {
    const [companyTitle, setCompanyTitle] = useState(props.title);
    const [companyTitleChange, setCompanyTitleChange] = useState(false);

      const changeCompanyTitle = (e) => {
        setCompanyTitle(e.target.value);
        setCompanyTitleChange(true);
      }

      const fetchTitleUpdate = async () => {
        await fetch('https://porownywarkachfapi.ftpeople.pl/update_title?company='+encodeURI(props.company)+'&title='+encodeURI(companyTitle), {
            method: 'POST'})
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

      const sendTitleInput = (e) => {
        props.startLoading(true);
        fetchTitleUpdate();
      }
      

return(
    <>
        <div className="mainContainerAdminCompanyData">
            <span className="sectionTitle">Tytuł</span>
            <span className="sectionDesc">maksymalnie 250 znaków. <br/> Dopuszczalne jest używanie znaczników html.</span>
            <input className="titleAdminInput" type="text" name="title" maxLength="250" value={companyTitle} onChange={changeCompanyTitle}/>
            {companyTitleChange
            ?
            <button className="saveTitleButton" onClick={sendTitleInput}>Zapisz</button>
            :
            ''
            }
            
        </div>
    </>
)

}

export default TitleSection;