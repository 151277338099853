import React from "react";
import '../../../components/Styles/PopUpLeadDetails.css';

const PopUpLeadDetails = (props) => {
    
    const handleClick = (e) => {
        props.toggle(e);
    };

    const leadsNotes = (
      props.leadsNote.filter(el => +el.userId === +props.id).map(el2 => (
        <div style={{borderTop: "1px solid black"}}>
          <p>{el2.addDate}</p>
          <p>{el2.note}</p>
        </div>
      ) 
      )
    )
    
    return (
     <div className="modalPopUpLeadDetails">
       <div className="modal_contentPopUpLeadDetails">
       <button className="closePopUpLeadDetails" value='close' onClick={handleClick}>➜</button>
        <div style={{overflowY: "scroll"}} className="mainContainerPopUpLeadDetailSection">
            <h2>SZCZEGŁOWE DANE KLIENTA</h2>
            <p>Imię i nazwisko: </p><p><b>{props.leadBasicData.name}</b></p>
            <p>Adres e-mail: </p><p><b>{props.leadBasicData.mail}</b></p>
            <p>Numer telefonu: </p><p><b>{props.leadBasicData.phone}</b></p>
            <br/>
            <p>Bank: </p><p><b>{props.leadBasicData.creditAgreement}</b></p>
            <p>Kwota kredytu: </p><p><b>{props.leadBasicData.creditAmmount} {props.leadBasicData.creditCurrency}</b></p>
            <p>Data uruchomienia: </p><p><b>{props.leadBasicData.creditDate}</b></p>
            <br/>
            <p>Lokalizacja klienta: </p><p><b>{props.leadBasicData.leadArea}</b></p>
            <p>Oczekiwania wobec kancelarii: </p><p><b>{props.leadBasicData.leadExpectations}</b></p>
            <br/>
            <h3>Analiza umowy kredytowej: {!props.leadDetailsData ? 'nie' : <a  className="popUpLeadDetailAnalysisLink" target="_blank" href={props.leadDetailsData.analysisLink} download>PODGLąD</a>}</h3>
            {props.leadsNote.filter(el => +el.userId === +props.id).length !== 0 ?
            <>
            <p style={{fontSize: "1vw", fontWeight: "900", marginTop: "5vh"}}>Notatki</p>
            {leadsNotes}
            </> : ''}
        </div>
      </div>
     </div>
    );
  }

export default PopUpLeadDetails;