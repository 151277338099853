import React, { Component } from "react";
import '../../../components/Styles/PopUp.css';
import { Markup } from 'interweave';

const PopUp = (props) => {
    
    const handleClick = (e) => {
        props.toggle(e);
    };
    
    return (
     <div className="modal">
       <div className="modal_content">
       <button className="close" value='close' onClick={handleClick}>➜</button>

        <div className="mainContainerPopUpSection">
                <div className="headerPopUpBox">
                    <div className="logoPopUpBox">
                        <img className="logoPopUpStyle" alt={props.companyTitle} src={props.companyLogo} />
                    </div>
                    <div className="titlePopUpBox">
                        <span className="PopUpTitleText"><Markup content={props.companyTitle}/></span>
                    </div>
                </div>
                <div className="mainPhotoBox" style={{backgroundImage: 'url("' + props.companyImage + '")'}}>

                </div>
                <div className="contentDescriptionPopUp">
                    <p><Markup content={props.companyDescription}/></p>
                </div>
                <div className="PopUpSeparator"></div>
                <div className="successPopUpBox">
                    <div className="experienceBoxFront">
                        <p className="successTitleTxtPopUp">Obecność <br/>na rynku CHF</p>
                        <img className="experienceImage" src="https://porownywarkachfapi.ftpeople.pl/experience1.png"></img>
                        <p className="successMainTxtPopUp">od {props.companyMarketYear === '0' ? 'brak danych' : props.companyMarketYear} roku</p>
                    </div>
                    <div className="experienceBoxFront">
                        <p className="successTitleTxtPopUp">Ilość obsługiwanych spraw CHF</p>
                        <img className="experienceImage" src="https://porownywarkachfapi.ftpeople.pl/experience2.png"></img>
                        <p className="successMainTxtPopUp">{props.companyCaseCount === '0' ? 'brak danych' : props.companyCaseCount}</p>
                    </div>
                    <div className="experienceBoxFront">
                        <p className="successTitleTxtPopUp">Ilość wygranych spraw CHF</p>
                        <img className="experienceImage" src="https://porownywarkachfapi.ftpeople.pl/experience3.png"></img>
                        <p className="successMainTxtPopUp">{props.companyWinCases === '0' ? 'brak danych' : props.companyWinCases}</p>
                    </div>
                    <div className="experienceBoxFront">
                        <p className="successTitleTxtPopUp">Ilość prawomocnych wygranych CHF</p>
                        <img className="experienceImage" src="https://porownywarkachfapi.ftpeople.pl/experience4.png"></img>
                        <p className="successMainTxtPopUp">{props.companyWinRealCases === '0' ? 'brak danych' : props.companyWinRealCases}</p>
                    </div>
                </div>
            </div>

      </div>
     </div>
    );
  }

export default PopUp;