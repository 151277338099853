import React, {useEffect} from 'react';
import '../../../components/Styles/ChooseCheckBox.css';
import { Markup } from 'interweave';
import { useState} from 'react';
import swal from 'sweetalert';


const ChooseCheckBox = (props) => {
    const [clicked, setClicked] = useState(false);

    const click = (e) => {
        if(!clicked && !(props.choosenArray.length+1 > 3)){
            setClicked(!clicked);
            props.checkChoose(e);
        }else if(clicked){
            setClicked(!clicked);
            props.checkChoose(e); 
        }else{
            swal({
                icon: "error",
                text: "Możesz wybrać maksymalnie 3 kancelarie!",
                button: {
                  text: "Ok",
                },
            });
        }
    }

    return(
        <>
           <button id={clicked ? "chooseButtonActive" : ''} className="chooseButton" value={props.companyId} onClick={click}>{clicked ? 'WYBRANO' : 'WYBIERZ'}</button>
        </>
    )
}

export default ChooseCheckBox;