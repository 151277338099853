import React from 'react';
import '../../../components/Styles/CreditSubsequentsTranchesChange.css';
import '../../../components/Styles/FormStyle.css';

const CreditSubsequentsTranchesChange = (props) => {

    const SubsequentsTranchesChangeData = props.creditSubsequentsTranchesChanges.map(creditSubsequentsTranchesChange => (
        <>
            <tr key={creditSubsequentsTranchesChange.key}>
                    <td>
                        {creditSubsequentsTranchesChange.date}
                    </td>
                    <td>
                        {creditSubsequentsTranchesChange.value}
                    </td>
                    <td>
                        {creditSubsequentsTranchesChange.currency}
                    </td>
                    <td>
                        <button id="subrow_button_table" className="submitButton" value={creditSubsequentsTranchesChange.key} onClick={props.handleDeleteCreditSubsequentsTranchesChange}>Usuń</button>
                    </td>
            </tr>
        </>
    ))

    const lenghtChangeDataStructure = (
        <table className="subsequentsTranchesChangeTable">
                <tbody>
                    <tr>
                        <th>Data</th>
                        <th>Wartość</th>
                        <th>Waluta</th>
                        <th>Akcja</th>
                    </tr>
                    {SubsequentsTranchesChangeData}
                </tbody>
        </table> 
    )

    return(
        <>
            <div className="additionalInfoRowCreditSubsequentsTranches">
                <div className="additionalInfoRowCreditSubsequentsTranchesControls">
                    <div className="additionalInfoRowCreditSubsequentsTranches_col1a">
                        <input type="number" id="subrow_input2" name="actualCreditSubsequentsTranchesChangeValue" onFocus={props.handleFocus} value={props.actualCreditSubsequentsTranchesChangeRowValue} onChange={props.handleActualCreditSubsequentsTranchesChangeRowValueChange}/>
                    </div>
                    <div className="additionalInfoRowCreditSubsequentsTranches_col2a">
                        <input type="date" id="subrow_input2" name="actualCreditSubsequentsTranchesChangeDate" value={props.actualCreditSubsequentsTranchesChangeRowDate} onChange={props.handleActualCreditSubsequentsTranchesChangeRowDateChange}/>
                    </div>
                    <div className="additionalInfoRowCreditSubsequentsTranches_col3a">
                        <select name="creditSubsequentsTranchesCurrencyChange" id="subrow_input3"  className="currencySelect" value={props.actualCreditSubsequentsTranchesChangeRowCurrency} onChange={props.handleActualCreditSubsequentsTranchesChangeRowCurrencyChange}>
                            <option value="PLN">PLN</option>
                            <option value="CHF">CHF</option>
                        </select>
                    </div>
                    <div className="additionalInfoRowCreditSubsequentsTranches_col4a">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditSubsequentsTranchesChange}>DODAJ</button>
                    </div>
                </div>
                <div className="additionalInfoRowCreditSubsequentsTranches_col4a_mobile">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditSubsequentsTranchesChange}>DODAJ</button>
                    </div>
                <div className="tableRow">
                    {props.creditSubsequentsTranchesChanges.length === 0 ? '' : lenghtChangeDataStructure}
                </div>
            </div>  
        </>
    )
}

export default CreditSubsequentsTranchesChange;