import { useState, useEffect } from 'react';
import '../../../components/Styles/AdminImageSection.css';
import swal from 'sweetalert';

const ImageSection = (props) => {
    const [imageImage, setImageImage] = useState(null);
    const [imageImageUrl, setImageImageUrl] = useState(null);

    useEffect(() => {
        if (imageImage) {
          setImageImageUrl(URL.createObjectURL(imageImage));
        }
      }, [imageImage]);

      const changeImageImage = (e) => {
        setImageImage(e.target.files[0]);
      }

      const fetchFileImageUpload = async () => {
        let formData = new FormData()
        formData.append('image', imageImage);

        await fetch('https://porownywarkachfapi.ftpeople.pl/uploadimage?company='+encodeURI(props.company), {
            method: 'POST',
            body: formData,})
        .then(response => response.text())
        .then(data => {
            setImageImage(null);
            props.getData();
        })
        .catch(error => {
            
        })
    }

      const sendImageFileInput = (e) => {
        props.startLoading(true);
        fetchFileImageUpload();
      }

return(
    <>
        <div className="mainContainerAdminCompanyData">
        <span className="sectionTitle"> Zdjęcie główne</span>
          <div className="imageUploadBox">
            <div className="fileUploadBox">
              <div className="fileUploadButton">
                <input className="fileImageInput" type="file" name="image" onChange={changeImageImage}/>
              </div>
            </div>
            <div className="imageInputPreviewBox">
              {imageImage 
                ?
                <>
                  <img className="imagePreview" src={imageImageUrl} alt="image_preview"/><br/>
                </>
                :
                ''
              }
            </div>
          </div>  
          {imageImage 
                ?
                <>
                  <button className="fileSentButton" onClick={sendImageFileInput}>ZAPISZ</button>
                </>
                :
                ''
              }
        </div>
    </>
)

}

export default ImageSection;