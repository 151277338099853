import { useState, useEffect } from "react";
import "../../../components/Styles/AdminCompanyDetails.css";
import { ThreeDots } from "react-loader-spinner";
import AdminCompanyDetailsInputs from "./AdminCompanyDetailsInputs";

const AdminCompanyDetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyRegion, setCompanyRegion] = useState("");
  const [companyLeadMail, setCompanyLeadMail] = useState("");
  const [companyPerson, setCompanyPerson] = useState("");
  const [companyAllowedBank, setCompanyAllowedBank] = useState("");
  const [companyAllowedRegions, setCompanyAllowedRegions] = useState("");
  const [companyAddressChange, setCompanyAddressChange] = useState(false);
  const [companyRegionChange, setCompanyRegionChange] = useState(false);
  const [companyLeadMailChange, setCompanyLeadMailChange] = useState(false);
  const [companyPersonChange, setCompanyPersonChange] = useState(false);
  const [companyAllowedBankChange, setCompanyAllowedBankChange] =
    useState(false);
  const [companyAllowedRegionsChange, setCompanyAllowedRegionsChange] =
    useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const fetchCompanyData = async (e) => {
    var body = {
      companyId: props.company,
    };

    await fetch("https://porownywarkachfapi.ftpeople.pl/get_company_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setCompanyDetails(data);
        setCompanyAddress(
          data.filter((el) => el.keyV === "companyAddress").length === 0
            ? ""
            : data.filter((el) => el.keyV === "companyAddress")[0].value
        );
        setCompanyRegion(
          data.filter((el) => el.keyV === "companyRegion").length === 0
            ? ""
            : data.filter((el) => el.keyV === "companyRegion")[0].value
        );
        setCompanyPerson(
          data.filter((el) => el.keyV === "companyPerson").length === 0
            ? ""
            : data.filter((el) => el.keyV === "companyPerson")[0].value
        );
        setCompanyLeadMail(
          data.filter((el) => el.keyV === "companyMail").length === 0
            ? ""
            : data.filter((el) => el.keyV === "companyMail")[0].value
        );
        setCompanyAllowedBank(
          data.filter((el) => el.keyV === "companyAllowedBanks").length === 0
            ? ""
            : data
                .filter((el) => el.keyV === "companyAllowedBanks")[0]
                .value.split("/")
        );
        setCompanyAllowedRegions(
          data.filter((el) => el.keyV === "companyAllowedRegions").length === 0
            ? ""
            : data
                .filter((el) => el.keyV === "companyAllowedRegions")[0]
                .value.split("/")
        );

        setCompanyAllowedBankChange(false);
        setCompanyAllowedRegionsChange(false);
        setCompanyAddressChange(false);
        setCompanyLeadMailChange(false);
        setCompanyPersonChange(false);
        setCompanyRegionChange(false);

        setLoading(false);
      })
      .catch((error) => {});
  };

  const getData = () => {
    fetchCompanyData();
  };

  const fetchAddressInputInsert = async () => {
    var body = {
      address: companyAddress,
    };

    await fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_address?company=" +
        encodeURI(props.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        getData();
      })
      .catch((error) => {});
  };

  const changeCompanyAddress = (e) => {
    setCompanyAddress(e.target.value);
    setCompanyAddressChange(true);
  };

  const sendAddressInput = (e) => {
    setLoading(true);
    fetchAddressInputInsert();
  };

  const changeCompanyRegion = (e) => {
    setCompanyRegion(e.target.value);
    setCompanyRegionChange(true);
  };

  const changeCompanyLeadMail = (e) => {
    setCompanyLeadMail(e.target.value);
    setCompanyLeadMailChange(true);
  };

  const fetchRegionInputInsert = async () => {
    var body = {
      region: companyRegion,
    };

    await fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_region?company=" +
        encodeURI(props.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        getData();
      })
      .catch((error) => {});
  };

  const sendRegionInput = (e) => {
    setLoading(true);
    fetchRegionInputInsert();
  };

  const fetchMailInputInsert = async () => {
    var body = {
      mail: companyLeadMail,
    };

    await fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_mail?company=" +
        encodeURI(props.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        getData();
      })
      .catch((error) => {});
  };

  const sendMailInput = (e) => {
    setLoading(true);
    fetchMailInputInsert();
  };

  const changeCompanyPerson = (e) => {
    setCompanyPerson(e.target.value);
    setCompanyPersonChange(true);
  };

  const fetchPersonInputInsert = async () => {
    var body = {
      person: companyPerson,
    };

    await fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_person?company=" +
        encodeURI(props.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        getData();
      })
      .catch((error) => {});
  };

  const sendPersonInput = (e) => {
    setLoading(true);
    fetchPersonInputInsert();
  };

  const changeCompanyAllowedBanks = (e) => {
    var options = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setCompanyAllowedBank(options);
    setCompanyAllowedBankChange(true);
  };

  const fetchAllowedBanksInputInsert = async () => {
    var banksT = "";
    companyAllowedBank.map((bank) => (banksT = bank + "/" + banksT));

    var body = {
      banks: banksT,
    };

    await fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_banks?company=" +
        encodeURI(props.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        getData();
      })
      .catch((error) => {});
  };

  const sendAllowedBanksInput = (e) => {
    setLoading(true);
    fetchAllowedBanksInputInsert();
  };

  const changeCompanyAllowedRegions = (e) => {
    var options = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setCompanyAllowedRegions(options);
    setCompanyAllowedRegionsChange(true);
  };

  const fetchAllowedRegionsInputInsert = async () => {
    var banksT = "";
    companyAllowedRegions.map((bank) => (banksT = bank + "/" + banksT));

    var body = {
      regions: banksT,
    };

    await fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_regions?company=" +
        encodeURI(props.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        getData();
      })
      .catch((error) => {});
  };

  const sendAllowedRegionsInput = (e) => {
    setLoading(true);
    fetchAllowedRegionsInputInsert();
  };

  return (
    <>
      <div className="mainContainerAdminCompanyDetails">
        {loading ? (
          <ThreeDots />
        ) : (
          <>
            <AdminCompanyDetailsInputs
              agreements={props.agreements}
              loading={props.loading}
              companyAddress={companyAddress}
              changeCompanyAddress={changeCompanyAddress}
              companyAddressChange={companyAddressChange}
              sendAddressInput={sendAddressInput}
              companyPerson={companyPerson}
              changeCompanyPerson={changeCompanyPerson}
              companyPersonChange={companyPersonChange}
              sendPersonInput={sendPersonInput}
              companyRegion={companyRegion}
              changeCompanyRegion={changeCompanyRegion}
              companyRegionChange={companyRegionChange}
              sendRegionInput={sendRegionInput}
              companyLeadMail={companyLeadMail}
              changeCompanyLeadMail={changeCompanyLeadMail}
              companyLeadMailChange={companyLeadMailChange}
              sendMailInput={sendMailInput}
              companyAllowedBank={companyAllowedBank}
              changeCompanyAllowedBanks={changeCompanyAllowedBanks}
              companyAllowedBankChange={companyAllowedBankChange}
              sendAllowedBanksInput={sendAllowedBanksInput}
              companyAllowedRegions={companyAllowedRegions}
              changeCompanyAllowedRegions={changeCompanyAllowedRegions}
              companyAllowedRegionsChange={companyAllowedRegionsChange}
              sendAllowedRegionsInput={sendAllowedRegionsInput}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AdminCompanyDetails;
