import React from "react";
import "../../../components/Styles/AdminCompanyLeads.css";
import { useState } from "react";
import { useEffect } from "react";
import PopUpLeadDetails from "../ConsultantPanel/PopUpLeadDetails";
import { CSVLink } from "react-csv";
import LeadObject from "../../../classes/LeadObject";
import swal from "sweetalert";
import { ThreeDots } from "react-loader-spinner";

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Phone", key: "phone" },
  { label: "Email", key: "mail" },
  { label: "Credit Agreement", key: "creditAgreement" },
  { label: "Credit Ammount", key: "creditAmmount" },
  { label: "Credit Currency", key: "creditCurrency" },
  { label: "Credit Date", key: "creditDate" },
  { label: "Area", key: "leadArea" },
  { label: "Expectations", key: "leadExpectations" },
  { label: "Add date", key: "addDate" },
  { label: "Identifier", key: "id" },
];

const AdminCompanyLeads = (props) => {
  const [seenPopUpLeadDetail, setSeenPopUpLeadDetail] = useState(false);
  const [idPopUp, setIdPopUp] = useState(1);
  const [assingmentLeadsTable, setAssingmentLeadsTable] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [assingmentLeads, setAssingmentLeads] = useState([]);
  const [leadsBasicData, setLeadsBasicData] = useState([]);
  const [leadsDetailData, setLeadsDetailData] = useState([]);

  const fetchAssingmentsForCompany = async () => {
    await fetch(
      `https://porownywarkachfapi.ftpeople.pl/get_leads_assingments_id_company?companyId=${encodeURI(
        props.company
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setAssingmentLeads(data);
        setAssingmentLeadsTable(sortArrayByDate(createLeadListArray(data)));
        setLoading(false);
      });
  };

  const fetchAllLeads = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/get_all_leads")
      .then((response) => response.json())
      .then(async (data) => {
        setLeadsBasicData(data);
        await fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_details")
          .then((response) => response.json())
          .then(async (data2) => {
            setLeadsDetailData(data2);
            await fetch(
              `https://porownywarkachfapi.ftpeople.pl/get_leads_assingments_id_company?companyId=${encodeURI(
                props.company
              )}`
            )
              .then((response) => response.json())
              .then((data3) => {
                setAssingmentLeads(data3);
                setAssingmentLeadsTable(
                  sortArrayByDate(createLeadListArray(data3, data))
                );
                setLoading(false);
              });
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const fetchDetailLeads = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_details")
      .then((response) => response.json())
      .then((data) => {
        setLeadsDetailData(data);
        fetchAssingmentsForCompany();
      })
      .catch((error) => {});
  };

  const getData = () => {
    setLoading(true);
    fetchAllLeads();
  };

  useEffect(() => {
    getData();
  }, []);

  const createLeadListArray = (leads, dataBasic) => {
    var arr = [];
    leads.map((lead) => {
      console.log(lead);
      arr.push(
        new LeadObject(
          lead.userId,
          getLeadName(lead.userId, dataBasic),
          getLeadPhone(lead.userId, dataBasic),
          getLeadMail(lead.userId, dataBasic),
          getLeadDate(lead.userId, dataBasic),
          lead.dateAssingment
        )
      );
    });
    return arr;
  };

  const createLeadListArray2 = (leads) => {
    var arr = [];
    leads.map((lead) => {
      arr.push(
        new LeadObject(
          lead.userId,
          getLeadName2(lead.userId),
          getLeadPhone2(lead.userId),
          getLeadMail2(lead.userId),
          getLeadDate2(lead.userId),
          lead.dateAssingment
        )
      );
    });
    return arr;
  };

  const sortArrayByDate = (arr) => {
    var arrN = arr.sort(function (a, b) {
      return new Date(b.assingmentDate2) - new Date(a.assingmentDate2);
    });
    return arrN;
  };

  const togglePopLeadDetail = (e) => {
    setSeenPopUpLeadDetail(!seenPopUpLeadDetail);
    setIdPopUp(+e.target.value);
  };

  const getLeadObjectBasic = (leadId, dataBasic) => {
    return dataBasic.find((lead) => lead.id === leadId);
  };

  const getLeadObjectBasic2 = (leadId) => {
    return leadsBasicData.find((lead) => lead.id === leadId);
  };

  const getLeadObjectDetail = (leadId) => {
    return leadsDetailData.find((lead) => lead.leadId === leadId);
  };

  const getLeadName = (leadId, dataBasic) => {
    return getLeadObjectBasic(leadId, dataBasic).name;
  };

  const getLeadPhone = (leadId, dataBasic) => {
    return getLeadObjectBasic(leadId, dataBasic).phone;
  };

  const getLeadMail = (leadId, dataBasic) => {
    return getLeadObjectBasic(leadId, dataBasic).mail;
  };

  const getLeadDate = (leadId, dataBasic) => {
    return getLeadObjectBasic(leadId, dataBasic).addDate;
  };

  const getAssDate = (leadId) => {
    var assObj = assingmentLeads.filter(
      (el) => el.userId === leadId && +el.companyId === +props.company
    );
    var date = "";
    if (assObj.length > 0) {
      date = assObj[0].assingmentDate;
    }
    return date;
  };

  const getLeadName2 = (leadId) => {
    return getLeadObjectBasic2(leadId).name;
  };

  const getLeadPhone2 = (leadId) => {
    return getLeadObjectBasic2(leadId).phone;
  };

  const getLeadMail2 = (leadId) => {
    return getLeadObjectBasic2(leadId).mail;
  };

  const getLeadDate2 = (leadId) => {
    return getLeadObjectBasic2(leadId).addDate;
  };

  const getLeadBasicData = (id) => {
    return getLeadObjectBasic2(id);
  };

  const getLeadDetailData = (id) => {
    return getLeadObjectDetail(id);
  };

  const changeFromDate = (e) => {
    setFromDate(e.target.value);
  };

  const changeToDate = (e) => {
    setToDate(e.target.value);
  };

  const filterListByDate = (e) => {
    var toDateBuff = new Date(toDate);
    toDateBuff.setDate(toDateBuff.getDate() + 1);

    if (
      fromDate !== "" &&
      toDate !== "" &&
      new Date(fromDate) <= new Date(toDate)
    ) {
      setAssingmentLeadsTable(
        sortArrayByDate(
          createLeadListArray2(assingmentLeads).filter(
            (lead) =>
              new Date(lead.assingmentDate) >= new Date(fromDate) &&
              new Date(lead.assingmentDate) <= new Date(toDateBuff)
          )
        )
      );
    } else {
      swal({
        icon: "error",
        text: "niepoprawne dane formularza!",
        button: {
          text: "Ok",
        },
      });
    }
  };

  const refresh = () => {
    setToDate("");
    setFromDate("");
    getData();
  };

  const generateSingleCSVData = (leadId) => {
    var leadBasicData = getLeadBasicData(leadId);
    var leadDetailData = getLeadDetailData(leadId);
    var assign = assingmentLeadsTable.filter((lead) => +lead.id === +leadId)[0];
    var data = "";
    if (leadDetailData !== undefined) {
      data = [
        [
          "ID",
          "Imię i nazwisko",
          "Numer telefonu",
          "Adres e-mail",
          "Umowa kredytowa",
          "Data uruchomienia kredytu",
          "Wartość kredytu",
          "Waluta kredytu",
          "Lokalizacja",
          "Oczekiwania wobec kancelarii",
          "Data aktywności",
        ],
        [
          leadBasicData.id,
          leadBasicData.name,
          leadBasicData.phone,
          leadBasicData.mail,
          leadBasicData.creditAgreement,
          leadBasicData.creditDate,
          leadBasicData.creditAmmount,
          leadBasicData.creditCurrency,
          leadBasicData.leadArea,
          leadBasicData.leadExpectations,
          assign.assingmentDate2,
        ],
      ];
    } else {
      data = [
        [
          "ID",
          "Imię i nazwisko",
          "Numer telefonu",
          "Adres e-mail",
          "Umowa kredytowa",
          "Data uruchomienia kredytu",
          "Wartość kredytu",
          "Waluta kredytu",
          "Lokalizacja",
          "Oczekiwania wobec kancelarii",
          "Data aktywności",
        ],
        [
          leadBasicData.id,
          leadBasicData.name,
          leadBasicData.phone,
          leadBasicData.mail,
          leadBasicData.creditAgreement,
          leadBasicData.creditDate,
          leadBasicData.creditAmmount,
          leadBasicData.creditCurrency,
          leadBasicData.leadArea,
          leadBasicData.leadExpectations,
          assign.assingmentDate2,
        ],
      ];
    }

    return data;
  };

  const generateAllCSVData = () => {
    var dataArr = [];
    var data = "";
    assingmentLeadsTable.map((lead) => {
      var leadBasicData = getLeadBasicData(lead.id);
      var row = [
        leadBasicData.id,
        leadBasicData.name,
        leadBasicData.phone,
        leadBasicData.mail,
        leadBasicData.creditAgreement,
        leadBasicData.creditDate,
        leadBasicData.creditAmmount,
        leadBasicData.creditCurrency,
        leadBasicData.leadArea,
        leadBasicData.leadExpectations,
        lead.assingmentDate2,
      ];
      dataArr.push(row);
    });

    var dataText = "";
    dataText = dataArr.map((data) => {
      dataText = dataText + "[" + data + "],";
    });

    var i = 0;
    data = [
      [
        "ID",
        "Imię i nazwisko",
        "Numer telefonu",
        "Adres e-mail",
        "Umowa kredytowa",
        "Data uruchomienia kredytu",
        "Wartość kredytu",
        "Waluta kredytu",
        "Lokalizacja",
        "Oczekiwania wobec kancelarii",
        "Data aktywności",
      ],
    ];
    dataArr.map((dataEl) => {
      data.push(dataEl);
    });

    return data;
  };

  const leadsList = (
    <>
      {assingmentLeadsTable.map((lead) => (
        <div key={lead.userId} className="adminCompanyLeadsRowBox">
          <div className="adminCompanyLeadsRowBox_col1">
            <span className="adminCompanyLeadsRowBox_coltitle">
              Imię i nazwisko:
            </span>
            <p className="adminCompanyLeadsRowBox_data">{lead.name}</p>
          </div>
          <div className="adminCompanyLeadsRowBox_col2">
            <span className="adminCompanyLeadsRowBox_coltitle">
              Numer telefonu:
            </span>
            <p className="adminCompanyLeadsRowBox_data">{lead.phone}</p>
          </div>
          <div className="adminCompanyLeadsRowBox_col3">
            <span className="adminCompanyLeadsRowBox_coltitle">
              Adres e-mail:
            </span>
            <p className="adminCompanyLeadsRowBox_data">{lead.mail}</p>
          </div>

          <div className="adminCompanyLeadsRowBox_col4">
            <span className="adminCompanyLeadsRowBox_coltitle">
              Data przypisania:
            </span>
            <p className="adminCompanyLeadsRowBox_data">
              {lead.assingmentDate2}
            </p>
          </div>
          <div className="adminCompanyLeadsRowBox_col5">
            <button
              className="adminCompanyLeadsButton"
              value={lead.id}
              onClick={togglePopLeadDetail}
            >
              Szczegóły
            </button>
            <CSVLink
              className="adminCompanyLeadsButton"
              data={generateSingleCSVData(lead.id)}
              filename={"LeadReport " + getLeadName2(lead.id) + ".csv"}
            >
              CSV
            </CSVLink>
          </div>
        </div>
      ))}
    </>
  );

  return (
    <>
      <div className="mainContainerAdminCompanyLeads">
        {loading ? (
          <ThreeDots />
        ) : (
          <>
            <div className="adminCompanyLeadsListFunctionalitesBox">
              <input
                className="adminCompanyLeadsDateInput"
                type="date"
                name="fromDate"
                value={fromDate}
                onChange={changeFromDate}
              />
              <input
                className="adminCompanyLeadsDateInput"
                type="date"
                name="toDate"
                value={toDate}
                onChange={changeToDate}
              />
              <button
                className="adminCompanyLeadsFilterButton"
                onClick={filterListByDate}
              >
                Filtruj
              </button>
              <div className="adminCompanyLeadsRecordsSeparator"></div>
              <button
                className="adminCompanyLeadsFilterButton"
                onClick={refresh}
              >
                Odśwież
              </button>
              <CSVLink
                className="adminCompanyLeadsFilterButton"
                data={generateAllCSVData()}
                filename={"LeadReport.csv"}
              >
                Eksport do CSV
              </CSVLink>
              <span className="adminCompanyLeadsRecordsCounter">
                Ilość rekordów: {assingmentLeadsTable.length}
              </span>
            </div>
            {leadsList}
          </>
        )}
      </div>

      {seenPopUpLeadDetail ? (
        <PopUpLeadDetails
          id={idPopUp}
          toggle={togglePopLeadDetail}
          leadBasicData={getLeadBasicData(idPopUp)}
          leadDetailsData={getLeadDetailData(idPopUp)}
          leadsNote={[]}
        />
      ) : null}
    </>
  );
};

export default AdminCompanyLeads;
