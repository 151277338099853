import { useState } from 'react';
import '../../../components/Styles/AdminDetailsSection.css';
import swal from 'sweetalert';

const DetailsSection = (props) => {
    const [caseCount, setCompanyCaseCount] = useState(props.caseCount);
    const [caseCountChange, setCompanyCaseCountChange] = useState(false);
    const [marketYear, setCompanyMarketYear] = useState(props.marketYear);
    const [marketYearChange, setCompanyMarketYearChange] = useState(false);
    const [winCases, setCompanyWinCases] = useState(props.winCases);
    const [winCasesChange, setCompanyWinCasesChange] = useState(false);
    const [winRealCases, setCompanyWinRealCases] = useState(props.winRealCases);
    const [winRealCasesChange, setCompanyWinRealCasesChange] = useState(null);

    const fetchCaseCountUpdate = async () => {
        fetch('https://porownywarkachfapi.ftpeople.pl/update_casecount?company='+encodeURI(props.company), {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({case: caseCount})
        })
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

    const fetchMarketYearUpdate = async () => {
        fetch('https://porownywarkachfapi.ftpeople.pl/update_marketyear?company='+encodeURI(props.company), {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({market: marketYear})
        })
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

    const fetchWinCasesUpdate = async () => {
        fetch('https://porownywarkachfapi.ftpeople.pl/update_wincases?company='+encodeURI(props.company), {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({wincases: winCases})
        })
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

    const fetchWinRealCasesUpdate = async () => {
        fetch('https://porownywarkachfapi.ftpeople.pl/update_winrealcases?company='+encodeURI(props.company), {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({winrealcases: winRealCases})
        })
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

    const changeCompanyCaseCount = (e) => {
        setCompanyCaseCount(e.target.value);
        setCompanyCaseCountChange(true);
      }

      const sendCaseCountInput = (e) => {
        props.startLoading(true);
        fetchCaseCountUpdate();
      }

      const changeCompanyMarketYear = (e) => {
        setCompanyMarketYear(e.target.value);
        setCompanyMarketYearChange(true);
      }

      const sendMarketYearInput = (e) => {
        props.startLoading(true);
        fetchMarketYearUpdate();
      }

      const changeCompanyWinCases = (e) => {
        setCompanyWinCases(e.target.value);
        setCompanyWinCasesChange(true);
      }

      const sendWinCasesInput = (e) => {
        props.startLoading(true);
        fetchWinCasesUpdate();
      }

      const changeCompanyWinRealCases = (e) => {
        setCompanyWinRealCases(e.target.value);
        setCompanyWinRealCasesChange(true);
      }

      const sendWinRealCasesInput = (e) => {
        setCompanyWinRealCases(e.target.value);
        fetchWinRealCasesUpdate();
      }

return(
    <>
    <div className="detailsInputsBox">
        <div className="detailsInputBox">
            <div className="mainContainerAdminCompanyData">
                <span className="sectionTitleFlex">Ilość prowadzonych spraw</span>
                    <input className="caseCountAdminInput" type="number" name="caseCount" maxLength="1000" rows="10" value={caseCount} onChange={changeCompanyCaseCount}/>
                    {caseCountChange
                    ?
                    <button className="saveCaseCountButton" onClick={sendCaseCountInput}>Zapisz</button>
                    :
                    ''
                    }
            </div>
        </div>
        <div className="detailsInputBox">
            <div className="mainContainerAdminCompanyData">
                <span className="sectionTitleFlex">Od którego roku na rynku?</span>
                    <input className="marketYearAdminInput" type="number" name="marketYear" maxLength="1000" rows="10" value={marketYear} onChange={changeCompanyMarketYear}/>
                    {marketYearChange
                    ?
                    <button className="saveCaseCountButton" onClick={sendMarketYearInput}>Zapisz</button>
                    :
                    ''
                    }
            </div>
        </div>
        <div className="detailsInputBox">
            <div className="mainContainerAdminCompanyData">
                <span className="sectionTitleFlex">Ilość wygranych spraw CHF?</span>
                    <input className="marketYearAdminInput" type="number" name="winCases" maxLength="1000" rows="10" value={winCases} onChange={changeCompanyWinCases}/>
                    {winCasesChange
                    ?
                    <button className="saveCaseCountButton" onClick={sendWinCasesInput}>Zapisz</button>
                    :
                    ''
                    }
            </div>
        </div>
        <div className="detailsInputBox">
            <div className="mainContainerAdminCompanyData">
                <span className="sectionTitleFlex">Ilość prawomocnych wygranych spraw CHF?</span>
                    <input className="marketYearAdminInput" type="number" name="winRealCases" maxLength="1000" rows="10" value={winRealCases} onChange={changeCompanyWinRealCases}/>
                    {winRealCasesChange
                    ?
                    <button className="saveCaseCountButton" onClick={sendWinRealCasesInput}>Zapisz</button>
                    :
                    ''
                    }
            </div>
        </div>
    </div>
    </>
)

}

export default DetailsSection;