import React from 'react';
import '../../../components/Styles/AdminCompanyOffer.css';
import AddOffer from './AddOffer';
import OfferList from './OfferList';
import { useState, useEffect } from 'react';
import { ThreeDots } from  'react-loader-spinner';

const AdminCompanyOffer = (props) => {
    const [loading, setLoading] = useState(true);
    const [companyOffers, setCompanyOffers] = useState([]);

    const fetchCompanyOffer = async () => {
        await fetch('https://porownywarkachfapi.ftpeople.pl/company_offer?company='+encodeURI(props.company))
        .then(response => response.json())
        .then(data => {
            setCompanyOffers(data);
            setLoading(false);
        })
        .catch(error => {
            
        })
    }

    const getData = () => {
        fetchCompanyOffer();
    }

    useEffect(() => {
        getData();
    },[]);

return(
    <>
        <div className="mainContainerAdminCompanyOffer">
            {loading ? 
            <ThreeDots/>
            :
            <>
                <AddOffer 
                    startLoading = {setLoading}
                    getData = {getData}
                    company = {props.company}
                />
                <br/>
                <hr/>
                <OfferList 
                    companyOffers = {companyOffers}
                    startLoading = {setLoading}
                    getData = {getData}
                    company = {props.company}
                />
            </>}
        </div>
    </>
)

}

export default AdminCompanyOffer;