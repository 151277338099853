import React from 'react';
import '../../../components/Styles/ToggleSwitchOffer.css';

const ToggleSwitch = (props) => {
    return ( 
        <>
            <label className={props.class}>
            <input type="checkbox" checked={props.value} onChange={props.change} disabled={props.disabled}/>
            <span className="switch" />
            </label>
        </>
     );
}
 
export default ToggleSwitch;