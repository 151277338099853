import React, { useState } from 'react';
import '../../../components/Styles/FirstPage.css';
import '../../../components/Styles/FormStyle.css';
import ToggleSwitch from './ToggleSwitch';
import swal from 'sweetalert';
import RSelect from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/fontawesome-free-regular';
import { faPhone } from '@fortawesome/fontawesome-free-solid';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import ReactPlayer from 'react-player'
import pl from "date-fns/locale/pl"; // the locale you want
registerLocale("pl", pl); // register it with the name you want

const FirstPage = (props) => {
    const [creditBank, setCreditBank] = useState(props.creditBank);
    const [creditAmmount, setCreditAmmount] = useState(props.creditAmmount);
    const [creditDate, setCreditDate] = useState(props.creditDate);
    const [creditCurrency, setCreditCurrency] = useState(props.currencyToggleChecked);
    const [creditCurrencyFlag, setCreditCurrencyFlag] = useState(props.creditCurrency === '' ? 0 : 1);

    const changeCreditBank = (e) => {
        setCreditBank(e.value);
        props.onChangeCreditBank(e);
    }

    const changeCreditAmmount = (e) => {
        setCreditAmmount(+e.target.value);
        props.onChangeCreditAmmount(e);
    }

    const changeCreditDate = (e) => {
        setCreditDate(e);
        props.onChangeCreditDate(e);
    }

    const changeCreditCurrency = (e) => {
        setCreditCurrency(e.target.checked);
        setCreditCurrencyFlag(1);
        props.onChangeCreditCurrencyToggle(e);
    }

    const focus = (e) => {
        if(e.target.value === '0'){
            if(e.target.name === 'creditAmmount'){
                setCreditAmmount('0');
            }
        }
        e.target.select();
    }

    const checkDigit = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
    }

    const validateForm = () => {
        if(!creditBank || !creditAmmount || !creditDate || !creditCurrencyFlag === 0){
            return false;
        }else {
            props.changeValidate(1);
            return true;
        }
    }

    const fillRequiredFieldsAlert = () => {
        var txt = ``;
        if(!creditBank){
            txt = txt+'Bank \n';
        }
        if(!creditAmmount){
            txt = txt+'Kwota kredytu \n';
        }
        if(!creditDate){
            txt = txt+'Data zaciągnięcia kredytu \n';
        }

        return txt;
    }

    const submitFirstForm = (e) => {
        var requiredFields = fillRequiredFieldsAlert();

        if(validateForm()){
            props.onSubmitFirstForm(e);
        }else{
            swal({
                icon: "error",
                text: `Należy uzupełnić wymagane pola formularza!
                       \n
                       ${requiredFields}
                `,
                button: {
                  text: "Ok",
                },
              });
        }
    }

    return ( 
        <>
        <div className="mainContainerFirst">
            <div className="contentFirst"> 
                <div className="formContainerFirst">
                    <div className="rowFirst">
                        <div className="oneColFirst">
                            <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                                <label htmlFor="bankChoose">Wybierz bank, w którym masz kredyt
                                <span className="requiredStar">*</span>
                                </label>
                            </div>
                            <div id="oneColFirst_column" className="twoCol_columnFirst">
                                <RSelect
                                    className="currencySelect"
                                    value={creditBank}
                                    onChange={changeCreditBank}
                                    options={props.agreements.map((agreement) => (
                                        {
                                            value: agreement.agreement,
                                            key: agreement.agreement,
                                            label: (
                                                <>
                                                    <img src="https://porownywarkachfapi.ftpeople.pl/select_bank.png" width="20px" className="imgSelect"/>
                                                    <span className="selectInside">{agreement.agreement}</span>
                                                </>
                                            )
                                        }
                                    ))}
                                    placeholder={creditBank ? creditBank : 'Wybierz z listy...'}
                                />
                            </div>

                    <div className="rowFirstMobile">
                    <div className="twoColFirst">
                        <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                            <label htmlFor="creditAmmount">Kwota kredytu
                            <span className="requiredStar">*</span>
                            </label>
                        </div>
                        <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                            <label htmlFor="creditDate">Data uruchomienia kredytu
                            <span className="requiredStar">*</span>
                            </label>
                        </div>
                    </div>
                    <div className="twoColFirst">
                        <div className="twoCol_columnFirst">
                            <input type="number" id="creditAmmount" name="creditAmmount" placeholder="250 000" onFocus={focus} value={creditAmmount} onChange={changeCreditAmmount} onKeyPress={checkDigit}/>
                        </div>
                        <div className="twoCol_columnFirst">
                            <DatePicker
                            closeOnScroll={true}
                            selected={new Date(creditDate)}
                            onChange={changeCreditDate}
                            dateFormat="yyyy-MM-dd"
                            locale='pl'
                            />
                        </div>
                    </div>
                    <div className="rowFirst">
                        <div className="twoColFirst">
                            <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                                <label htmlFor="currencyChoose">Waluta wypłaty
                                <span className="requiredStar">*</span>
                                </label>
                            </div>
                            <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                                
                            </div>
                        </div>
                        <div className="twoColFirst">
                            <div className="twoCol_columnFirst">
                                <ToggleSwitch
                                    id={creditCurrencyFlag}
                                    name="currencyChoose"
                                    onChangeCreditCurrencyToggle = {changeCreditCurrency}
                                    currencyToggleChecked = {creditCurrency}
                                />
                            </div>
                            <div className="twoCol_columnFirst">
                                
                            </div>
                        </div>  
                    </div>
                    <div className="rowFirst">
                        <button className="submitButtonFirstPage" onClick={submitFirstForm}>Dalej →</button> 
                    </div>
                    <div style={{marginTop: "3vh"}}>
                    <ReactPlayer
                        width="100%"
                        height="100%"
                        loop={true}
                        url='https://porownywarkachf.pl/wp-content/uploads/2023/08/FILM-PCHF.mp4' 
                        playing={true}
                        />
                    </div>
                    
            </div>
            
                            <div id="oneColFirst_column_additional" className="twoCol_columnFirst">
                                <div className="youKnowBox_first_page">
                                        <div className="youKnowBox_first_page_box1">
                                            <img className="youKnowBox_first_page_ico" src="https://porownywarkachfapi.ftpeople.pl/first_page_1.png"/>
                                        </div>
                                        <div className="youKnowBox_first_page_box2">
                                            <p className="youKnowBox_first_page_title">Czy wiesz, że...</p>
                                            <p className="youKnowBox_first_page_desc">
                                                Przeciętny frankowicz na skutek swojej toksycznej umowy kredytowej nadpłaca ponad 2-krotnie kwotę zaciągniętego zobowiązania?
                                            </p>
                                        </div>
                                </div>
                            </div>
                            <div id="oneColFirst_column_additional2" className="twoCol_columnFirst">
                                <div className="contact_first_page">
                                        <div className="contact_first_page_box1">
                                            <img className="contact_first_page_ico" src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"/>
                                        </div>
                                        <div className="contact_first_page_box2">
                                            <p className="contact_first_page_title">Potrzebujesz pomocy?</p>
                                            <p className="contact_first_page_desc">
                                                Karolina chętnie Ci pomoże
                                            </p>
                                            <div className="contact_first_page_details_box">
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faPhone}/></div>
                                                    71 331 78 21
                                                </div>
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faEnvelope}/></div>
                                                    <a className="contactHref" href="mailto: biuro@porownywarkachf.pl">biuro@porownywarkachf.pl</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
                <div className="descContainerFirst">
                    <div className="rowFirst">
                    <div className="twoColFirst">
                        <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                            <label htmlFor="creditAmmount">Kwota kredytu
                            <span className="requiredStar">*</span>
                            </label>
                        </div>
                        <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                            <label htmlFor="creditDate">Data uruchomienia kredytu
                            <span className="requiredStar">*</span>
                            </label>
                        </div>
                    </div>
                    <div className="twoColFirst">
                        <div className="twoCol_columnFirst">
                            <input type="number" id="creditAmmount" name="creditAmmount" placeholder="250 000" onFocus={focus} value={creditAmmount} onChange={changeCreditAmmount} onKeyPress={checkDigit}/>
                        </div>
                        <div className="twoCol_columnFirst">
                            <DatePicker
                            closeOnScroll={true}
                            selected={new Date(creditDate)}
                            onChange={changeCreditDate}
                            dateFormat="yyyy-MM-dd"
                            />
                        </div>
                    </div>
                    <div className="rowFirst">
                        <div className="twoColFirst">
                            <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                                <label htmlFor="currencyChoose">Waluta wypłaty
                                <span className="requiredStar">*</span>
                                </label>
                            </div>
                            <div id="twoCol_columnFirst_title" className="twoCol_columnFirst">
                                
                            </div>
                        </div>
                        <div className="twoColFirst">
                            <div className="twoCol_columnFirst">
                                <ToggleSwitch
                                    id={creditCurrencyFlag}
                                    name="currencyChoose"
                                    onChangeCreditCurrencyToggle = {changeCreditCurrency}
                                    currencyToggleChecked = {creditCurrency}
                                />
                            </div>
                            <div className="twoCol_columnFirst">
                                
                            </div>
                        </div>  
                    </div>
                    <div className="rowFirst">
                        <button className="submitButtonFirstPage" onClick={submitFirstForm}>Dalej →</button> 
                    </div>  
                    <div style={{paddingTop: "8vh"}}>
            <ReactPlayer
                        width="100%"
                        height="100%"
                        loop={true}
                        url='https://porownywarkachf.pl/wp-content/uploads/2023/08/FILM-PCHF.mp4' 
                        playing={true}
                        />

            </div>
            </div>
            </div>
            </div> 
            </div>
        </>
     );
}
 
export default FirstPage;