import "../../../components/Styles/AdminCompanyData.css";
import LogoSection from "./LogoSection";
import TitleSection from "./TitleSection";
import ImageSection from "./ImageSection";
import DescriptionSection from "./DescriptionSection";
import PreviewSection from "./PreviewSection";
import DetailsSection from "./DetailsSection";
import { useState } from "react";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

const AdminCompanyData = (props) => {
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [companyFiles, setCompanyFiles] = useState([]);

  const fetchCompanyDesc = async (e) => {
    var body = {
      companyId: props.company,
    };

    await fetch(
      "https://porownywarkachfapi.ftpeople.pl/get_company_description",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCompanyData(data);
      })
      .catch((error) => {});
  };

  const fetchCompanyFiles = async (e) => {
    var body = {
      companyId: props.company,
    };

    await fetch("https://porownywarkachfapi.ftpeople.pl/get_company_files", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        setCompanyFiles(data);
        setLoading(false);
      })
      .catch((error) => {});
  };

  const getData = () => {
    fetchCompanyDesc();
    fetchCompanyFiles();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mainContainerAdminCompanyData">
        {loading ? (
          <ThreeDots />
        ) : (
          <>
            <div className="dataContainer">
              <div className="dataSettingsContainer">
                <LogoSection
                  getData={getData}
                  company={props.company}
                  startLoading={setLoading}
                />
                <TitleSection
                  getData={getData}
                  company={props.company}
                  startLoading={setLoading}
                  title={
                    companyData.filter((el) => el.descType === "title")[0] ===
                    undefined
                      ? ""
                      : companyData.filter((el) => el.descType === "title")[0]
                          .descText === undefined
                      ? ""
                      : companyData.filter((el) => el.descType === "title")[0]
                          .descText
                  }
                />
                <ImageSection
                  getData={getData}
                  company={props.company}
                  startLoading={setLoading}
                />
                <DescriptionSection
                  getData={getData}
                  company={props.company}
                  startLoading={setLoading}
                  description={
                    companyData.filter((el) => el.descType === "description")
                      .length === 0
                      ? ""
                      : companyData.filter(
                          (el) => el.descType === "description"
                        )[0].descText
                  }
                />
              </div>
              <div className="dataSettingPreviewContainer">
                <PreviewSection
                  logoUrl={
                    companyFiles.filter((el) => el.fileType === "logo")
                      .length === 0
                      ? ""
                      : companyFiles.filter((el) => el.fileType === "logo")[0]
                          .fileUrl
                  }
                  title={
                    companyData.filter((el) => el.descType === "title")
                      .length === 0
                      ? ""
                      : companyData.filter((el) => el.descType === "title")[0]
                          .descText
                  }
                  description={
                    companyData.filter((el) => el.descType === "description")
                      .length === 0
                      ? ""
                      : companyData.filter(
                          (el) => el.descType === "description"
                        )[0].descText
                  }
                  imageUrl={
                    companyFiles.filter((el) => el.fileType === "image")
                      .length === 0
                      ? ""
                      : companyFiles.filter((el) => el.fileType === "image")[0]
                          .fileUrl
                  }
                  caseCount={
                    companyData.filter((el) => el.descType === "caseCount")
                      .length === 0
                      ? ""
                      : companyData.filter(
                          (el) => el.descType === "caseCount"
                        )[0].descText
                  }
                  marketYear={
                    companyData.filter((el) => el.descType === "marketYear")
                      .length === 0
                      ? ""
                      : companyData.filter(
                          (el) => el.descType === "marketYear"
                        )[0].descText
                  }
                  winCases={
                    companyData.filter((el) => el.descType === "winCases")
                      .length === 0
                      ? ""
                      : companyData.filter(
                          (el) => el.descType === "winCases"
                        )[0].descText
                  }
                  winRealCases={
                    companyData.filter((el) => el.descType === "winRealCases")
                      .length === 0
                      ? ""
                      : companyData.filter(
                          (el) => el.descType === "winRealCases"
                        )[0].descText
                  }
                />
              </div>
            </div>
            <div className="detailsFullWidthBox">
              <DetailsSection
                getData={getData}
                company={props.company}
                startLoading={setLoading}
                caseCount={
                  companyData.filter((el) => el.descType === "caseCount")
                    .length === 0
                    ? ""
                    : companyData.filter((el) => el.descType === "caseCount")[0]
                        .descText
                }
                marketYear={
                  companyData.filter((el) => el.descType === "marketYear")
                    .length === 0
                    ? ""
                    : companyData.filter(
                        (el) => el.descType === "marketYear"
                      )[0].descText
                }
                winCases={
                  companyData.filter((el) => el.descType === "winCases")
                    .length === 0
                    ? ""
                    : companyData.filter((el) => el.descType === "winCases")[0]
                        .descText
                }
                winRealCases={
                  companyData.filter((el) => el.descType === "winRealCases")
                    .length === 0
                    ? ""
                    : companyData.filter(
                        (el) => el.descType === "winRealCases"
                      )[0].descText
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AdminCompanyData;
