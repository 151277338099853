import { useState } from 'react';
import '../../../components/Styles/AdminCompanyDetails.css';
import swal from 'sweetalert';
import { useEffect } from 'react';
import { ThreeDots } from  'react-loader-spinner';

const AdminCompanyDetailsInputs = (props) => {
    
    const regionList = ['Dolnośląskie', 'Kujawsko-Pomorskie', 'Lubelskie', 'Lubuskie', 'Łódzkie', 'Małopolskie', 'Mazowieckie', 'Opolskie', 'Podkarpackie', 'Podlaskie', 'Pomorskie', 'Śląskie', 'Świętokrzyskie', 'Warmińsko-Mazurskie', 'Wielkopolskie', 'Zachodniopomorskie'];

return(
    <>
        <div className="mainContainerAdminCompanyDetails">
            <div className="addDetailsInputBreak">Dane kancelarii</div>
            <div className="separator"></div>
            <div className="addDetailsInputsBox">
                <div className="addDetailsInputBox">
                    <p className="subTitleInput">
                        Wprowadź adres (ulica, kod pocztowy, miejscowość)
                    </p>
                    <textarea className="addressAdminInput" type="text" name="title" rows="4" maxLength="1000" value={props.companyAddress} onChange={props.changeCompanyAddress}/>
                    {props.companyAddressChange
                    ?
                    <button className="saveButton" onClick={props.sendAddressInput}>Zapisz</button>
                    :
                    ''
                    }

                    <p className="subTitleInput">
                        Wprowadź adres e-mail do przesyłania leadów
                    </p>
                    <input className="addOfferAdminInputDefault" type="text" name="entranceeCost" value={props.companyLeadMail} onChange={props.changeCompanyLeadMail}/> 
                    {props.companyLeadMailChange
                    ?
                    <button className="saveButton" onClick={props.sendMailInput}>Zapisz</button>
                    :
                    ''
                    }

                </div>
                <div className="addDetailsInputBox">
                    <p className="subTitleInput">
                        Dane osoby kontaktowej (imię i nazwisko, adres e-mail, numer telefonu)
                    </p>
                    <textarea className="addressAdminInput" type="text" name="title" rows="4" maxLength="1000" value={props.companyPerson} onChange={props.changeCompanyPerson}/>
                    {props.companyPersonChange 
                    ?
                    <button className="saveButton" onClick={props.sendPersonInput}>Zapisz</button>
                    :
                    ''
                    }

                </div>
                <div className="addDetailsInputBox">
                    <p className="subTitleInput">
                        Wybierz województwo, w którym mieści się siedziba
                    </p>
                    <select name="regionChoose" id="regionChoose" className="regionSelect" value={props.companyRegion} onChange={props.changeCompanyRegion}>
                        {regionList.map((region) => (
                            <option key={region} value={region}>{region}</option>
                        ))}
                    </select>
                    {props.companyRegionChange 
                    ?
                    <button className="saveButton" onClick={props.sendRegionInput}>Zapisz</button>
                    :
                    ''
                    }
                    
                </div>
                
            </div>
            <div className="addDetailsInputsBox">
                <div className="addDetailsInputWideBox">
                    <p className="subTitleInput">
                        Wybierz banki, z którymi prowadzisz sprawy w sądzie (wciśnij control/command + kliknij, aby wybrać banki)
                    </p>
                    <select name="bankChoose" id="bankChoose" className="allowedBanksSelect" value={props.companyAllowedBank} onChange={props.changeCompanyAllowedBanks} multiple>
                        {props.agreements.map((agreement) => (
                            <option key={agreement.agreement} value={agreement.agreement}>{agreement.agreement}</option>
                        ))}
                    </select>
                    {props.companyAllowedBankChange
                    ?
                    <button className="saveButton" onClick={props.sendAllowedBanksInput}>Zapisz</button>
                    :
                    ''
                    }
                </div>
            </div>
            <div className="addDetailsInputsBox">
                <div className="addDetailsInputWideBox">
                    <p className="subTitleInput">
                        Wybierz województwa, w których prowadzisz sprawy [pochodzenie klienta] (wciśnij control/command + kliknij, aby wybrać regiony)
                    </p>
                    <select name="regionChoose" id="regionChoose" className="allowedBanksSelect" value={props.companyAllowedRegions} onChange={props.changeCompanyAllowedRegions} multiple>
                        {regionList.map((region) => (
                            <option key={region} value={region}>{region}</option>
                        ))}
                    </select>
                    {props.companyAllowedRegionsChange
                    ?
                    <button className="saveButton" onClick={props.sendAllowedRegionsInput}>Zapisz</button>
                    :
                    ''
                    }
                </div>
            </div>
        </div>
    </>
)

}

export default AdminCompanyDetailsInputs;