import { useState, useEffect } from 'react';
import '../../../components/Styles/AdminPreviewSection.css';
import { Markup } from 'interweave';
import { ThreeDots } from  'react-loader-spinner';

const PreviewSection = (props) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
    }, []);

return(
    <>
        <p className="previewTitle">Tak będzie wyglądał Twój pop-up w porównywarce</p>
        {loading ?
        <div style={{display: "flex", justifyContent: "center"}}>
            <ThreeDots/>
        </div>
        
        :
        <>
        <div className="mainContainerPreviewSection">
            <div className="headerPreviewBox">
                <div className="logoPreviewBox">
                    <img className="logoPreviewStyle" alt="Logo kancelarii" src={props.logoUrl} />
                </div>
                <div className="titlePreviewBox">
                    <span className="previewTitleText"><Markup content={props.title}/></span>
                </div>
            </div>
            <div className="mainPhotoBox" style={{backgroundImage: 'url("' + props.imageUrl + '")'}}>

            </div>
            <div className="contentDescription">
                <p><Markup content={props.description}/></p>
            </div>
            <div className="previewSeparator"></div>
            <div className="successPreviewBox">
                <div className="experienceBox">
                    <p className="successTitleTxt">Obecność <br/>na rynku CHF</p>
                    <img className="experienceImage" alt="obecność na rynku" src="https://porownywarkachfapi.ftpeople.pl/experience1.png"></img>
                    <p className="successMainTxt">od {props.marketYear === '0' ? 'brak danych' : props.marketYear} roku</p>
                </div>
                <div className="experienceBox">
                    <p className="successTitleTxt">Ilość obsługiwanych spraw CHF</p>
                    <img className="experienceImage" alt="ilość obsługiwanych spraw" src="https://porownywarkachfapi.ftpeople.pl/experience2.png"></img>
                    <p className="successMainTxt">{props.caseCount === '0' ? 'brak danych' : props.caseCount}</p>
                </div>
                <div className="experienceBox">
                    <p className="successTitleTxt">Ilość wygranych spraw CHF</p>
                    <img className="experienceImage" alt="ilość wygranych spraw" src="https://porownywarkachfapi.ftpeople.pl/experience3.png"></img>
                    <p className="successMainTxt">{props.winCases === '0' ? 'brak danych' : props.winCases}</p>
                </div>
                <div className="experienceBox">
                    <p className="successTitleTxt">Ilość prawomocnych wygranych CHF</p>
                    <img className="experienceImage" alt="ilość prawomocnych wygranych spraw" src="https://porownywarkachfapi.ftpeople.pl/experience4.png"></img>
                    <p className="successMainTxt">{props.winRealCases === '0' ? 'brak danych' : props.winRealCases}</p>
                </div>
            </div>
        </div>
        </>}
    </>
)

}

export default PreviewSection;