import { faEnvelope } from "@fortawesome/fontawesome-free-regular";
import {
  faFlagCheckered,
  faMapPin,
  faMoneyBill,
  faPhone,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "emailjs-com";
import React, { useState } from "react";
import RSelect from "react-select";
import swal from "sweetalert";
import "../../../components/Styles/FormStyle.css";
import "../../../components/Styles/SecondPage.css";
import ReactPlayer from "react-player";

const SecondPage = (props) => {
  const [clientLocalization, setClientLocalization] = useState(
    props.userLocalization
  );
  const [clientExpectations, setClientExpectations] = useState(
    props.userExpectations
  );
  const [clientName, setClientName] = useState(props.userName);
  const [clientPhone, setClientPhone] = useState(props.userPhone);
  const [clientMail, setClientMail] = useState(props.userMail);
  const [clientAcceptPolicy, setClientAcceptPolicy] = useState(false);

  const changeClientLocalization = (e) => {
    setClientLocalization(e.value);
    props.onChangeUserLocalization(e);
  };

  const changeClientExpectations = (e) => {
    setClientExpectations(e.target.value);
    props.onChangeUserExpectations(e);
  };

  const changeClientName = (e) => {
    setClientName(e.target.value);
    props.onChangeUserName(e);
  };

  const changeClientPhone = (e) => {
    setClientPhone(e.target.value);
    props.onChangeUserPhone(e);
  };

  const changeClientMail = (e) => {
    setClientMail(e.target.value);
    props.onChangeUserMail(e);
  };

  const changeClientAcceptPolicy = (e) => {
    setClientAcceptPolicy(e.target.checked);
  };

  const validateForm = () => {
    var phoneno = /^[0-9]{9}$/;
    var phoneCheck = phoneno.test(clientPhone);
    if (
      !clientLocalization ||
      !clientExpectations ||
      !clientName ||
      !clientPhone ||
      !clientMail ||
      !phoneCheck
    ) {
      return false;
    } else {
      props.changeValidate(2);
      return true;
    }
  };

  const fillRequiredFieldsAlert = () => {
    var phoneno = /^[0-9]{9}$/;
    var phoneCheck = phoneno.test(clientPhone);
    var txt = ``;
    if (!clientLocalization) {
      txt = txt + "Lokalizacja \n";
    }
    if (!clientExpectations) {
      txt = txt + "Oczekiwania względem oferty \n";
    }
    if (!clientName) {
      txt = txt + "Imię i nazwisko \n";
    }
    if (!clientPhone) {
      txt = txt + "Numer telefonu (wprowadź 9 cyfr) \n";
    }
    if (!clientMail) {
      txt = txt + "Adres e-mail \n";
    }
    if (!phoneCheck) {
      txt = txt + "Format numeru telefonu - 9 cyfr XXXXXXXXX \n";
    }

    return txt;
  };

  const submitSecondForm = (e) => {
    var requiredFields = fillRequiredFieldsAlert();
    if (validateForm()) {
      props.onSubmitSecondForm(e);
      sendWelcomeMail();
    } else {
      swal({
        icon: "error",
        text: `Należy uzupełnić wymagane pola formularza!
                      \n
                      ${requiredFields}
                      `,
        button: {
          text: "Ok",
        },
      });
    }
  };

  const submitDetailsAnalysis = (e) => {
    var requiredFields = fillRequiredFieldsAlert();
    if (validateForm()) {
      props.onClickDetailsAnalysis(e);
      sendWelcomeMail();
    } else {
      swal({
        icon: "error",
        text: `Należy uzupełnić wymagane pola formularza!
                      \n
                      ${requiredFields}
                      `,
        button: {
          text: "Ok",
        },
      });
    }
  };

  const focus = (e) => {
    e.target.select();
  };

  const sendWelcomeMail = () => {
    window.fbq("trackCustom", "contactPorCHF");
    window.gtag("event", "conversion", {
      send_to: "AW-10942401230/qwRMCJD73M0DEM6V3-Eo",
    });
    window.gtag("event", "click_contact");
    emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
    var templateParams = {
      name: clientName,
      mail: clientMail,
    };

    emailjs.send("Porównywarka CHF", "template_9kdrirk", templateParams).then(
      function (response) {},
      function (error) {}
    );
  };

  const regionList = [
    "Dolnośląskie",
    "Kujawsko-Pomorskie",
    "Lubelskie",
    "Lubuskie",
    "Łódzkie",
    "Małopolskie",
    "Mazowieckie",
    "Opolskie",
    "Podkarpackie",
    "Podlaskie",
    "Pomorskie",
    "Śląskie",
    "Świętokrzyskie",
    "Warmińsko-Mazurskie",
    "Wielkopolskie",
    "Zachodniopomorskie",
  ];

  return (
    <>
      <div className="mainContainerSecond">
        <div className="contentSecond">
          <div className="formContainerSecond">
            <div className="rowSecond">
              <div className="twoColSecond">
                <div className="oneCol_columnSecond">
                  <div className="formTitleSecond">
                    <label htmlFor="bankChoose">
                      Wybierz województwo
                      <span className="requiredStar">*</span>
                    </label>
                  </div>
                  <RSelect
                    className="currencySelect"
                    value={clientLocalization}
                    onChange={changeClientLocalization}
                    options={regionList.map((region) => ({
                      value: region,
                      key: region,
                      label: (
                        <>
                          <img
                            src="https://porownywarkachfapi.ftpeople.pl/select_region.png"
                            width="20px"
                            className="imgSelect"
                          />
                          <span className="selectInside">{region}</span>
                        </>
                      ),
                    }))}
                    placeholder={
                      clientLocalization
                        ? clientLocalization
                        : "Wybierz z listy..."
                    }
                  />
                </div>
                <div className="formSepSecond"></div>
                <div className="oneCol_columnSecond">
                  <div className="formTitleSecond">
                    <label htmlFor="bankChoose">
                      Co jest dla Ciebie wazne?
                      <span className="requiredStar">*</span>
                    </label>
                  </div>
                  <div className="chooseExpectationsBoxSecond">
                    <button
                      className={
                        clientExpectations === "Ilość wygranych spraw"
                          ? "expectationsPointSecondActive"
                          : "expectationsPointSecond"
                      }
                      onClick={changeClientExpectations}
                      value="Ilość wygranych spraw"
                    >
                      <FontAwesomeIcon icon={faFlagCheckered} />
                      Ilość wygranych
                    </button>
                    <button
                      className={
                        clientExpectations === "Lokalizacja"
                          ? "expectationsPointSecondActive"
                          : "expectationsPointSecond"
                      }
                      onClick={changeClientExpectations}
                      value="Lokalizacja"
                    >
                      <FontAwesomeIcon icon={faMapPin} />
                      Lokalizacja
                    </button>
                    <button
                      className={
                        clientExpectations === "Cena"
                          ? "expectationsPointSecondActive"
                          : "expectationsPointSecond"
                      }
                      onClick={changeClientExpectations}
                      value="Cena"
                    >
                      <FontAwesomeIcon icon={faMoneyBill} />
                      Cena
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="rowSep" />
            <div className="rowSecond">
              <div className="formTitleASecond">
                <label htmlFor="bankChoose">
                  Dane kontaktowe kredytobiorcy
                  <span className="requiredStar">*</span>
                </label>
              </div>
              <div className="twoColSecond">
                <div className="oneColA_columnSecond">
                  <input
                    type="text"
                    id={clientName ? "userName" : "requiredInput"}
                    name="userName"
                    placeholder="Imię i nazwisko"
                    value={clientName}
                    onChange={changeClientName}
                    onFocus={focus}
                  />
                </div>
              </div>
              <div id="twoColSecond_spec" className="twoColSecond">
                <div className="twoCol_columnASecond">
                  <input
                    type="text"
                    id={clientPhone ? "userPhone" : "requiredInput"}
                    name="userPhone"
                    placeholder="Numer telefonu (9 cyfr)"
                    value={clientPhone}
                    onChange={changeClientPhone}
                    onFocus={focus}
                  />
                </div>
                <div className="twoCol_columnASecond">
                  <input
                    type="text"
                    id={clientMail ? "userMail" : "requiredInput"}
                    name="userMail"
                    placeholder="Adres e-mail"
                    value={clientMail}
                    onChange={changeClientMail}
                    onFocus={focus}
                  />
                </div>
              </div>
              <div className="twoColSecond">
                <div className="oneColB_columnSecond">
                  <label className="containerPrivacyBox">
                    Oświadczam, że zapoznałem się z{" "}
                    <a
                      className="linkHref"
                      href="https://porownywarkachf.pl/polityka-prywatnosci/"
                      target="_blank"
                    >
                      Polityką prywatności
                    </a>{" "}
                    oraz akceptuje jej postanowienia i wyrażam zgodę na kontakt
                    kancelarii w niej wymienionych.
                    <span className="requiredStar">*</span>
                    <input type="checkbox" onClick={changeClientAcceptPolicy} />
                    <span className="checkmarkPrivacyBox"></span>
                  </label>

                  {clientAcceptPolicy ? (
                    <div className="buttonsBoxSecondPage">
                      <button
                        className="analysisTriggerBox"
                        onClick={submitDetailsAnalysis}
                        disabled={!clientAcceptPolicy}
                      >
                        <div className="analysisTriggerBoxDescSection">
                          <p className="analysisTriggerBoxDescSectionTitle">
                            WYKONAJ ANALIZĘ
                          </p>
                          <p className="analysisTriggerBoxDescSectionDesc">
                            i przejdź do ofert kancelarii CHF
                          </p>
                        </div>
                        <div className="analysisTriggerBoxImageBox">
                          <img
                            className="analysisTriggerBoxImage"
                            src="https://porownywarkachfapi.ftpeople.pl/second_page_2.png"
                          />
                        </div>
                      </button>

                      <button
                        className="comparerTriggerBox"
                        onClick={submitSecondForm}
                        disabled={!clientAcceptPolicy}
                      >
                        <div className="analysisTriggerBoxDescSection">
                          <p className="analysisTriggerBoxDescSectionTitle">
                            PORÓWNAJ OFERTY
                          </p>
                          <p className="analysisTriggerBoxDescSectionDesc">
                            i wybierz swoją kancelarię CHF
                          </p>
                        </div>
                        <div className="analysisTriggerBoxImageBox">
                          <img
                            className="analysisTriggerBoxImage"
                            src="https://porownywarkachfapi.ftpeople.pl/second_page_1.png"
                          />
                        </div>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="rowSecond">
              <div className="twoColSecond">
                <div className="twoCol_columnSecond">
                  <button
                    className="submitButtonSecondPage"
                    onClick={props.onClickPrevoiusPage}
                  >
                    ← Wstecz
                  </button>
                </div>
              </div>
            </div>
            <div className="rowSecondMobile">
              <div className="formTitleASecond">
                <label htmlFor="bankChoose">
                  Taki widok zobaczysz po przejściu dalej
                </label>
              </div>
              <div
                style={{ marginBottom: "5vh" }}
                className="secondImageCenter"
              >
                <ReactPlayer
                  width="100%"
                  height="100%"
                  loop={true}
                  url="https://porownywarkachf.pl/wp-content/uploads/2023/08/FILM-PCHF.mp4"
                  playing={true}
                />
                <span style={{ fontSize: "2vw", textAlign: "left" }}>
                  Podejmiemy 5 prób kontaktu, jeżeli nie uda nam się z Tobą
                  skontaktować telefonicznie naszy system automatycznie
                  zarekomenduje kancelarie prawne na podstawie wprowadzonych do
                  formularza parametrów.
                </span>
              </div>
              <div className="contact_first_page">
                <div className="contact_first_page_box1">
                  <img
                    className="contact_first_page_ico"
                    src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"
                  />
                </div>
                <div className="contact_first_page_box2">
                  <p className="contact_first_page_title">
                    Potrzebujesz pomocy?
                  </p>
                  <p className="contact_first_page_desc">
                    Karolina chętnie Ci pomoże
                  </p>
                  <div className="contact_first_page_details_box">
                    <div className="contact_first_page_details_col">
                      <div className="contactIco">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>
                      71 331 78 21
                    </div>
                    <div className="contact_first_page_details_col">
                      <div className="contactIco">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                      <a
                        className="contactHref"
                        href="mailto: biuro@porownywarkachf.pl"
                      >
                        biuro@porownywarkachf.pl
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="descContainerSecond">
            <div className="formTitleASecond">
              <label htmlFor="bankChoose">
                Taki widok zobaczysz po przejściu dalej
              </label>
            </div>
            <ReactPlayer
              width="100%"
              loop={true}
              url="https://porownywarkachf.pl/wp-content/uploads/2023/08/FILM-PCHF.mp4"
              playing={true}
            />
            <span style={{ fontSize: ".5vw" }}>
              Podejmiemy 5 prób kontaktu, jeżeli nie uda nam się z Tobą
              skontaktować telefonicznie naszy system automatycznie
              zarekomenduje kancelarie prawne na podstawie wprowadzonych do
              formularza parametrów.
            </span>
            <div style={{ height: "5vh" }}></div>
            <div className="contact_first_page">
              <div className="contact_first_page_box1">
                <img
                  className="contact_first_page_ico"
                  src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"
                />
              </div>
              <div className="contact_first_page_box2">
                <p className="contact_first_page_title">Potrzebujesz pomocy?</p>
                <p className="contact_first_page_desc">
                  Karolina chętnie Ci pomoże
                </p>
                <div className="contact_first_page_details_box">
                  <div className="contact_first_page_details_col">
                    <div className="contactIco">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    71 331 78 21
                  </div>
                  <div className="contact_first_page_details_col">
                    <div className="contactIco">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <a
                      className="contactHref"
                      href="mailto: biuro@porownywarkachf.pl"
                    >
                      biuro@porownywarkachf.pl
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondPage;
