import { useState, useEffect } from 'react';
import '../../../components/Styles/AdminLogoSection.css';
import swal from 'sweetalert';

const LogoSection = (props) => {
    const [logoImage, setLogoImage] = useState(null);
    const [logoImageUrl, setLogoImageUrl] = useState(null);

    useEffect(() => {
        if (logoImage) {
          setLogoImageUrl(URL.createObjectURL(logoImage));
        }
      }, [logoImage]);

      const changeLogoImage = (e) => {
        setLogoImage(e.target.files[0]);
      }

      const fetchFileLogoUpload = async () => {
        let formData = new FormData()
        formData.append('logo', logoImage);

        await fetch('https://porownywarkachfapi.ftpeople.pl/uploadlogo?company='+encodeURI(props.company), {
            method: 'POST',
            body: formData,})
        .then(response => response.text())
        .then(data => {
            console.log(data)
            setLogoImage(null);
            props.getData();
        })
        .catch(error => {
            
        })
    }

      const sendLogoFileInput = (e) => {
        props.startLoading(true);
        fetchFileLogoUpload();
      }

return(
    <>
        <div className="mainContainerAdminCompanyData">
          <span className="sectionTitle">Logo</span>
          <div className="logoUploadBox">
            <div className="fileUploadBox">
              <div className="fileUploadButton">
                <input className="fileLogoInput" type="file" name="logo" onChange={changeLogoImage}/>
              </div>
            </div>
            <div className="logoInputPreviewBox">
              {logoImage 
                ?
                <>
                  <img className="logoPreview" src={logoImageUrl} alt="logo_preview"/><br/>
                </>
                :
                ''
              }
            </div>
          </div>  
          {logoImage 
                ?
                <>
                  <button className="fileSentButton" onClick={sendLogoFileInput}>ZAPISZ</button>
                </>
                :
                ''
              }
        </div>
    </>
)

}

export default LogoSection;