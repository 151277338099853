import React from 'react';
import '../Styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

const Header = (props) => {
    return ( 
        <>
            <div className="twoColContainerH">
                <div className="firstColHeaderClient">
                    <a href="https://porownywarkachf.pl"><img alt="logo" className="logoImageHeader" src="https://porownywarkachfapi.ftpeople.pl/logo_main.png"/> </a> 
                </div>
                <div className="secondColHeaderClient">
                    <span><a className="headerLinkClient" href="https://porownywarkachf.pl" rel="noreferrer" target="_blank">Strona główna</a></span>
                    <span><a className="headerLinkClient" href="https://porownywarkachf.pl/jak-dziala-kalkulator-umowy-chf/" rel="noreferrer" target="_blank">Jak to działa?</a></span>
                    <span><a className="headerLinkClient" href="https://porownywarkachf.pl/wygrane-frankowiczow-aktualne-informacje-blog/" rel="noreferrer" target="_blank">Blog</a></span>
                    <span><a className="headerLinkClient" href="https://porownywarkachf.pl/kancelarie-frankowiczow-porownaj-oferty/" rel="noreferrer" target="_blank">Kontakt</a></span>
                </div>
                <div className="thirdColHeader">
                    <div className="socialIconHeaderClient"><a className="socialLink" href="https://www.facebook.com/porownywarkaCHF" target="_blank"><FontAwesomeIcon icon={faFacebookF}/></a></div>
                </div>
            </div>
        </>
     );
}
 
export default Header;