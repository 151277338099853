import '../../components/Styles/Comparer.css';
import FirstPage from './FirstPage/FirstPage';
import SecondPage from './SecondPage/SecondPage';
import ThirdPage from './ThirdPage/ThirdPage';
import FourthPage from './FourthPage/FourthPage';
import FifthPage from './FifthPage/FifthPage';
import LoginPage from './LoginPage/LoginPage';
import Steps from '../../components/Steps/Steps';
import React, {Component} from 'react';
import CreditLengthChange from '../../classes/CreditLengthChange';
import CreditMarginChange from '../../classes/CreditMarginChange';
import CreditSubsequentsTranchesChange from '../../classes/CreditSubsequentsTranchesChange';
import CreditEarlyRepaymentChange from '../../classes/CreditEarlyRepaymentChange';
import Swal2 from 'sweetalert2';
import swal from 'sweetalert';
import withReactContent from 'sweetalert2-react-content';
import Header from '../../components/Header/Header';

const MySwal = withReactContent(Swal2);

class Comparer extends Component {
  
  state = {
    creditCurrency: 'PLN',
    currencyToggleChecked: false,
    step: 1,
    page: 1,
    creditBank: '',
    creditDate: '2005-05-05',
    creditAmmount: '',
    userLocalization: '',
    userName: '',
    userMail: '',
    userPhone: '',
    creditFinalized: 'No',
    creditFinalizedToggleChecked: false,
    creditLengthChange: 'No',
    creditLengthChangeToggleChecked: false,
    creditLengthChanges: [],
    actualCreditLengthChangeRowDate: '',
    actualCreditLengthChangeRowValue: 0,
    creditMarginChange: 'No',
    creditMarginChangeToggleChecked: false,
    creditMarginChanges: [],
    actualCreditMarginChangeRowDate: '',
    actualCreditMarginChangeRowValue: 0,
    creditSubsequentsTranchesChange: 'No',
    creditSubsequentsTranchesChangeToggleChecked: false,
    creditSubsequentsTranchesChanges: [],
    actualCreditSubsequentsTranchesChangeRowDate: '',
    actualCreditSubsequentsTranchesChangeRowValue: 0,
    actualCreditSubsequentsTranchesChangeRowCurrency: 'PLN',
    creditEarlyRepaymentChange: 'No',
    creditEarlyRepaymentChangeToggleChecked: false,
    creditEarlyRepaymentChanges: [],
    actualCreditEarlyRepaymentChangeRowDate: '',
    actualCreditEarlyRepaymentChangeRowValue: 0,
    actualCreditEarlyRepaymentChangeRowCurrency: 'PLN',
    creditMargin: '',
    creditLength: '',
    creditType: '',
    creditGracePeriod: '',
    creditFinalDate: '',
    agreementDetailsText: '',
    agreements: [],
    scripturl: '',
    analysis: 0,
    userExpectations: '',
    userID: '',
    userPass: '',
    userDetailData: [],
    userBasicData: [],
    stepsDisplay: true,
    validate1: 0,
    validate2: 0,
    validate3: 0,
    companies: [],
    companiesData: [],
    companiesDescription: [],
    companiesFiles: [],
    companiesOffer: [],
    chooseCompanyArray: [],
    clientAcceptPolicy: false,
    newAssingmentDate: '',
    companyJudgments: [],
  }

  showLoginForm = () => {
    MySwal.fire({
      title: 'Zaloguj się',
      html: `
      <span className="labelSwal">Hasło</span><input type="password" name="login" id="password" className="swal2-input">`,
      confirmButtonText: 'Zaloguj się',
      allowOutsideClick: false,
      allowEscapeKey: false,
      focusConfirm: false,
      preConfirm: () => {
        const password = Swal2.getPopup().querySelector('#password').value
        if (!password) {
          Swal2.showValidationMessage(`Proszę wypełnić dane`)
        }
        return { password: password}
      }
    }).then((result) => {
      const passwordToCheck = result.value.password;
      //const loginToCheck = result.value.login;
      var resultCheck = passwordToCheck === 'chF2022';
      if(!resultCheck){
        MySwal.fire({
          title: 'NIEPRAWIDŁOWE HASŁO',
          icon: 'error'
        }).then(results => {
          setTimeout(window.location.reload(false),0);
        })
      }
    })
  }

  getCompaniesFullData(){
    this.fetchCompanies();
    this.fetchCompaniesData();
    this.fetchCompaniesDescription();
    this.fetchCompaniesFiles();
    this.fetchCompaniesOffer();
    this.fetchCompanyJudgments();
  }

  componentDidMount(){
    //this.showLoginForm();
    this.fetchAgreements();
    this.fetchScript();
    this.getCompaniesFullData();
    this.checkStartup();
  }

  getQueryVariable(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] === variable){
        return decodeURIComponent(pair[1]);
      }
    }
    return(false);
  }

  checkStartup = () => {
    if(+this.getQueryVariable('login') === 1){
      this.setState({
        page: 5,
        step: 5,
        stepsDisplay: true,
      })
    }else if (+this.getQueryVariable('redirect') === 1){
      this.setState({
        page: 2,
        step: 2,
        stepsDisplay: true,
        creditBank: "mBank S.A. - BRE Bank S.A. | Umowa z 2004 r. mPlan", //to FIX redirect bank data
        creditAmmount: +this.getQueryVariable('ammount'),
        creditCurrency: this.getQueryVariable('currency'),
        creditDate: this.getQueryVariable('date'),
      })
    }
  }

  fetchAgreements = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/agreements')
    .then(response => response.json())
    .then(data => {
      this.setState({
        agreements: data,
      })
    })
  }

  fetchLastAssingmentDate = (id) => {
    fetch('https://porownywarkachfapi.ftpeople.pl/get_leads_assingments_id_all?id='+id)
    .then(response => response.json())
    .then(data => {
      if(data.length > 0){
        this.setState({
          newAssingmentDate: this.addDays(data[0].dateAssingment,30),
        })
      }
    })
  }

  addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  fetchScript = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/scripturl')
    .then(response => response.json())
    .then(data => {
      this.setState({
        scripturl: data[0].url,
      })
    })
  }
  
  fetchCompanies = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/company_all')
    .then(response => response.json())
    .then(data => {
      this.setState({
        companies: data,
      })
    })
  }

  fetchCompaniesData = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/company_data_all')
    .then(response => response.json())
    .then(data => {
      this.setState({
        companiesData: data,
      })
    })
  }

  fetchCompaniesDescription = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/company_description_all')
    .then(response => response.json())
    .then(data => {
      this.setState({
        companiesDescription: data,
      })
    })
  }

  fetchCompaniesFiles = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/company_files_all')
    .then(response => response.json())
    .then(data => {
      this.setState({
        companiesFiles: data,
      })
    })
  }

  fetchCompaniesOffer = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/company_offer_all')
    .then(response => response.json())
    .then(data => {
      this.setState({
        companiesOffer: data,
      })
    })
  }


  fetchUserDetailsByPass = () => {
    fetch('https://porownywarkachfapi.ftpeople.pl/get_lead_details_by_pass?userpass="'+encodeURI(this.state.userPass)+'"')
    .then(response => response.json())
    .then(data => {
      this.setState({
        userDetailData: data,
      })
    })
  }

  fetchUserBasicById = (id) => {
    fetch('https://porownywarkachfapi.ftpeople.pl/get_lead_basic_by_id?id="'+encodeURI(id)+'"')
    .then(response => response.json())
    .then(data => {
      this.setState({
        creditBank: data[0].creditAgreement,
        creditAmmount: data[0].creditAmmount,
        creditDate: data[0].creditDate,
        userLocalization: data[0].leadArea,
        userExpectations: data[0].leadExpectations,
        userName: data[0].name,
        userMail: data[0].mail,
        userPhone: data[0].phone,
        userID: data[0].id,
        userBasicData: data,
      })
    })
  }

  fetchAgreementText = (agreement) => {
    fetch('https://porownywarkachfapi.ftpeople.pl/agreement_details?agreement="'+encodeURI(agreement)+'"')
    .then(response => response.json())
    .then(data => {
      var pointFinal = '';
      pointFinal = data.map((point)=>(pointFinal + point.point.replaceAll("_","\n") + "\n\n"));
      this.setState({
        agreementDetailsText: pointFinal,
      })
    })
  }

  fetchNewBasicLeadInsert = () => {
    var body = {
        name: this.state.userName,
        phone: this.state.userPhone,
        mail: this.state.userMail,
        creditAgreement: this.state.creditBank,
        creditAmmount: this.state.creditAmmount,
        creditDate: this.state.creditDate,
        creditCurrency: this.state.creditCurrency,
        leadArea: this.state.userLocalization,
        leadExpectations: this.state.userExpectations
    };

    fetch('https://porownywarkachfapi.ftpeople.pl/insert_lead_basic', {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
        if(data.insertId === 0){
          
        }else{
          this.setState(prevState => ({
            userID: data.insertId,
          }));
        }
        
    })
    .catch(error => {
        
    })
}

fetchCompanyJudgments = () => {
        
  var body = {
      
  };

  fetch('https://porownywarkachfapi.ftpeople.pl/get_judgments', {
      method: 'POST', 
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
  })
  .then(response => response.json())
  .then(data => {
    this.setState({
      companyJudgments: data,
    })
  })
  .catch(error => {
      
  })
}

fetchNewLeadAssingments = (leadAssingmentsData, leadId) => {
        
  var body = {
      assingments: leadAssingmentsData,
      leadId: leadId
  };

  fetch('https://porownywarkachfapi.ftpeople.pl/insert_lead_assingments', {
      method: 'POST', 
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
  })
  .then(response => response.json())
  .then(data => {

  })
  .catch(error => {
      
  })
}

onChangeCreditCurrency = (e) => {
    this.setState(prevState => ({
      creditCurrency: e.target.checked ? 'CHF' : 'PLN',
      currencyToggleChecked: !prevState.currencyToggleChecked,
    }));
  }

onChangeThirdPageToggle = (e) => {

    if(e.target.name==="finalizedCredit"){
      this.setState(prevState => ({
        creditFinalized: e.target.checked ? 'Yes' : 'No',
        creditFinalizedToggleChecked: !prevState.creditFinalizedToggleChecked,
      }));
    }else if(e.target.name==="creditLengthChange"){
      this.setState(prevState => ({
        creditLengthChange: e.target.checked ? 'Yes' : 'No',
        creditLengthChangeToggleChecked: !prevState.creditLengthToggleChecked,
      }));
    }else if(e.target.name==="creditMarginChange"){
      this.setState(prevState => ({
        creditMarginChange: e.target.checked ? 'Yes' : 'No',
        creditMarginChangeToggleChecked: !prevState.creditMarginToggleChecked,
      }));
    }else if(e.target.name==="creditSubsequentsTranchesChange"){
      this.setState(prevState => ({
        creditSubsequentsTranchesChange: e.target.checked ? 'Yes' : 'No',
        creditSubsequentsTranchesChangeToggleChecked: !prevState.creditSubsequentsTranchesToggleChecked,
      }));
    }else if(e.target.name==="creditEarlyRepaymentChange"){
      this.setState(prevState => ({
        creditEarlyRepaymentChange: e.target.checked ? 'Yes' : 'No',
        creditEarlyRepaymentChangeToggleChecked: !prevState.creditEarlyRepaymentToggleChecked,
      }));
    }
  }

  onChangeCreditBank = (e) => {
    this.setState(prevState => ({
      creditBank: e.value,
    }));
    this.fetchAgreementText(e.value);
  }

  componentDidUpdate(){
    
  }

  onChangeCreditType = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      creditType: e.target.value,
    }));
  }

  onChangeCreditDate = (e) => {
    var year = e.getFullYear();
    var month = (e.getMonth()+1).toString().length === 1 ? "0"+(e.getMonth()+1) : e.getMonth()+1;
    var day = e.getUTCDate().toString().length === 1 ? "0"+(e.getUTCDate()) : e.getUTCDate();
    this.setState(prevState => ({
      creditDate: year+"-"+month+"-"+day,
    }));
  }

  onChangeCreditFinalDate = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      creditFinalDate: e.target.value,
    }));
  }

  onChangeCreditAmmount = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      creditAmmount: isNaN(parseFloat(e.target.value)) ? prevState.creditAmmount : parseFloat(e.target.value),
    }));
  }

  onChangeCreditGracePeriod = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      creditGracePeriod: isNaN(parseFloat(e.target.value)) ? prevState.creditGracePeriod : parseFloat(e.target.value),
    }));
  }

  onChangeCreditMargin = (e) => {
    e.preventDefault();

    var organizedValue = e.target.value.replaceAll(",",".");
    
    if(e.target.value === "" || e.target.value === 0){
      this.setState({
        creditMargin: 0,
      })
    }else{
      this.setState({
        creditMargin: +organizedValue,
      })
    }
  }

  onChangeCreditLength = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      creditLength: isNaN(parseFloat(e.target.value)) ? prevState.creditLength : parseFloat(e.target.value),
    }));
  }

  onChangeUserLocalization = (e) => {
    this.setState(prevState => ({
      userLocalization: e.value,
    }));
  }

  onChangeUserExpectations = (e) => {
    this.setState(prevState => ({
      userExpectations: e.target.value,
    }));
  }

  onChangeUserName = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      userName: e.target.value,
    }));
  }

  onChangeUserMail = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      userMail: e.target.value,
    }));
  }

  onChangeUserPhone = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      userPhone: e.target.value,
    }));
  }

  onSubmitFirstForm = (e) => {
      this.setState(prevState => ({
        page: 2,
        step: 2,
      }));
  }

  onClickDetailsAnalysis = (e) => {
      this.fetchNewBasicLeadInsert(); 
      this.setState(prevState => ({
        page: 3,
        step: 3,
        stepsDisplay: true,
      }));
  }

  onClickPrevoiusPage = (e) => {
      this.setState(prevState => ({
        page: prevState.page-1,
        step: prevState.step-1,
      }));
  }

  onSubmitSecondForm = (e) => {
      this.fetchNewBasicLeadInsert(); 
      this.setState(prevState => ({
        page: 4,
        step: 4,
        stepsDisplay: true,
      }));
  }

  onChooseCompanies = (chooseCompanyArray) => {
      this.setState(prevState => ({
        chooseCompanyArray: chooseCompanyArray,
        page: 6,
        step: 6,
      }));
  }

  handleActualCreditLengthChangeRowDateChange = (e) => {
    e.preventDefault();

      this.setState({
        actualCreditLengthChangeRowDate: e.target.value,
      })    
  }

  handleActualCreditLengthChangeRowValueChange = (e) => {
    e.preventDefault();

    if(e.target.value === "" || e.target.value === "0"){
      this.setState({
        actualCreditLengthChangeRowValue: 0,
      })
    }else{
      this.setState({
        actualCreditLengthChangeRowValue: +e.target.value,
      })
    }   
  }

  handleAddCreditLengthChange = (e) => {
    e.preventDefault();

    if(this.state.creditLengthChanges.length === 6 || this.state.actualCreditLengthChangeRowValue === 0 || this.state.actualCreditLengthChangeRowDate === ''){
      return;
    }

    this.setState (prevState => ({
        creditLengthChanges: [...prevState.creditLengthChanges, new CreditLengthChange(prevState.creditLengthChanges.length+1, this.state.actualCreditLengthChangeRowDate, this.state.actualCreditLengthChangeRowValue)],
        actualCreditLengthChangeRowDate: '',
        actualCreditLengthChangeRowValue: 0,
      }))
  }

  handleDeleteCreditLengthChange = (e) => {
    e.preventDefault();
    
    this.setState (prevState => ({
      creditLengthChanges: prevState.creditLengthChanges.filter(creditLengthChange => creditLengthChange.key !== +e.target.value),
    }))
  }

  handleActualCreditMarginChangeRowDateChange = (e) => {
    e.preventDefault();

      this.setState({
        actualCreditMarginChangeRowDate: e.target.value,
      })    
  }

  handleActualCreditMarginChangeRowValueChange = (e) => {
    e.preventDefault();

    if(e.target.value === "" || e.target.value === "0"){
      this.setState({
        actualCreditMarginChangeRowValue: 0,
      })
    }else{
      this.setState({
        actualCreditMarginChangeRowValue: +e.target.value,
      })
    }   
  }

  handleAddCreditMarginChange = (e) => {
    e.preventDefault();

    if(this.state.creditMarginChanges.length === 6 || this.state.actualCreditMarginChangeRowValue === 0 || this.state.actualCreditMarginChangeRowDate === ''){
      return;
    }

    this.setState (prevState => ({
        creditMarginChanges: [...prevState.creditMarginChanges, new CreditMarginChange(prevState.creditMarginChanges.length+1, this.state.actualCreditMarginChangeRowDate, this.state.actualCreditMarginChangeRowValue)],
        actualCreditMarginChangeRowDate: '',
        actualCreditMarginChangeRowValue: 0,
      }))
  }

  handleDeleteCreditMarginChange = (e) => {
    e.preventDefault();
    
    this.setState (prevState => ({
      creditMarginChanges: prevState.creditMarginChanges.filter(creditMarginChange => creditMarginChange.key !== +e.target.value),
    }))
  }

  handleActualCreditSubsequentsTranchesChangeRowDateChange = (e) => {
    e.preventDefault();

      this.setState({
        actualCreditSubsequentsTranchesChangeRowDate: e.target.value,
      })    
  }

  handleActualCreditSubsequentsTranchesChangeRowValueChange = (e) => {
    e.preventDefault();

    if(e.target.value === "" || e.target.value === "0"){
      this.setState({
        actualCreditSubsequentsTranchesChangeRowValue: 0,
      })
    }else{
      this.setState({
        actualCreditSubsequentsTranchesChangeRowValue: +e.target.value,
      })
    }   
  }

  handleActualCreditSubsequentsTranchesChangeRowCurrencyChange = (e) => {
    e.preventDefault();

    this.setState({
        actualCreditSubsequentsTranchesChangeRowCurrency: e.target.value,
      }) 
  }

  handleAddCreditSubsequentsTranchesChange = (e) => {
    e.preventDefault();

    if(this.state.creditSubsequentsTranchesChanges.length === 6 || this.state.actualCreditSubsequentsTranchesChangeRowValue === 0 || this.state.actualCreditSubsequentsTranchesChangeRowDate === ''){
      return;
    }

    this.setState (prevState => ({
        creditSubsequentsTranchesChanges: [...prevState.creditSubsequentsTranchesChanges, new CreditSubsequentsTranchesChange(prevState.creditSubsequentsTranchesChanges.length+1, this.state.actualCreditSubsequentsTranchesChangeRowDate, this.state.actualCreditSubsequentsTranchesChangeRowValue, this.state.actualCreditSubsequentsTranchesChangeRowCurrency)],
        actualCreditSubsequentsTranchesChangeRowDate: '',
        actualCreditSubsequentsTranchesChangeRowValue: 0,
        actualCreditSubsequentsTranchesChangeRowCurrency: 'PLN',
      }))
  }

  handleDeleteCreditSubsequentsTranchesChange = (e) => {
    e.preventDefault();
    
    this.setState (prevState => ({
      creditSubsequentsTranchesChanges: prevState.creditSubsequentsTranchesChanges.filter(creditSubsequentsTranchesChange => creditSubsequentsTranchesChange.key !== +e.target.value),
    }))
  }

  handleActualCreditEarlyRepaymentChangeRowDateChange = (e) => {
    e.preventDefault();

      this.setState({
        actualCreditEarlyRepaymentChangeRowDate: e.target.value,
      })    
  }

  handleActualCreditEarlyRepaymentChangeRowValueChange = (e) => {
    e.preventDefault();

    if(e.target.value === "" || e.target.value === "0"){
      this.setState({
        actualCreditEarlyRepaymentChangeRowValue: 0,
      })
    }else{
      this.setState({
        actualCreditEarlyRepaymentChangeRowValue: +e.target.value,
      })
    }   
  }

  handleActualCreditEarlyRepaymentChangeRowCurrencyChange = (e) => {
    e.preventDefault();

    this.setState({
        actualCreditEarlyRepaymentChangeRowCurrency: e.target.value,
      }) 
  }

  handleAddCreditEarlyRepaymentChange = (e) => {
    e.preventDefault();

    if(this.state.creditEarlyRepaymentChanges.length === 6 || this.state.actualCreditEarlyRepaymentChangeRowValue === 0 || this.state.actualCreditEarlyRepaymentChangeRowDate === ''){
      return;
    }

    this.setState (prevState => ({
        creditEarlyRepaymentChanges: [...prevState.creditEarlyRepaymentChanges, new CreditEarlyRepaymentChange(prevState.creditEarlyRepaymentChanges.length+1, this.state.actualCreditEarlyRepaymentChangeRowDate, this.state.actualCreditEarlyRepaymentChangeRowValue, this.state.actualCreditEarlyRepaymentChangeRowCurrency)],
        actualCreditEarlyRepaymentChangeRowDate: '',
        actualCreditEarlyRepaymentChangeRowValue: 0,
        actualCreditEarlyRepaymentChangeRowCurrency: 'PLN',
      }))
  }

  handleDeleteCreditEarlyRepaymentChange = (e) => {
    e.preventDefault();
    
    this.setState (prevState => ({
      creditEarlyRepaymentChanges: prevState.creditEarlyRepaymentChanges.filter(creditEarlyRepaymentChange => creditEarlyRepaymentChange.key !== +e.target.value),
    }))
  }

  doAnalysis = (e) => {
    e.preventDefault();
    this.setNewCalculate();
    this.setState (prevState => ({
      analysis: 1,
      step: 5,
      page: 5,  
    }))
  }

  clientAcceptPolicy = (e) => {
    this.setState (prevState => ({
      clientAcceptPolicy: e.target.checked, 
    }))
  }

  checkValidateForSteps = () => {
    console.log('check');
    if(this.state.creditBank !== '' && this.state.creditDate !== '' && this.state.creditAmmount !== '' && this.state.creditCurrency !== ''){
      this.setState (prevState => ({
        validate1: 1,
      }))
    }

    if(this.state.userLocalization !== '' && this.state.userExpectations !== '' && this.state.userName !== '' && this.state.userPhone !== '' && this.state.userMail !== '' && this.state.clientAcceptPolicy){
      this.setState (prevState => ({
        validate2: 1,
      }))
    }

    if(this.state.creditMargin !== '' && this.state.creditLength !== '' && this.state.creditType !== ''){
      this.setState (prevState => ({
        validate3: 1,
      }))
    }
  }

  changePageBySteps = (e) => {
    this.checkValidateForSteps();

    if(this.state.validate1 === 1 && +e.target.value === 1){
      this.setState (prevState => ({
        step: +e.target.value,
        page: +e.target.value,  
        stepsDisplay: true,
      }))
    }else if(this.state.validate2 === 1 && +e.target.value === 2){
      this.setState (prevState => ({
        step: +e.target.value,
        page: +e.target.value,  
      }))
    } else if(this.state.validate3 === 1 && +e.target.value === 3){
      this.setState (prevState => ({
        step: +e.target.value,
        page: +e.target.value,  
      }))
    } else if(+e.target.value === 4){
      this.setState (prevState => ({
        step: +e.target.value,
        page: +e.target.value,  
      }))
    }
  }

  changeUserPass = (e) => {
    this.setState (prevState => ({
      userPass: e.target.value,
    }))
  }

  setNewCalculate(){

    var options, url;

    var payload = {
      "clientBank": this.state.creditBank,
      "clientName": this.state.userName,
      "clientPhone": this.state.userPhone,
      "clientMail": this.state.userMail,
      "clientCreditValueCHF": this.state.creditCurrency === 'CHF' ? this.state.creditAmmount : '',
      "clientCreditValuePLN": this.state.creditCurrency === 'PLN' ? this.state.creditAmmount : '',
      "clientCreditStartDate": this.state.creditDate,
      "clientCreditMargin": this.state.creditMargin/100,
      "clientCreditLength": this.state.creditLength,
      "clientCreditDelayLength": this.state.creditGracePeriod,
      "clientCreditSpreadValue": 3/100,
      "clientCreditLengthChangeDate1": this.state.creditLengthChanges.length > 0 ? this.state.creditLengthChanges[0].date : '',
      "clientCreditLengthChangeDate2": this.state.creditLengthChanges.length > 1 ? this.state.creditLengthChanges[1].date : '',
      "clientCreditLengthChangeDate3": this.state.creditLengthChanges.length > 2 ? this.state.creditLengthChanges[2].date : '',
      "clientCreditLengthChangeDate4": this.state.creditLengthChanges.length > 3 ? this.state.creditLengthChanges[3].date : '',
      "clientCreditLengthChangeDate5": this.state.creditLengthChanges.length > 4 ? this.state.creditLengthChanges[4].date : '',
      "clientCreditLengthChangeDate6": this.state.creditLengthChanges.length > 5 ? this.state.creditLengthChanges[5].date : '',
      "clientCreditLengthChangeValue1": this.state.creditLengthChanges.length > 0 ? this.state.creditLengthChanges[0].value : '',
      "clientCreditLengthChangeValue2": this.state.creditLengthChanges.length > 1 ? this.state.creditLengthChanges[1].value : '',
      "clientCreditLengthChangeValue3": this.state.creditLengthChanges.length > 2 ? this.state.creditLengthChanges[2].value : '',
      "clientCreditLengthChangeValue4": this.state.creditLengthChanges.length > 3 ? this.state.creditLengthChanges[3].value : '',
      "clientCreditLengthChangeValue5": this.state.creditLengthChanges.length > 4 ? this.state.creditLengthChanges[4].value : '',
      "clientCreditLengthChangeValue6": this.state.creditLengthChanges.length > 5 ? this.state.creditLengthChanges[5].value : '',
      "clientCreditMarginChangeDate1": this.state.creditMarginChanges.length > 0 ? this.state.creditMarginChanges[0].date : '',
      "clientCreditMarginChangeDate2": this.state.creditMarginChanges.length > 1 ? this.state.creditMarginChanges[1].date : '',
      "clientCreditMarginChangeDate3": this.state.creditMarginChanges.length > 2 ? this.state.creditMarginChanges[2].date : '',
      "clientCreditMarginChangeDate4": this.state.creditMarginChanges.length > 3 ? this.state.creditMarginChanges[3].date : '',
      "clientCreditMarginChangeDate5": this.state.creditMarginChanges.length > 4 ? this.state.creditMarginChanges[4].date : '',
      "clientCreditMarginChangeDate6": this.state.creditMarginChanges.length > 5 ? this.state.creditMarginChanges[5].date : '',
      "clientCreditMarginChangeValue1": this.state.creditMarginChanges.length > 0 ? this.state.creditMarginChanges[0].value/100 : '',
      "clientCreditMarginChangeValue2": this.state.creditMarginChanges.length > 1 ? this.state.creditMarginChanges[1].value/100 : '',
      "clientCreditMarginChangeValue3": this.state.creditMarginChanges.length > 2 ? this.state.creditMarginChanges[2].value/100 : '',
      "clientCreditMarginChangeValue4": this.state.creditMarginChanges.length > 3 ? this.state.creditMarginChanges[3].value/100 : '',
      "clientCreditMarginChangeValue5": this.state.creditMarginChanges.length > 4 ? this.state.creditMarginChanges[4].value/100 : '',
      "clientCreditMarginChangeValue6": this.state.creditMarginChanges.length > 5 ? this.state.creditMarginChanges[5].value/100 : '',
      "clientCreditSubsequentsTranchesDate1": this.state.creditSubsequentsTranchesChanges.length > 0 ? this.state.creditSubsequentsTranchesChanges[0].date : '', 
      "clientCreditSubsequentsTranchesDate2": this.state.creditSubsequentsTranchesChanges.length > 1 ? this.state.creditSubsequentsTranchesChanges[1].date : '', 
      "clientCreditSubsequentsTranchesDate3": this.state.creditSubsequentsTranchesChanges.length > 2 ? this.state.creditSubsequentsTranchesChanges[2].date : '', 
      "clientCreditSubsequentsTranchesDate4": this.state.creditSubsequentsTranchesChanges.length > 3 ? this.state.creditSubsequentsTranchesChanges[3].date : '', 
      "clientCreditSubsequentsTranchesDate5": this.state.creditSubsequentsTranchesChanges.length > 4 ? this.state.creditSubsequentsTranchesChanges[4].date : '', 
      "clientCreditSubsequentsTranchesDate6": this.state.creditSubsequentsTranchesChanges.length > 5 ? this.state.creditSubsequentsTranchesChanges[5].date : '', 
      "clientCreditSubsequentsTranchesValuePLN1": this.state.creditSubsequentsTranchesChanges.length > 0 && this.state.creditSubsequentsTranchesChanges[0].currency === 'PLN' ? this.state.creditSubsequentsTranchesChanges[0].value : '',
      "clientCreditSubsequentsTranchesValuePLN2": this.state.creditSubsequentsTranchesChanges.length > 1 && this.state.creditSubsequentsTranchesChanges[1].currency === 'PLN' ? this.state.creditSubsequentsTranchesChanges[1].value : '',
      "clientCreditSubsequentsTranchesValuePLN3": this.state.creditSubsequentsTranchesChanges.length > 2 && this.state.creditSubsequentsTranchesChanges[2].currency === 'PLN' ? this.state.creditSubsequentsTranchesChanges[2].value : '',
      "clientCreditSubsequentsTranchesValuePLN4": this.state.creditSubsequentsTranchesChanges.length > 3 && this.state.creditSubsequentsTranchesChanges[3].currency === 'PLN' ? this.state.creditSubsequentsTranchesChanges[3].value : '',
      "clientCreditSubsequentsTranchesValuePLN5": this.state.creditSubsequentsTranchesChanges.length > 4 && this.state.creditSubsequentsTranchesChanges[4].currency === 'PLN' ? this.state.creditSubsequentsTranchesChanges[4].value : '',
      "clientCreditSubsequentsTranchesValuePLN6": this.state.creditSubsequentsTranchesChanges.length > 5 && this.state.creditSubsequentsTranchesChanges[5].currency === 'PLN' ? this.state.creditSubsequentsTranchesChanges[5].value : '',
      "clientCreditSubsequentsTranchesValueCHF1": this.state.creditSubsequentsTranchesChanges.length > 0 && this.state.creditSubsequentsTranchesChanges[0].currency === 'CHF' ? this.state.creditSubsequentsTranchesChanges[0].value : '',
      "clientCreditSubsequentsTranchesValueCHF2": this.state.creditSubsequentsTranchesChanges.length > 1 && this.state.creditSubsequentsTranchesChanges[1].currency === 'CHF' ? this.state.creditSubsequentsTranchesChanges[1].value : '',
      "clientCreditSubsequentsTranchesValueCHF3": this.state.creditSubsequentsTranchesChanges.length > 2 && this.state.creditSubsequentsTranchesChanges[2].currency === 'CHF' ? this.state.creditSubsequentsTranchesChanges[2].value : '',
      "clientCreditSubsequentsTranchesValueCHF4": this.state.creditSubsequentsTranchesChanges.length > 3 && this.state.creditSubsequentsTranchesChanges[3].currency === 'CHF' ? this.state.creditSubsequentsTranchesChanges[3].value : '',
      "clientCreditSubsequentsTranchesValueCHF5": this.state.creditSubsequentsTranchesChanges.length > 4 && this.state.creditSubsequentsTranchesChanges[4].currency === 'CHF' ? this.state.creditSubsequentsTranchesChanges[4].value : '',
      "clientCreditSubsequentsTranchesValueCHF6": this.state.creditSubsequentsTranchesChanges.length > 5 && this.state.creditSubsequentsTranchesChanges[5].currency === 'CHF' ? this.state.creditSubsequentsTranchesChanges[5].value : '',
      "clientCreditEarlyRepaymentDate1": this.state.creditEarlyRepaymentChanges.length > 0 ? this.state.creditEarlyRepaymentChanges[0].date : '', 
      "clientCreditEarlyRepaymentDate2": this.state.creditEarlyRepaymentChanges.length > 1 ? this.state.creditEarlyRepaymentChanges[1].date : '', 
      "clientCreditEarlyRepaymentDate3": this.state.creditEarlyRepaymentChanges.length > 2 ? this.state.creditEarlyRepaymentChanges[2].date : '', 
      "clientCreditEarlyRepaymentDate4": this.state.creditEarlyRepaymentChanges.length > 3 ? this.state.creditEarlyRepaymentChanges[3].date : '', 
      "clientCreditEarlyRepaymentDate5": this.state.creditEarlyRepaymentChanges.length > 4 ? this.state.creditEarlyRepaymentChanges[4].date : '', 
      "clientCreditEarlyRepaymentDate6": this.state.creditEarlyRepaymentChanges.length > 5 ? this.state.creditEarlyRepaymentChanges[5].date : '', 
      "clientCreditEarlyRepaymentValuePLN1": this.state.creditEarlyRepaymentChanges.length > 0 && this.state.creditEarlyRepaymentChanges[0].currency === 'PLN' ? this.state.creditEarlyRepaymentChanges[0].value : '',
      "clientCreditEarlyRepaymentValuePLN2": this.state.creditEarlyRepaymentChanges.length > 1 && this.state.creditEarlyRepaymentChanges[1].currency === 'PLN' ? this.state.creditEarlyRepaymentChanges[1].value : '',
      "clientCreditEarlyRepaymentValuePLN3": this.state.creditEarlyRepaymentChanges.length > 2 && this.state.creditEarlyRepaymentChanges[2].currency === 'PLN' ? this.state.creditEarlyRepaymentChanges[2].value : '',
      "clientCreditEarlyRepaymentValuePLN4": this.state.creditEarlyRepaymentChanges.length > 3 && this.state.creditEarlyRepaymentChanges[3].currency === 'PLN' ? this.state.creditEarlyRepaymentChanges[3].value : '',
      "clientCreditEarlyRepaymentValuePLN5": this.state.creditEarlyRepaymentChanges.length > 4 && this.state.creditEarlyRepaymentChanges[4].currency === 'PLN' ? this.state.creditEarlyRepaymentChanges[4].value : '',
      "clientCreditEarlyRepaymentValuePLN6": this.state.creditEarlyRepaymentChanges.length > 5 && this.state.creditEarlyRepaymentChanges[5].currency === 'PLN' ? this.state.creditEarlyRepaymentChanges[5].value : '',
      "clientCreditEarlyRepaymentValueCHF1": this.state.creditEarlyRepaymentChanges.length > 0 && this.state.creditEarlyRepaymentChanges[0].currency === 'CHF' ? this.state.creditEarlyRepaymentChanges[0].value : '',
      "clientCreditEarlyRepaymentValueCHF2": this.state.creditEarlyRepaymentChanges.length > 1 && this.state.creditEarlyRepaymentChanges[1].currency === 'CHF' ? this.state.creditEarlyRepaymentChanges[1].value : '',
      "clientCreditEarlyRepaymentValueCHF3": this.state.creditEarlyRepaymentChanges.length > 2 && this.state.creditEarlyRepaymentChanges[2].currency === 'CHF' ? this.state.creditEarlyRepaymentChanges[2].value : '',
      "clientCreditEarlyRepaymentValueCHF4": this.state.creditEarlyRepaymentChanges.length > 3 && this.state.creditEarlyRepaymentChanges[3].currency === 'CHF' ? this.state.creditEarlyRepaymentChanges[3].value : '',
      "clientCreditEarlyRepaymentValueCHF5": this.state.creditEarlyRepaymentChanges.length > 4 && this.state.creditEarlyRepaymentChanges[4].currency === 'CHF' ? this.state.creditEarlyRepaymentChanges[4].value : '',
      "clientCreditEarlyRepaymentValueCHF6": this.state.creditEarlyRepaymentChanges.length > 5 && this.state.creditEarlyRepaymentChanges[5].currency === 'CHF' ? this.state.creditEarlyRepaymentChanges[5].value : '',
      "analysisAuthor": this.state.userName,
      "clientCreditAgreementPoints": this.state.agreementDetailsText.length === '' ? '' : this.state.agreementDetailsText,
      "dateCancelCredit": this.state.creditFinalDate,
      "creditType": this.state.creditType,
      "expertMail": this.state.userMail,
      "leadId": this.state.userID
    }

    url = this.state.scripturl;
    options = {};
    options.method = 'post';
    options.payload = JSON.stringify(payload);

    fetch(url, {
      method: 'post',
      body: JSON.stringify(payload),
    })
    .then(response => {
      
    })

  }

  handleFocus = (e) => {
    e.target.value=0;
    e.target.select();
  }

  validateUserPass = (userPass) => {
    if(this.state.userDetailData.length === 0){
      return false;
    }else if(userPass === this.state.userDetailData[0].leadPassword){
      return true;
    }else{
      return false;
    }
  }

  checkUserPass = (e) => {
        e.preventDefault();
        this.fetchUserDetailsByPass();
        var resultCheck = false;
        swal({
            title: 'Logowanie...',
            text: '',
            icon: 'info',
            timer: 3000,
            buttons: false,
        }).then(() => {
            resultCheck = this.validateUserPass(this.state.userPass);
            if(resultCheck){
              this.fetchUserBasicById(this.state.userDetailData[0].leadId);
              this.setState (prevState => ({
                step: 4,
                page: 4,
                analysis: 1, 
                stepsDisplay: true,   
              }))
            }
            if(!resultCheck){
                MySwal.fire({
                  title: 'NIEPRAWIDŁOWE HASŁO',
                  icon: 'error'
                }).then(results => {
                  
                })
            }
      }) 
  } 

  changeValidate = (value) => {
    if(value === 1){
      this.setState (prevState => ({
        validate1: 1,
      }))
    }else if(value === 2){
      this.setState (prevState => ({
        validate2: 1,
      }))
    }else if(value === 3){
      this.setState (prevState => ({
        validate3: 1,
      }))
    }
  }

  arrayRemove = (arr, value) => { 
    return arr.filter(function(ele){ 
        return ele !== value; 
    });
  }

  render(){
  return (
    <>
        <Header />  
        {this.state.stepsDisplay
        ?
        <Steps 
          stepNumber = {this.state.step}
          changeStep = {this.changePageBySteps}
        />
        :
        ''
        } 
        {this.state.page === 1 ?
        <FirstPage 
          onChangeCreditCurrencyToggle = {this.onChangeCreditCurrency}
          currencyToggleChecked = {this.state.currencyToggleChecked}
          onSubmitFirstForm = {this.onSubmitFirstForm}
          onChangeCreditBank = {this.onChangeCreditBank}
          creditBank = {this.state.creditBank}
          onChangeCreditDate = {this.onChangeCreditDate}
          creditDate = {this.state.creditDate}
          onChangeCreditAmmount = {this.onChangeCreditAmmount}
          creditAmmount = {this.state.creditAmmount}
          agreements = {this.state.agreements}
          creditCurrency = {this.state.creditCurrency}
          changeValidate = {this.changeValidate}
        />
        :
        this.state.page === 2 ?
        <SecondPage 
          creditBank = {this.state.creditBank}
          creditCurrency = {this.state.creditCurrency}
          creditAmmount = {this.state.creditAmmount}
          creditDate = {this.state.creditDate}
          onChangeUserLocalization = {this.onChangeUserLocalization}
          userLocalization = {this.state.userLocalization}
          onChangeUserExpectations = {this.onChangeUserExpectations}
          userExpectations = {this.state.userExpectations}
          onChangeUserName = {this.onChangeUserName}
          userName = {this.state.userName}
          onChangeUserMail = {this.onChangeUserMail}
          userMail = {this.state.userMail}
          onChangeUserPhone = {this.onChangeUserPhone}
          userPhone = {this.state.userPhone}
          onClickPrevoiusPage = {this.onClickPrevoiusPage}
          onSubmitSecondForm = {this.onSubmitSecondForm}
          onClickDetailsAnalysis = {this.onClickDetailsAnalysis}
          handleFocus = {this.handleFocus}
          changeValidate = {this.changeValidate}
        />
        :
        this.state.page === 4 ?
        <FourthPage 
          creditBank = {this.state.creditBank}
          creditCurrency = {this.state.creditCurrency}
          creditAmmount = {this.state.creditAmmount}
          creditDate = {this.state.creditDate}
          userLocalization = {this.state.userLocalization}
          userExpectations = {this.state.userExpectations}
          userName = {this.state.userName}
          userMail = {this.state.userMail}
          userPhone = {this.state.userPhone}
          onClickPrevoiusPage = {this.onClickPrevoiusPage}
          onSubmitSecondForm = {this.onSubmitSecondForm}
          handleFocus = {this.handleFocus}
          analysis = {this.state.analysis}
          onClickDetailsAnalysis = {this.onClickDetailsAnalysis}
          companies = {this.state.companies}
          companiesData = {this.state.companiesData}
          companiesDescription = {this.state.companiesDescription}
          companiesFiles = {this.state.companiesFiles}
          companiesOffer = {this.state.companiesOffer}
          onChooseCompanies = {this.onChooseCompanies}
          userDetailData = {this.state.userDetailData}
          fetchLastAssingmentDate = {this.fetchLastAssingmentDate}
          newAssingmentDate = {this.state.newAssingmentDate}
          companyJudgments = {this.state.companyJudgments}
        />
        :
        this.state.page === 3 ?
        <ThirdPage 
          creditBank = {this.state.creditBank}
          creditCurrency = {this.state.creditCurrency}
          creditAmmount = {this.state.creditAmmount}
          creditDate = {this.state.creditDate}
          userLocalization = {this.state.userLocalization}
          userExpectations = {this.state.userExpectations}
          userName = {this.state.userName}
          userMail = {this.state.userMail}
          userPhone = {this.state.userPhone}
          onClickPrevoiusPage = {this.onClickPrevoiusPage}
          onSubmitSecondForm = {this.onSubmitSecondForm}
          onChangeThirdPageToggle = {this.onChangeThirdPageToggle}
          creditFinalizedToggleChecked = {this.state.creditFinalizedToggleChecked}
          creditFinalized = {this.state.creditFinalized}
          onChangeCreditMargin = {this.onChangeCreditMargin}
          creditMargin = {this.state.creditMargin}
          onChangeCreditLength = {this.onChangeCreditLength}
          creditLength = {this.state.creditLength}
          onChangeCreditType = {this.onChangeCreditType}
          creditType = {this.state.creditType}
          onChangeCreditGracePeriod = {this.onChangeCreditGracePeriod}
          creditGracePeriod = {this.state.creditGracePeriod}
          onChangeCreditFinalDate = {this.onChangeCreditFinalDate}
          creditFinalDate = {this.state.creditFinalDate}

          creditLengthChangeToggleChecked = {this.state.creditLengthChangeToggleChecked}
          creditLengthChange = {this.state.creditLengthChange}
          creditLengthChanges = {this.state.creditLengthChanges}
          handleActualCreditLengthChangeRowDateChange = {this.handleActualCreditLengthChangeRowDateChange}
          handleActualCreditLengthChangeRowValueChange = {this.handleActualCreditLengthChangeRowValueChange}
          actualCreditLengthChangeRowDate = {this.state.actualCreditLengthChangeRowDate}
          actualCreditLengthChangeRowValue = {this.state.actualCreditLengthChangeRowValue}
          handleAddCreditLengthChange = {this.handleAddCreditLengthChange}
          handleDeleteCreditLengthChange = {this.handleDeleteCreditLengthChange}

          creditMarginChangeToggleChecked = {this.state.creditMarginChangeToggleChecked}
          creditMarginChange = {this.state.creditMarginChange}
          creditMarginChanges = {this.state.creditMarginChanges}
          handleActualCreditMarginChangeRowDateChange = {this.handleActualCreditMarginChangeRowDateChange}
          handleActualCreditMarginChangeRowValueChange = {this.handleActualCreditMarginChangeRowValueChange}
          actualCreditMarginChangeRowDate = {this.state.actualCreditMarginChangeRowDate}
          actualCreditMarginChangeRowValue = {this.state.actualCreditMarginChangeRowValue}
          handleAddCreditMarginChange = {this.handleAddCreditMarginChange}
          handleDeleteCreditMarginChange = {this.handleDeleteCreditMarginChange}

          creditSubsequentsTranchesChangeToggleChecked = {this.state.creditSubsequentsTranchesChangeToggleChecked}
          creditSubsequentsTranchesChange = {this.state.creditSubsequentsTranchesChange}
          creditSubsequentsTranchesChanges = {this.state.creditSubsequentsTranchesChanges}
          handleActualCreditSubsequentsTranchesChangeRowDateChange = {this.handleActualCreditSubsequentsTranchesChangeRowDateChange}
          handleActualCreditSubsequentsTranchesChangeRowValueChange = {this.handleActualCreditSubsequentsTranchesChangeRowValueChange}
          actualCreditSubsequentsTranchesChangeRowDate = {this.state.actualCreditSubsequentsTranchesChangeRowDate}
          actualCreditSubsequentsTranchesChangeRowValue = {this.state.actualCreditSubsequentsTranchesChangeRowValue}
          handleAddCreditSubsequentsTranchesChange = {this.handleAddCreditSubsequentsTranchesChange}
          handleDeleteCreditSubsequentsTranchesChange = {this.handleDeleteCreditSubsequentsTranchesChange}
          handleActualCreditSubsequentsTranchesChangeRowCurrencyChange = {this.handleActualCreditSubsequentsTranchesChangeRowCurrencyChange}
          actualCreditSubsequentsTranchesChangeRowCurrency = {this.state.actualCreditSubsequentsTranchesChangeRowCurrency}

          creditEarlyRepaymentChangeToggleChecked = {this.state.creditEarlyRepaymentChangeToggleChecked}
          creditEarlyRepaymentChange = {this.state.creditEarlyRepaymentChange}
          creditEarlyRepaymentChanges = {this.state.creditEarlyRepaymentChanges}
          handleActualCreditEarlyRepaymentChangeRowDateChange = {this.handleActualCreditEarlyRepaymentChangeRowDateChange}
          handleActualCreditEarlyRepaymentChangeRowValueChange = {this.handleActualCreditEarlyRepaymentChangeRowValueChange}
          actualCreditEarlyRepaymentChangeRowDate = {this.state.actualCreditEarlyRepaymentChangeRowDate}
          actualCreditEarlyRepaymentChangeRowValue = {this.state.actualCreditEarlyRepaymentChangeRowValue}
          handleAddCreditEarlyRepaymentChange = {this.handleAddCreditEarlyRepaymentChange}
          handleDeleteCreditEarlyRepaymentChange = {this.handleDeleteCreditEarlyRepaymentChange}
          handleActualCreditEarlyRepaymentChangeRowCurrencyChange = {this.handleActualCreditEarlyRepaymentChangeRowCurrencyChange}
          actualCreditEarlyRepaymentChangeRowCurrency = {this.state.actualCreditEarlyRepaymentChangeRowCurrency}

          setNewCalculate = {this.doAnalysis}
          handleFocus = {this.handleFocus}
          changeValidate = {this.changeValidate}
        />
        :
        this.state.page === 5 ?
        <LoginPage 
          changeUserPass = {this.changeUserPass}
          checkUserPass = {this.checkUserPass}
        />
        :
        this.state.page === 6 ?
        <FifthPage 
          chooseCompanies = {this.state.chooseCompanyArray}
          changeStep = {this.changePageBySteps}
          companies = {this.state.companies}
          companiesData = {this.state.companiesData}
          companiesDescription = {this.state.companiesDescription}
          companiesFiles = {this.state.companiesFiles}
          companiesOffer = {this.state.companiesOffer}
          addAssingmentsToDatabase = {this.fetchNewLeadAssingments}
          leadId = {this.state.userID}
          userName = {this.state.userName}
          userMail = {this.state.userMail}
          userPhone = {this.state.userPhone}
        />
        :
        ''
        }
    </>
  );
}
}

export default Comparer;
