import React from "react";
import "../../../components/Styles/ConsultantPendingLeads.css";
import ConsultantAssingmentsLeadsData from "./ConsultantAssingmentsLeadsData";
import { useState } from "react";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

const ConsultantAssingmentsLeads = (props) => {
  const [leadsArray, setLeadsArray] = useState([]);
  const [leadsAssingmentsData, setLeadsAssingmentsData] = useState([]);
  const [leadsBasicData, setLeadsBasicData] = useState([]);
  const [leadsDetailData, setLeadsDetailData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadsDeleteData, setLeadsDeleteData] = useState([]);
  const [leadsNote, setLeadsNote] = useState([]);
  const [leadsMark, setLeadsMark] = useState([]);

  const fetchAssingmentsLeads = () => {
    fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_assingments")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          leadsAssingmentsData: data,
        });
      })
      .catch((error) => {});
  };

  const fetchAllLeads = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/get_all_leads")
      .then((response) => response.json())
      .then(async (data) => {
        setLeadsBasicData(data);
        await fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_details")
          .then((response2) => response2.json())
          .then(async (data2) => {
            setLeadsDetailData(data2);
            await fetch(
              "https://porownywarkachfapi.ftpeople.pl/get_leads_delete"
            )
              .then((response4) => response4.json())
              .then(async (data4) => {
                setLeadsDeleteData(data4);
                await fetch(
                  "https://porownywarkachfapi.ftpeople.pl/get_leads_note"
                )
                  .then((response5) => response5.json())
                  .then(async (data5) => {
                    setLeadsNote(data5);
                    await fetch(
                      "https://porownywarkachfapi.ftpeople.pl/get_leads_mark"
                    )
                      .then((response6) => response6.json())
                      .then(async (data6) => {
                        setLeadsMark(data6);
                        await fetch(
                          "https://porownywarkachfapi.ftpeople.pl/get_leads_assingments"
                        )
                          .then((response3) => response3.json())
                          .then((data3) => {
                            setLeadsAssingmentsData(data3);
                            var arr = data;
                            var arrAss = arr.filter(
                              (el) =>
                                checkIfLeadAssingment(el.id, data3) &&
                                el.arch !== "1"
                            );
                            setLeadsArray(sortArrayByDate(arrAss));
                            setLoading(false);
                          })
                          .catch((error) => {});
                      })
                      .catch((error) => {});
                  })
                  .catch((error) => {});
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const checkIfLeadAssingment = (id, arr) => {
    var arrTest = arr.filter((el) => el.userId === id);

    if (arrTest.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const sortArrayByDate = (arr) => {
    var arrN = arr.sort(function (a, b) {
      return new Date(b.addDate) - new Date(a.addDate);
    });
    return arrN;
  };

  const checkInAssingmentByLeadId = (id) => {
    var arr = props.leadsAssingmentsData.filter((lead) => id === lead.userId);
    return arr.length > 0 ? true : false;
  };

  const checkIfAssingment = (array) => {
    var arrFiltered = [];
    array.map((lead) => {
      if (checkInAssingmentByLeadId(lead.id)) {
        arrFiltered.push(lead);
      }
    });

    return arrFiltered;
  };

  const prepareLeadsArray = () => {
    var arrT = sortArrayByDate(props.leadsBasicData);
    var arrFinal = checkIfAssingment(arrT);
    setLeadsArray(arrFinal);
  };

  const getSubArray = (idx, _length, _array) => {
    return _array.slice(idx, idx + _length);
  };

  useEffect(() => {
    setLoading(true);
    fetchAllLeads();
  }, []);

  const changePage = (e) => {
    prepareLeadsArray(e);
  };

  const refreshPage = (e) => {
    setLoading(true);
    fetchAllLeads();
  };

  const getLeadDetailData = (id) => {
    return props.leadsDetailData.filter((lead) => lead.leadId === id)[0];
  };

  return (
    <>
      <div className="mainContainerConsultantPendingLeads">
        {loading ? (
          <ThreeDots color="black" height={50} width={50} />
        ) : (
          <>
            <button
              style={{ width: "30%" }}
              className="pendingLeadsButton"
              value={8}
              onClick={refreshPage}
            >
              ODŚWIEŻ
            </button>
            <h3>Ilość leadów: {leadsArray.length}</h3>
            <ConsultantAssingmentsLeadsData
              leadsBasicData={leadsArray}
              getLeadDetailData={getLeadDetailData}
              companiesList={props.companiesList}
              addAssingmentsToDatabase={props.addAssingmentsToDatabase}
              leadsNote={leadsNote}
              refreshPage={refreshPage}
              user={props.user}
              leadsMark={leadsMark}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ConsultantAssingmentsLeads;
