import React from 'react';
import '../../../components/Styles/CreditMarginChange.css';
import '../../../components/Styles/FormStyle.css';

const CreditMarginChange = (props) => {

    const MarginChangeData = props.creditMarginChanges.map(creditMarginChange => (
        <>
            <tr key={creditMarginChange.key}>
                    <td>
                        {creditMarginChange.date}
                    </td>
                    <td>
                        {creditMarginChange.value}
                    </td>
                    <td>
                        <button id="subrow_button_table" className="submitButton" value={creditMarginChange.key} onClick={props.handleDeleteCreditMarginChange}>Usuń</button>
                    </td>
            </tr>
        </>
    ))

    const lenghtChangeDataStructure = (
        <table className="marginChangeTable">
                <tbody>
                    <tr>
                        <th>Data</th>
                        <th>Wartość</th>
                        <th>Akcja</th>
                    </tr>
                    {MarginChangeData}
                </tbody>
        </table> 
    )

    return(
        <>
            <div className="additionalInfoRowCreditMargin">
                <div className="additionalInfoRowCreditMarginControls">
                    <div className="additionalInfoRowCreditMargin_col1">
                        <input type="number" id="subrow_input" name="actualCreditMarginChangeValue" onFocus={props.handleFocus} value={props.actualCreditMarginChangeRowValue} onChange={props.handleActualCreditMarginChangeRowValueChange}/>
                    </div>
                    <div className="additionalInfoRowCreditMargin_col2">
                        <input type="date" id="subrow_input" name="actualCreditMarginChangeDate" value={props.actualCreditMarginChangeRowDate} onChange={props.handleActualCreditMarginChangeRowDateChange}/>
                    </div>
                    <div className="additionalInfoRowCreditMargin_col3">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditMarginChange}>DODAJ</button>
                    </div>
                </div>
                <div className="additionalInfoRowCreditMargin_col3_mobile">
                        <button id="subrow_button" className="submitButton" onClick={props.handleAddCreditMarginChange}>DODAJ</button>
                    </div>
                <div className="tableRow">
                    {props.creditMarginChanges.length === 0 ? '' : lenghtChangeDataStructure}
                </div>
            </div>  
        </>
    )
}

export default CreditMarginChange;