import { faFacebookF, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../Styles/HeaderAdmin.css';

const Header = (props) => {
    return ( 
        <>
            <div className="twoColContainerH">
                <div className="firstColHeader">
                    <img alt="logo" className="logoImageHeaderAdmin" src="https://porownywarkachfapi.ftpeople.pl/logo_main.png"/>  
                </div>
                <div className="secondColHeaderAdmin">
                    <span>STREFA PARTNERA</span>
                </div>
                <div className="thirdColHeader">
                    <div className="socialIconHeader"><a className="socialLink" href="https://www.facebook.com/porownywarkaCHF" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></div>
                </div>
            </div>
        </>
     );
}
 
export default Header;