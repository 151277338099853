import React from 'react';
import '../Styles/Footer.css';

const Footer = (props) => {
    return ( 
        <>
            <div className="twoColContainerF">
                <div className="firstColHeaderF">
                <a href="https://porownywarkachf.pl"><img alt="logo" className="logoImageFooter" src="https://porownywarkachfapi.ftpeople.pl/logo_main.png"/> </a>   
                </div>
                <div className="secondColHeaderF">
                <a className="contactHref" href="https://porownywarkachf.pl/polityka-prywatnosci/">Polityka prywatności</a>
                <a className="contactHref" href="https://porownywarkachf.pl/regulamin/">Regulamin</a>
                <a className="contactHref" href="mailto: biuro@porownywarkachf.pl">biuro@porownywarkachf.pl</a>
                <a className="contactHref" href="https://porownywarkachf.pl/">© Porównywarka CHF 2022</a>
                </div>
            </div>
        </>
     );
}
 
export default Footer;