import React, { Component } from "react";
import "../../../components/Styles/AdminPanel.css";
import AdminMenu from "../Menu/AdminMenu";
import AdminCompanyData from "../CompanyData/AdminCompanyData";
import AdminCompanyOffer from "../CompanyOffer/AdminCompanyOffer";
import AdminCompanyDetails from "../CompanyDetails/AdminCompanyDetails";
import AdminCompanyLeads from "../CompanyLeads/AdminCompanyLeads";
import ConsultantPendingLeads from "../ConsultantPanel/ConsultantPendingLeads";
import ConsultantArchiveLeads from "../ConsultantPanel/ConsultantArchiveLeads";
import ConsultantAssingmentsLeads from "../ConsultantPanel/ConsultantAssingmentsLeads";
import AdminCompanyUsers from "../CompanyUsers/AdminCompanyUsers";
import Swal2 from "sweetalert2";
import swal from "sweetalert";
import withReactContent from "sweetalert2-react-content";
import imageCompression from "browser-image-compression";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";
import { isMobile } from "react-device-detect";
import ConsultantIndividualCampaign from "../ConsultantPanel/ConsultantIndividualCampaign";
import Dashboard from "../ConsultantPanel/Dashboard";
import AdminCompanyJudgments from "../CompanyJudgments/AdminCompanyJudgments";

const MySwal = withReactContent(Swal2);
const imageOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

class AdminPanel extends Component {
  state = {
    page: 1,
    user: [],
    company: 0,
    actualPage: "",
    logoFile: "",
    logoUrl: "",
    companyTitle: "",
    companyTitleInput: "",
    companyDescription: "",
    companyDescriptionInput: "",
    imageFile: "",
    imageUrl: "",
    caseCount: "",
    caseCountInput: 0,
    marketYear: "",
    marketYearInput: 0,
    winCases: "",
    winCasesInput: 0,
    winRealCases: "",
    winRealCasesInput: 0,
    imageImageRefreshFlag: 1,
    logoImageRefreshFlag: 1,
    titleRefreshFlag: 1,
    descriptionRefreshFlag: 1,
    caseCountRefreshFlag: 1,
    marketYearRefreshFlag: 1,
    winCasesRefreshFlag: 1,
    winRealCasesRefreshFlag: 1,
    addOfferEntranceeCostInput: 0,
    addOfferSuccessFeeInput: 0,
    addOfferSuccessFeeTypeInput: "%",
    addOfferInstallmentInput: 0,
    addOfferInstallmentSwitchInput: 0,
    addOfferOptionsSwitchInput: 0,
    addOfferOptionsInput: "",
    addOfferNameInput: "",
    addOfferTypeInput: "",
    companyOffers: [],
    companyAddressInput: "",
    companyRegionInput: "",
    companyPersonInput: "",
    agreements: [],
    companyAllowedBanksInput: [],
    companyAllowedRegionsInput: [],
    leadsBasicData: [],
    leadsAssingmentsData: [],
    leadsDetailData: [],
    companies: [],
    assingmentLeads: [],
    companyMailInput: "",
    companyPhoneInput: "",
    width: 0,
    companiesData: [],
    addOfferType: "benefitValue",
  };

  fetchUser = (login) => {
    fetch(
      'https://porownywarkachfapi.ftpeople.pl/userlogin?login="' +
        encodeURI(login) +
        '"'
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          user: data[0],
          company: data[0].company_id,
        });
        if (data[0].role === 1 || data[0].role === 3) {
          this.fetchAllLeads();
          this.fetchDetailLeads();
          this.fetchAssingmentsLeads();
          this.fetchCompanies();
          this.fetchCompaniesData();
        }
      })
      .catch((error) => {});
  };

  fetchAllLeads = () => {
    fetch("https://porownywarkachfapi.ftpeople.pl/get_all_leads")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          leadsBasicData: data,
        });
      })
      .catch((error) => {});
  };

  fetchCompaniesData = () => {
    fetch("https://porownywarkachfapi.ftpeople.pl/company_data_all")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          companiesData: data,
        });
      });
  };

  fetchDetailLeads = () => {
    fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_details")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          leadsDetailData: data,
        });
      })
      .catch((error) => {});
  };

  fetchAssingmentsLeads = () => {
    fetch("https://porownywarkachfapi.ftpeople.pl/get_leads_assingments")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          leadsAssingmentsData: data,
        });
      })
      .catch((error) => {});
  };

  fetchLogoUrl = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/logo_url?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          logoUrl: data[0].fileUrl,
        });
      })
      .catch((error) => {});
  };

  fetchFileLogoUpload = () => {
    let formData = new FormData();
    formData.append("logo", this.state.logoFile);

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/uploadlogo?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      logoImageRefreshFlag: 1,
    });
  };

  fetchImageUrl = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/image_url?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          imageUrl: data[0].fileUrl,
        });
      })
      .catch((error) => {});
  };

  async compressImageFile(file) {
    const compressedFile = await imageCompression(
      this.state.imageFile,
      imageOptions
    );

    return compressedFile;
  }

  fetchFileImageUpload = () => {
    let formData = new FormData();
    formData.append("image", this.state.imageFile);

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/uploadimage?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      imageImageRefreshFlag: 1,
    });
  };

  fetchCompanyTitleFirst = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_title?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyTitleInput: data[0].descText,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyCaseCountFirst = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_casecount?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            caseCountInput: data[0].descText,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyOffer = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_offer?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyOffers: data,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyMarketYearFirst = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_marketyear?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            marketYearInput: data[0].descText,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyWinCasesFirst = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_wincases?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            winCasesInput: data[0].descText,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyWinRealCasesFirst = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_winrealcases?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            winRealCasesInput: data[0].descText,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyTitle = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_title?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyTitle: data[0].descText,
            ompanyTitleInput: data[0].descText,
          });
        }
      })
      .catch((error) => {});
    this.fetchCompanyTitleFirst();
  };

  fetchTitleUpdate = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/update_title?company=" +
        encodeURI(this.state.company) +
        "&title=" +
        encodeURI(this.state.companyTitleInput),
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      titleRefreshFlag: 1,
    });
  };

  fetchCompanyDescriptionFirst = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_description?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyDescriptionInput: data[0].descText,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyDescription = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_description?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyDescription: data[0].descText,
          });
        }
      })
      .catch((error) => {});
    this.fetchCompanyDescriptionFirst();
  };

  fetchCompanies = async () => {
    await fetch("https://porownywarkachfapi.ftpeople.pl/company_all")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          companies: data,
        });
      })
      .catch((error) => {});
  };

  fetchCompanyCaseCount = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_casecount?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            caseCount: data[0].descText,
          });
        }
      })
      .catch((error) => {});
    this.fetchCompanyCaseCountFirst();
  };

  fetchCompanyMarketYear = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_marketyear?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            marketYear: data[0].descText,
          });
        }
      })
      .catch((error) => {});
    this.fetchCompanyMarketYearFirst();
  };

  fetchCompanyWinCases = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_wincases?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            winCases: data[0].descText,
          });
        }
      })
      .catch((error) => {});
    this.fetchCompanyWinCasesFirst();
  };

  fetchCompanyWinRealCases = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_winrealcases?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            winRealCases: data[0].descText,
          });
        }
      })
      .catch((error) => {});
    this.fetchCompanyWinRealCasesFirst();
  };

  fetchDescriptionUpdate = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/update_description?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ desc: this.state.companyDescriptionInput }),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      descriptionRefreshFlag: 1,
    });
  };

  fetchCaseCountUpdate = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/update_casecount?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ case: this.state.caseCountInput }),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      caseCountRefreshFlag: 1,
    });
  };

  fetchMarketYearUpdate = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/update_marketyear?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ market: this.state.marketYearInput }),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      marketYearRefreshFlag: 1,
    });
  };

  fetchWinCasesUpdate = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/update_wincases?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ wincases: this.state.winCasesInput }),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      winCasesRefreshFlag: 1,
    });
  };

  fetchWinRealCasesUpdate = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/update_winrealcases?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ winrealcases: this.state.winRealCasesInput }),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
    this.setState({
      winRealCasesRefreshFlag: 1,
    });
  };

  fetchOfferInsert = () => {
    var body = {
      entranceeCost: this.state.addOfferEntranceeCostInput,
      successFeeType: this.state.addOfferSuccessFeeTypeInput,
      successFee: this.state.addOfferSuccessFeeInput,
      installmentOn: this.state.addOfferInstallmentSwitchInput,
      installment: this.state.addOfferInstallmentInput,
      optionsOn: this.state.addOfferOptionsSwitchInput,
      options: this.state.addOfferOptionsInput,
      name: this.state.addOfferNameInput,
      type: this.state.addOfferType,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_offer?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchOfferDelete = (id) => {
    var body = {
      offerId: id,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/delete_offer?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.text())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchAddressInputInsert = (address) => {
    var body = {
      address: address,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_address?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchRegionInputInsert = (region) => {
    var body = {
      region: region,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_region?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchMailInputInsert = (mail) => {
    var body = {
      mail: mail,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_mail?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchPhoneInputInsert = (phone) => {
    var body = {
      phone: phone,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_phone?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchPersonInputInsert = (person) => {
    var body = {
      person: person,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_person?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchAllowedBanksInputInsert = (banks) => {
    var banksT = "";
    banks.map((bank) => (banksT = bank + "/" + banksT));

    var body = {
      banks: banksT,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_banks?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchAllowedRegionsInputInsert = (regions) => {
    var regionsT = "";
    regions.map((region) => (regionsT = region + "/" + regionsT));

    var body = {
      regions: regionsT,
    };

    fetch(
      "https://porownywarkachfapi.ftpeople.pl/insert_regions?company=" +
        encodeURI(this.state.company),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchCompanyAddressInput = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_address?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyAddressInput: data[0].value,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyRegionInput = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_region?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyRegionInput: data[0].value,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyMailInput = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_mail?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyMailInput: data[0].value,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyPhoneInput = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_phone?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyPhoneInput: data[0].value,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyPersonInput = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_person?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyPersonInput: data[0].value,
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyAllowedBanksInput = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_banks?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyAllowedBanksInput: data[0].value.split("/"),
          });
        }
      })
      .catch((error) => {});
  };

  fetchCompanyAllowedRegionsInput = () => {
    fetch(
      "https://porownywarkachfapi.ftpeople.pl/company_regions?company=" +
        encodeURI(this.state.company)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            companyAllowedRegionsInput: data[0].value.split("/"),
          });
        }
      })
      .catch((error) => {});
  };

  fetchAgreements = () => {
    fetch("https://porownywarkachfapi.ftpeople.pl/agreements")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          agreements: data,
        });
      });
  };

  fetchNewLeadAssingments = (leadAssingmentsData, leadId) => {
    var body = {
      assingments: leadAssingmentsData,
      leadId: leadId,
    };

    fetch("https://porownywarkachfapi.ftpeople.pl/insert_lead_assingments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  fetchAssingmentsForCompany = () => {
    fetch(
      `https://porownywarkachfapi.ftpeople.pl/get_leads_assingments_id_company?companyId=${encodeURI(
        this.state.company
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          assingmentLeads: data,
        });
      });
  };

  handleLogoFileInputChange = (e) => {
    this.setState((prevState) => ({
      logoFile: e.target.files[0],
    }));
  };

  handleImageFileInputChange = (e) => {
    this.setState((prevState) => ({
      imageFile: e.target.files[0],
    }));
  };

  handleCompanyTitleInputChange = (e) => {
    this.setState((prevState) => ({
      companyTitleInput: e.target.value,
    }));
  };

  handleCompanyDescriptionInputChange = (e) => {
    this.setState((prevState) => ({
      companyDescriptionInput: e.target.value,
    }));
  };

  handleCompanyCaseCountInputChange = (e) => {
    this.setState((prevState) => ({
      caseCountInput: e.target.value,
    }));
  };

  handleCompanyMarketYearInputChange = (e) => {
    this.setState((prevState) => ({
      marketYearInput: e.target.value,
    }));
  };

  handleCompanyAddressInputChange = (e) => {
    this.setState((prevState) => ({
      companyAddressInput: e.target.value,
    }));
  };

  handleCompanyRegionInputChange = (e) => {
    this.setState((prevState) => ({
      companyRegionInput: e.target.value,
    }));
  };

  handleCompanyMailInputChange = (e) => {
    this.setState((prevState) => ({
      companyMailInput: e.target.value,
    }));
  };

  handleCompanyPhoneInputChange = (e) => {
    this.setState((prevState) => ({
      companyPhoneInput: e.target.value,
    }));
  };

  handleCompanyPersonInputChange = (e) => {
    this.setState((prevState) => ({
      companyPersonInput: e.target.value,
    }));
  };

  handleCompanyAllowedBanksInputChange = (e) => {
    var options = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    this.setState((prevState) => ({
      companyAllowedBanksInput: options,
    }));
  };

  handleCompanyAllowedRegionsInputChange = (e) => {
    var options = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    this.setState((prevState) => ({
      companyAllowedRegionsInput: options,
    }));
  };

  handleCompanyWinCasesInputChange = (e) => {
    this.setState((prevState) => ({
      winCasesInput: e.target.value,
    }));
  };

  handleCompanyWinRealCasesInputChange = (e) => {
    this.setState((prevState) => ({
      winRealCasesInput: e.target.value,
    }));
  };

  handleEntranceeCostInputChange = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      addOfferEntranceeCostInput: +e.target.value,
    }));
  };

  handleSuccessFeeInputChange = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      addOfferSuccessFeeInput: +e.target.value,
    }));
  };

  handleSuccessFeeTypeInputChange = (value) => {
    this.setState((prevState) => ({
      addOfferSuccessFeeTypeInput: value,
    }));
  };

  handleInstallmentInputChange = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      addOfferInstallmentInput: +e.target.value,
    }));
  };

  handleInstallmentSwitchInputChange = (value) => {
    this.setState((prevState) => ({
      addOfferInstallmentSwitchInput: value,
    }));
  };

  handleOptionsSwitchInputChange = (value) => {
    this.setState((prevState) => ({
      addOfferOptionsSwitchInput: value,
    }));
  };

  handleOptionsInputChange = (e) => {
    e.preventDefault();
    var value = e.target.value.toString().replace("\n", "");
    this.setState((prevState) => ({
      addOfferOptionsInput: value,
    }));
  };

  handleNameInputChange = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      addOfferNameInput: e.target.value,
    }));
  };

  handleTypeInputChange = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      addOfferType: e.target.value,
    }));
  };

  handleLogoFileInputSend = (e) => {
    e.preventDefault();
    this.fetchFileLogoUpload();
  };

  handleImageFileInputSend = (e) => {
    e.preventDefault();
    this.fetchFileImageUpload();
  };

  handleCompanyTitleInputSend = (e) => {
    e.preventDefault();
    this.fetchTitleUpdate();
    this.setState({
      companyTitleInput: "",
    });
  };

  handleCompanyDescriptionInputSend = (e) => {
    e.preventDefault();
    this.fetchDescriptionUpdate();
    this.setState({
      companyDescriptionInput: "",
    });
  };

  handleCompanyCaseCountInputSend = (e) => {
    e.preventDefault();
    this.fetchCaseCountUpdate();
    this.setState({
      caseCountInput: "",
    });
  };

  handleCompanyMarketYearInputSend = (e) => {
    e.preventDefault();
    this.fetchMarketYearUpdate();
    this.setState({
      marketYearInput: "",
    });
  };

  handleCompanyWinCasesInputSend = (e) => {
    e.preventDefault();
    this.fetchWinCasesUpdate();
    this.setState({
      winCasesInput: "",
    });
  };

  handleCompanyWinRealCasesInputSend = (e) => {
    e.preventDefault();
    this.fetchWinRealCasesUpdate();
    this.setState({
      winRealCasesInput: "",
    });
  };

  handleCompanyOfferInsertSend = (e) => {
    e.preventDefault();
    this.fetchOfferInsert();
    this.setState({
      addOfferEntranceCostInput: 0,
      addOfferSuccessFeeTypeInput: "%",
      addOfferSuccessFeeInput: 0,
      addOfferInstallmentSwitchInput: 0,
      addOfferInstallmentInput: 0,
      addOfferOptionsSwitchInput: 0,
      addOfferOptionsInput: "",
      addOfferNameInput: "",
      addOfferType: "benefitValue",
    });
  };

  handleCompanyOfferDeleteSend = (e) => {
    e.preventDefault();
    this.fetchOfferDelete(e.target.value);
  };

  handleCompanyAddressInputSend = (e) => {
    e.preventDefault();
    this.fetchAddressInputInsert(this.state.companyAddressInput);
  };

  handleCompanyRegionInputSend = (e) => {
    e.preventDefault();
    this.fetchRegionInputInsert(this.state.companyRegionInput);
  };

  handleCompanyMailInputSend = (e) => {
    e.preventDefault();
    this.fetchMailInputInsert(this.state.companyMailInput);
  };

  handleCompanyPhoneInputSend = (e) => {
    e.preventDefault();
    this.fetchPhoneInputInsert(this.state.companyPhoneInput);
  };

  handleCompanyPersonInputSend = (e) => {
    e.preventDefault();
    this.fetchPersonInputInsert(this.state.companyPersonInput);
  };

  handleCompanyAllowedBanksInputSend = (e) => {
    e.preventDefault();
    this.fetchAllowedBanksInputInsert(this.state.companyAllowedBanksInput);
  };

  handleCompanyAllowedRegionsInputSend = (e) => {
    e.preventDefault();
    this.fetchAllowedRegionsInputInsert(this.state.companyAllowedRegionsInput);
  };

  checkPassword(loginToCheck, passwordToCheck) {
    if (this.state.user === undefined) {
      return false;
    }
    if (
      this.state.user.login === loginToCheck &&
      this.state.user.password === passwordToCheck
    ) {
      return true;
    } else {
      return false;
    }
  }

  refreshFlag = (flag) => {
    if (flag === "logo") {
      this.setState({
        logoImageRefreshFlag: 0,
      });
    } else if (flag === "image") {
      this.setState({
        imageImageRefreshFlag: 0,
      });
    } else if (flag === "title") {
      this.setState({
        titleRefreshFlag: 0,
      });
    } else if (flag === "description") {
      this.setState({
        descriptionRefreshFlag: 0,
      });
    } else if (flag === "caseCount") {
      this.setState({
        caseCountRefreshFlag: 0,
      });
    } else if (flag === "marketYear") {
      this.setState({
        marketYearRefreshFlag: 0,
      });
    } else if (flag === "winCases") {
      this.setState({
        winCasesRefreshFlag: 0,
      });
    } else if (flag === "winRealCases") {
      this.setState({
        winRealCasesRefreshFlag: 0,
      });
    }
  };

  showLoginInput() {
    isMobile
      ? MySwal.fire({
          title: "Brak obsługi wersji mobile",
          icon: "error",
        }).then((results) => {
          setTimeout(window.location.reload(false), 0);
        })
      : MySwal.fire({
          title: "Zaloguj się",
          html: `
            <center>
            <span class="labelSwal">Podaj login:</span><input class="loginInput" type="text" name="login" id="login" class="swal2-input" placeholder="login">
            <span class="labelSwal">Podaj hasło:</span><input class="loginInput" type="password" name="password" id="password" class="swal2-input" placeholder="hasło">
            </center>
            `,
          confirmButtonText: "Zaloguj się",
          allowOutsideClick: false,
          allowEscapeKey: false,
          focusConfirm: false,
          preConfirm: () => {
            const password = Swal2.getPopup().querySelector("#password").value;
            const login = Swal2.getPopup().querySelector("#login").value;
            if (!password || !login) {
              Swal2.showValidationMessage(`Proszę wypełnić dane`);
            }
            return { password: password, login: login };
          },
        }).then((result) => {
          const passwordToCheck = result.value.password;
          const loginToCheck = result.value.login;
          this.fetchUser(loginToCheck);
          var resultCheck = false;
          swal({
            title: "Logowanie...",
            text: "",
            icon: "info",
            timer: 3000,
            buttons: false,
          }).then(() => {
            resultCheck = this.checkPassword(loginToCheck, passwordToCheck);
            if (resultCheck) {
            }
            if (!resultCheck) {
              MySwal.fire({
                title: "NIEPRAWIDŁOWE HASŁO",
                icon: "error",
              }).then((results) => {
                setTimeout(window.location.reload(false), 0);
              });
            }
          });
        });
  }

  componentDidMount() {
    this.showLoginInput();
    this.fetchAllLeads();
    this.fetchDetailLeads();
  }

  componentDidUpdate() {}

  handleAdminMenuChange = (e) => {
    if (e.target.name === "leads") {
      this.fetchAssingmentsForCompany();
      this.setState((prevState) => ({
        page: 2,
      }));
    } else if (e.target.name === "description") {
      this.fetchLogoUrl();
      this.fetchCompanyTitle();
      this.fetchImageUrl();
      this.fetchCompanyDescription();
      this.fetchCompanyDescriptionFirst();
      this.fetchCompanyTitleFirst();
      this.fetchCompanyCaseCount();
      this.fetchCompanyCaseCountFirst();
      this.fetchCompanyMarketYear();
      this.fetchCompanyMarketYearFirst();
      this.fetchCompanyWinCases();
      this.fetchCompanyWinCasesFirst();
      this.fetchCompanyWinRealCases();
      this.fetchCompanyWinRealCasesFirst();

      this.setState((prevState) => ({
        page: 3,
        imageImageRefreshFlag: 1,
        logoImageRefreshFlag: 1,
        titleRefreshFlag: 1,
        descriptionRefreshFlag: 1,
        caseCountRefreshFlag: 1,
        marketYearRefreshFlag: 1,
        winCasesRefreshFlag: 1,
        winRealCasesRefreshFlag: 1,
      }));
    } else if (e.target.name === "offer") {
      this.fetchCompanyOffer();
      this.setState((prevState) => ({
        page: 4,
      }));
    } else if (e.target.name === "data") {
      this.fetchCompanyAddressInput();
      this.fetchCompanyPersonInput();
      this.fetchCompanyRegionInput();
      this.fetchCompanyMailInput();
      this.fetchCompanyPhoneInput();
      this.fetchAgreements();
      this.fetchCompanyAllowedBanksInput();
      this.fetchCompanyAllowedRegionsInput();
      this.setState((prevState) => ({
        page: 5,
      }));
    } else if (e.target.name === "users") {
      this.setState((prevState) => ({
        page: 6,
      }));
    } else if (e.target.name === "leadsPendingConsultant") {
      this.fetchAssingmentsLeads();
      this.setState((prevState) => ({
        page: 7,
      }));
    } else if (e.target.name === "leadsAssingmentsConsultant") {
      this.setState((prevState) => ({
        page: 8,
      }));
    } else if (e.target.name === "leadsArchiveConsultant") {
      this.setState((prevState) => ({
        page: 12,
      }));
    } else if (e.target.name === "leadsIndividualCampaign") {
      this.setState((prevState) => ({
        page: 9,
      }));
    } else if (e.target.name === "leadsDashboard") {
      this.setState((prevState) => ({
        page: 10,
      }));
    } else if (e.target.name === "judgments") {
      this.setState((prevState) => ({
        page: 11,
      }));
    }
  };

  refreshPage = (e) => {
    swal({
      title: "Odświeżanie danych...",
      text: "",
      icon: "info",
      timer: 1000,
      buttons: false,
    });
    this.fetchCompanies();
    this.fetchAllLeads();
    this.fetchDetailLeads();
    this.fetchAssingmentsLeads();
    this.setState((prevState) => ({
      page: +e.target.value,
    }));
  };

  handleCompanyAddressInputUpdate = () => {
    this.fetchCompanyAddressInput();
  };

  handleCompanyRegionInputUpdate = () => {
    this.fetchCompanyRegionInput();
  };

  handleCompanyPersonInputUpdate = () => {
    this.fetchCompanyPersonInput();
  };

  handleCompanyAllowedBanksInputUpdate = () => {
    this.fetchCompanyAllowedBanksInput();
  };

  handleCompanyAllowedRegionsInputUpdate = () => {
    this.fetchCompanyAllowedRegionsInput();
  };

  getLeadsBasicData = () => {
    this.fetchAllLeads();
    return this.state.leadsBasicData;
  };

  getLeadsDetailData = () => {
    this.fetchDetailLeads();
    return this.state.leadsDetailData;
  };

  render() {
    return (
      <>
        <HeaderAdmin />
        <div className="mainContainerAdminFirstPage">
          <div className="contentAdminFirstPage">
            <div className="menuAdminContainer">
              <AdminMenu
                handleAdminMenuChange={this.handleAdminMenuChange}
                userDetails={this.state.user}
              />
            </div>
            <div className="functionalitesFirstPage">
              {this.state.actualPage}
              {this.state.page === 2 ? (
                <AdminCompanyLeads company={this.state.company} />
              ) : this.state.page === 3 ? (
                <AdminCompanyData company={this.state.company} />
              ) : this.state.page === 4 ? (
                <AdminCompanyOffer company={this.state.company} />
              ) : this.state.page === 5 ? (
                <AdminCompanyDetails
                  company={this.state.company}
                  agreements={this.state.agreements}
                />
              ) : this.state.page === 6 ? (
                <AdminCompanyUsers />
              ) : this.state.page === 7 ? (
                <ConsultantPendingLeads
                  leadsBasicData={this.state.leadsBasicData}
                  leadsAssingmentsData={this.state.leadsAssingmentsData}
                  leadsDetailData={this.state.leadsDetailData}
                  refreshPage={this.refreshPage}
                  companiesList={this.state.companies}
                  addAssingmentsToDatabase={this.fetchNewLeadAssingments}
                  companiesData={this.state.companiesData}
                  user={this.state.user}
                />
              ) : this.state.page === 8 ? (
                <ConsultantAssingmentsLeads
                  leadsBasicData={this.state.leadsBasicData}
                  leadsAssingmentsData={this.state.leadsAssingmentsData}
                  leadsDetailData={this.state.leadsDetailData}
                  refreshPage={this.refreshPage}
                  companiesList={this.state.companies}
                  addAssingmentsToDatabase={this.fetchNewLeadAssingments}
                  user={this.state.user}
                />
              ) : this.state.page === 12 ? (
                <ConsultantArchiveLeads
                  leadsBasicData={this.state.leadsBasicData}
                  leadsAssingmentsData={this.state.leadsAssingmentsData}
                  leadsDetailData={this.state.leadsDetailData}
                  refreshPage={this.refreshPage}
                  companiesList={this.state.companies}
                  addAssingmentsToDatabase={this.fetchNewLeadAssingments}
                  user={this.state.user}
                />
              ) : this.state.page === 9 ? (
                <ConsultantIndividualCampaign
                  leadsBasicData={this.state.leadsBasicData}
                  leadsAssingmentsData={this.state.leadsAssingmentsData}
                  leadsDetailData={this.state.leadsDetailData}
                  refreshPage={this.refreshPage}
                  companiesList={this.state.companies}
                  addAssingmentsToDatabase={this.fetchNewLeadAssingments}
                  companies={this.state.companies}
                  companiesData={this.state.companiesData}
                  user={this.state.user}
                />
              ) : this.state.page === 10 ? (
                <Dashboard
                  leadsBasicData={this.state.leadsBasicData}
                  leadsAssingmentsData={this.state.leadsAssingmentsData}
                  leadsDetailData={this.state.leadsDetailData}
                  refreshPage={this.refreshPage}
                  companiesList={this.state.companies}
                  addAssingmentsToDatabase={this.fetchNewLeadAssingments}
                  companies={this.state.companies}
                />
              ) : this.state.page === 11 ? (
                <AdminCompanyJudgments company={this.state.company} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdminPanel;
