import './components/Styles/App.css';
import Footer from './components/Footer/Footer';
import Comparer from './pages/Comparer/Comparer';
import AdminPanel from './pages/Admin/Panel/AdminPanel';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, {Component} from 'react';

class App extends Component {

  render(){
  return (
    <>
      
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Comparer />} />
              <Route path="admin" element={<AdminPanel />} />
              <Route path="comparer" element={<Comparer />} />
          </Routes>
        </BrowserRouter>
      <Footer />
    </>
  );
}
}

export default App;
