import React from 'react';
import '../../../components/Styles/ToggleSwitch.css';

const ToggleSwitch = (props) => {
    return ( 
        <>
            <label id="currencyChoose" className="toggle-switchFP">
            <input type="checkbox" checked={props.currencyToggleChecked} onChange={props.onChangeCreditCurrencyToggle}/>
            <span className="switch" />
            </label>
        </>
     );
}
 
export default ToggleSwitch;