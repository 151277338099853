import React, { useState } from 'react';
import '../../../components/Styles/ThirdPage.css';
import '../../../components/Styles/FormStyle.css';
import ToggleSwitchYesNo from './ToggleSwitchYesNo';
import CreditLengthChange from './CreditLengthChange';
import CreditMarginChange from './CreditMarginChange';
import CreditSubsequentsTranchesChange from './CreditSubsequentsTranchesChange';
import CreditEarlyRepaymentChange from './CreditEarlyRepaymentChange';
import swal from 'sweetalert';
import { faEnvelope } from '@fortawesome/fontawesome-free-regular';
import { faFlagCheckered, faMapPin, faMoneyBill, faPhone } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from 'emailjs-com';
import ReactPlayer from 'react-player'

const ThirdPage = (props) => {
    const [creditMargin, setCreditMargin] = useState(props.creditMargin);
    const [creditLength, setCreditLength] = useState(props.creditLength);
    const [creditType, setCreditType] = useState(props.creditType);
    const [creditGracePeriod, setCreditGracePeriod] = useState(0);
    const [showDetailsConfig, setShowDetailsConfig] = useState(false);

    const changeCreditMargin = (e) => {
        var value = e.target.value.replaceAll(",",".");
        setCreditMargin(value);
        props.onChangeCreditMargin(e);
    }

    const changeCreditLength = (e) => {
        setCreditLength(e.target.value);
        props.onChangeCreditLength(e);
    }

    const changeCreditType = (e) => {
        setCreditType(e.target.value);
        props.onChangeCreditType(e);
    }

    const changeCreditGracePeriod = (e) => {
        setCreditGracePeriod(e.target.value);
        props.onChangeCreditGracePeriod(e);
    }

    const checkDigit = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
    }

    const checkDigitFloat = (e) => {
        if (!(/[0-9]/.test(e.key) || /\./.test(e.key) || /\,/.test(e.key))) {
            e.preventDefault();
          }
    }

    const fillRequiredFieldsAlert = () => {
        var txt = ``;
        if(!creditMargin){
            txt = txt+'Marża banku \n';
        }
        if(!creditLength){
            txt = txt+'Okres spłaty kredytu \n';
        }
        if(!creditType){
            txt = txt+'Rodzaj spłaty rat kredytu \n';
        }

        return txt;
    }

    const submitCalculationForm = (e) => {
        var requiredFields = fillRequiredFieldsAlert();
        if(validateForm()){
            props.setNewCalculate(e);
            sendAnalysisMail();
        }else{
            swal({
                icon: "error",
                text: "Należy uzupełnić wymagane pola formularza!",
                button: {
                  text: "Ok",
                },
              });
        }
    }

    const validateForm = () => {
        if(!creditMargin || !creditLength || !creditType){
            return false;
        }else {
            props.changeValidate(3);
            return true;
        }
    }

    const focus = (e) => {
        e.target.select();
    }

    const sendAnalysisMail = () =>{
        emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
        var templateParams = {
          name: props.userName,
          mail: props.userMail,
        };    
             
        emailjs.send('Porównywarka CHF', 'template_hbln1il', templateParams)
        .then(function(response) {
            
        }, function(error) {
              
        });
    }

    return(
        <>
            <div className="mainContainerThird">
                <div className="contentThird">
                    <div className="formContainerThird">
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="creditMargin">Marża banku (w procentach)
                                <span className="requiredStar">*</span>
                                </label>
                            </div>
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="creditLength">Okres kredytowania (w miesiącach)
                                <span className="requiredStar">*</span>
                                </label>
                            </div>
                        </div>
                        <div className="twoColThird">
                            <div className="twoCol_columnThird">
                                <input id={creditMargin ? "creditMargin" : "requiredInput"} type="number" pattern="\d+" min={0} max={100} step={0.1} onFocus={focus} name="creditMargin" value={creditMargin} onChange={changeCreditMargin} onKeyPress={checkDigitFloat}/>
                            </div>
                            <div className="twoCol_columnThird">
                                <input id={creditLength ? "creditLength" : "requiredInput"} type="number" name="creditLength" onFocus={focus} value={props.creditLength} onChange={changeCreditLength} onKeyPress={checkDigit}/>
                            </div>
                        </div>
                    
                    </div>
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="creditType">Rodzaj spłaty rat
                                <span className="requiredStar">*</span>
                                </label>
                            </div>
                            
                        </div>
                        <div className="twoColThird">
                            <div className="twoCol_columnThird">
                                <select id={creditType ? "creditType" : "requiredInput"} name="creditType" className="currencySelect" value={creditType} onChange={changeCreditType}>
                                    {!creditType ? <option value="null">Wybierz...</option> : ''}
                                    <option value="równe">Równe</option>
                                    <option value="malejące">Malejące</option>
                                </select>
                            </div>
                            
                        </div>
                    </div>
                    <div className="showDetailsConfigBox" onClick={()=>{setShowDetailsConfig(!showDetailsConfig)}}>
                        <div className="showDetailsConfigBox_col1">
                            {showDetailsConfig ? 
                            'Ukryj opcje zaawansowane'
                            :
                            'Pokaż opcje zaawansowane'
                            }
                            
                        </div>
                        <div className="showDetailsConfigBox_col2">
                        
                        {showDetailsConfig ? 
                        '↑'
                        :
                        '↓'
                        }
                        </div>
                    </div>
                    <div className={showDetailsConfig ? "showedBox_Details" : "hiddenBox_Details"}>
                    <div className="rowThird">
                    
                        <div className="twoColThird">
                            <div className="twoCol_columnThird">
                                
                            </div>
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="creditGracePeriod">Wakacje kredytowe (w miesiącach)
                                </label>
                            </div>
                        </div>
                        <div className="twoColThird">
                            <div className="twoCol_columnThird">
                                <span className="infoText">
                                    UWAGA! Zalecam, aby dane zawarte w tej sekcji uzupełniać wspólnie z naszym konsultantem, do którego kontakt znajdziesz poniżej.
                                </span>
                                
                            </div>
                            <div className="twoCol_columnThird">
                                <input id={creditGracePeriod ? "creditGracePeriod" : "requiredInput"} type="number" onFocus={focus} name="creditGracePeriod" value={creditGracePeriod} onChange={changeCreditGracePeriod} onKeyPress={checkDigit}/>
                            </div>
                        </div>
                    </div>
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="bankChoose">Czy kredyt spłacono w całości?</label>
                            </div>
                            <div id="twoCol_columnThird_center" className="twoCol_columnThird">
                                <ToggleSwitchYesNo 
                                    id="finalizedCredit"
                                    name="finalizedCredit"
                                    onChangeThirdPageToggle = {props.onChangeThirdPageToggle}
                                    toggleChecked = {props.creditFinalizedToggleChecked}
                                />
                            </div>
                        </div>
                        <div id={props.creditFinalized === 'No' ? 'hidden_box' : 'oneCol_columnThird_narrow'} className="oneColThird">
                            <div id="twoCol_columnThird_title" className="oneCol_columnThird">
                                <label htmlFor="creditFinalDate">Data całkowitej spłaty kredytu</label>
                            </div>
                            <div id="" className="oneCol_columnThird">
                                <input type="date" id="creditFinalDate" name="creditFinalDate" value={props.creditFinalDate} onChange={props.onChangeCreditFinalDate}/>
                            </div>
                        </div>
                    </div>
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="bankChoose">Zmiany w okresie kredytowania?</label>
                            </div>
                            <div id="twoCol_columnThird_center" className="twoCol_columnThird">
                                <ToggleSwitchYesNo 
                                id="creditLengthChange"
                                name="creditLengthChange"
                                onChangeThirdPageToggle = {props.onChangeThirdPageToggle}
                                toggleChecked = {props.creditLengthToggleChecked}
                                />
                            </div>
                        </div>
                    </div>
                    <div id={props.creditLengthChange === 'No' ? 'hidden_box' : 'oneCol_columnThird_full'} className="oneColThird">
                            <div id="twoCol_columnThird_title" className="oneCol_columnThird">
                                <CreditLengthChange 
                                    creditLengthChanges = {props.creditLengthChanges}
                                    handleActualCreditLengthChangeRowDateChange = {props.handleActualCreditLengthChangeRowDateChange}
                                    handleActualCreditLengthChangeRowValueChange = {props.handleActualCreditLengthChangeRowValueChange}
                                    actualCreditLengthChangeRowDate = {props.actualCreditLengthChangeRowDate}
                                    actualCreditLengthChangeRowValue = {props.actualCreditLengthChangeRowValue}
                                    handleAddCreditLengthChange = {props.handleAddCreditLengthChange}
                                    handleDeleteCreditLengthChange = {props.handleDeleteCreditLengthChange}
                                    handleFocus = {props.handleFocus}
                                />
                            </div>
                        </div>
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="bankChoose">Zmiany w marzy?</label>
                            </div>
                            <div id="twoCol_columnThird_center" className="twoCol_columnThird">
                                <ToggleSwitchYesNo 
                                    id="creditMarginChange"
                                    name="creditMarginChange"
                                    onChangeThirdPageToggle = {props.onChangeThirdPageToggle}
                                    toggleChecked = {props.creditMarginToggleChecked}
                                />
                            </div>
                        </div>
                    </div>
                    <div id={props.creditMarginChange === 'No' ? 'hidden_box' : 'oneCol_columnThird_full'} className="oneColThird">
                        <div id="twoCol_columnThird_title" className="oneCol_columnThird">
                            <CreditMarginChange 
                                    creditMarginChanges = {props.creditMarginChanges}
                                    handleActualCreditMarginChangeRowDateChange = {props.handleActualCreditMarginChangeRowDateChange}
                                    handleActualCreditMarginChangeRowValueChange = {props.handleActualCreditMarginChangeRowValueChange}
                                    actualCreditMarginChangeRowDate = {props.actualCreditMarginChangeRowDate}
                                    actualCreditMarginChangeRowValue = {props.actualCreditMarginChangeRowValue}
                                    handleAddCreditMarginChange = {props.handleAddCreditMarginChange}
                                    handleDeleteCreditMarginChange = {props.handleDeleteCreditMarginChange}
                                    handleFocus = {props.handleFocus}
                                />
                        </div>
                    </div>
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="bankChoose">Kolejne transze kredytu?</label>
                            </div>
                            <div id="twoCol_columnThird_center" className="twoCol_columnThird">
                                <ToggleSwitchYesNo 
                                    id="creditSubsequentsTranchesChange"
                                    name="creditSubsequentsTranchesChange"
                                    onChangeThirdPageToggle = {props.onChangeThirdPageToggle}
                                    toggleChecked = {props.creditSubsequentsTranchesToggleChecked}
                                />
                            </div>
                        </div>
                    </div>
                    <div id={props.creditSubsequentsTranchesChange === 'No' ? 'hidden_box' : 'oneCol_columnThird_full'} className="oneColThird">
                        <div id="twoCol_columnThird_title" className="oneCol_columnThird">
                            <CreditSubsequentsTranchesChange 
                                    creditSubsequentsTranchesChanges = {props.creditSubsequentsTranchesChanges}
                                    handleActualCreditSubsequentsTranchesChangeRowDateChange = {props.handleActualCreditSubsequentsTranchesChangeRowDateChange}
                                    handleActualCreditSubsequentsTranchesChangeRowValueChange = {props.handleActualCreditSubsequentsTranchesChangeRowValueChange}
                                    actualCreditSubsequentsTranchesChangeRowDate = {props.actualCreditSubsequentsTranchesChangeRowDate}
                                    actualCreditSubsequentsTranchesChangeRowValue = {props.actualCreditSubsequentsTranchesChangeRowValue}
                                    handleAddCreditSubsequentsTranchesChange = {props.handleAddCreditSubsequentsTranchesChange}
                                    handleDeleteCreditSubsequentsTranchesChange = {props.handleDeleteCreditSubsequentsTranchesChange}
                                    handleActualCreditSubsequentsTranchesChangeRowCurrencyChange = {props.handleActualCreditSubsequentsTranchesChangeRowCurrencyChange}
                                    actualCreditSubsequentsTranchesChangeRowCurrency = {props.actualCreditSubsequentsTranchesChangeRowCurrency}
                                    handleFocus = {props.handleFocus}
                                />
                        </div>
                    </div>
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div id="twoCol_columnThird_title" className="twoCol_columnThird">
                                <label htmlFor="bankChoose">Wcześniejsze spłaty kredytu?</label>
                            </div>
                            <div id="twoCol_columnThird_center" className="twoCol_columnThird">
                                <ToggleSwitchYesNo 
                                    id="creditEarlyRepaymentChange"
                                    name="creditEarlyRepaymentChange"
                                    onChangeThirdPageToggle = {props.onChangeThirdPageToggle}
                                    toggleChecked = {props.creditEarlyRepaymentToggleChecked}
                                />
                            </div>
                        </div>
                    </div>
                    <div id={props.creditEarlyRepaymentChange === 'No' ? 'hidden_box' : 'oneCol_columnThird_full'} className="oneColThird">
                        <div id="twoCol_columnThird_title" className="oneCol_columnThird">
                            <CreditEarlyRepaymentChange 
                                    creditEarlyRepaymentChanges = {props.creditEarlyRepaymentChanges}
                                    handleActualCreditEarlyRepaymentChangeRowDateChange = {props.handleActualCreditEarlyRepaymentChangeRowDateChange}
                                    handleActualCreditEarlyRepaymentChangeRowValueChange = {props.handleActualCreditEarlyRepaymentChangeRowValueChange}
                                    actualCreditEarlyRepaymentChangeRowDate = {props.actualCreditEarlyRepaymentChangeRowDate}
                                    actualCreditEarlyRepaymentChangeRowValue = {props.actualCreditEarlyRepaymentChangeRowValue}
                                    handleAddCreditEarlyRepaymentChange = {props.handleAddCreditEarlyRepaymentChange}
                                    handleDeleteCreditEarlyRepaymentChange = {props.handleDeleteCreditEarlyRepaymentChange}
                                    handleActualCreditEarlyRepaymentChangeRowCurrencyChange = {props.handleActualCreditEarlyRepaymentChangeRowCurrencyChange}
                                    actualCreditEarlyRepaymentChangeRowCurrency = {props.actualCreditEarlyRepaymentChangeRowCurrency}
                                    handleFocus = {props.handleFocus}
                                />
                        </div>
                    </div>
                    </div>

                    <div className="rowThird">
                                    <button id="thirdComparerTriggerBox" className="comparerTriggerBox" onClick={submitCalculationForm}>
                                        <div className="analysisTriggerBoxDescSection">
                                            <p className="analysisTriggerBoxDescSectionTitle">
                                                PORÓWNAJ OFERTY
                                            </p>
                                            <p className="analysisTriggerBoxDescSectionDesc">
                                            i wybierz swoją kancelarię CHF
                                            </p>
                                        </div>
                                        <div className="analysisTriggerBoxImageBox">
                                            <img className="analysisTriggerBoxImage" src="https://porownywarkachfapi.ftpeople.pl/second_page_1.png"/>
                                        </div>  
                                    </button>
                    </div>
                    
                    <div className="rowThird">
                        <div className="twoColThird">
                            <div className="twoCol_columnThird">
                                <button className="submitButtonThirdPage" onClick={props.onClickPrevoiusPage}>← Wstecz</button>
                            </div>
                            <div className="twoCol_columnThird">
                            
                            
                            </div>

                        </div>
                    </div>
                    <div className="rowSecondMobile">
                    <div className="formTitleASecond">
                                    <label htmlFor="bankChoose">Taki widok zobaczysz po przejściu dalej
                                    </label>
                    </div>
                    <div style={{marginBottom:"5vh"}} className="secondImageCenter">
                    <ReactPlayer
                        width="100%"
                        height="100%"
                        loop={true}
                        url='https://porownywarkachf.pl/wp-content/uploads/2023/08/FILM-PCHF.mp4' 
                        playing={true}
                        />
                    </div>
                    <div className="contact_first_page">
                                        <div className="contact_first_page_box1">
                                            <img className="contact_first_page_ico" src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"/>
                                        </div>
                                        <div className="contact_first_page_box2">
                                            <p className="contact_first_page_title">Potrzebujesz pomocy?</p>
                                            <p className="contact_first_page_desc">
                                                Karolina chętnie Ci pomoże
                                            </p>
                                            <div className="contact_first_page_details_box">
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faPhone}/></div>
                                                    71 331 78 21
                                                </div>
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faEnvelope}/></div>
                                                    <a className="contactHref" href="mailto: biuro@porownywarkachf.pl">biuro@porownywarkachf.pl</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                    </div>
                </div>
                <div className="descContainerThird">
                <div className="formTitleASecond">
                                    <label htmlFor="bankChoose">Taki widok zobaczysz po przejściu dalej
                                    </label>
                    </div>
                    <ReactPlayer
                        width="100%"
                        loop={true}
                        url='https://porownywarkachf.pl/wp-content/uploads/2023/08/FILM-PCHF.mp4' 
                        playing={true}
                        />
                    
                    <div style={{height: "5vh"}}></div>
                    <div className="contact_first_page">
                                        <div className="contact_first_page_box1">
                                            <img className="contact_first_page_ico" src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"/>
                                        </div>
                                        <div className="contact_first_page_box2">
                                            <p className="contact_first_page_title">Potrzebujesz pomocy?</p>
                                            <p className="contact_first_page_desc">
                                                Karolina chętnie Ci pomoże
                                            </p>
                                            <div className="contact_first_page_details_box">
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faPhone}/></div>
                                                    71 331 78 21
                                                </div>
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faEnvelope}/></div>
                                                    <a className="contactHref" href="mailto: biuro@porownywarkachf.pl">biuro@porownywarkachf.pl</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default ThirdPage;