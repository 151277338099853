import React from 'react';
import '../../../components/Styles/ConsultantPendingLeadsData.css';
import { useState } from 'react';
import { useEffect } from 'react';
import PopUpLeadDetails from "./PopUpLeadDetails"; 
import swal from 'sweetalert';
import { ThreeDots } from  'react-loader-spinner';

const ConsultantAssingmentsLeadsData = (props) => {
    const [seenPopUpLeadDetail, setSeenPopUpLeadDetail] = useState(false);
    const [idPopUp, setIdPopUp] = useState(1);
    const [assingmentTable, setAssingmentTable] = useState([]);
    const [loading, setLoading] = useState(false);

const click = (e) => {
    console.log('click');
}

const togglePopLeadDetail = (e) => {
    setSeenPopUpLeadDetail(!seenPopUpLeadDetail);
    setIdPopUp(+e.target.value);
};

const getLeadBasicData = (id) => {
    return props.leadsBasicData.filter(lead => lead.id === id)[0];
}

const getLeadDetailData = (id) => {
    return props.getLeadDetailData(id);
}

const fetchAddPhone = async (value, id) => {

    var leadData = props.leadsBasicData.filter(el => +el.id === +id)[0];
        
    var body = {
        leadId: id,
        note: 'Zmieniono numer telefonu z '+leadData.phone+' na '+value,
        phone: value,
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/update_lead_phone', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Zmieniono numer telefonu",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const fetchAddMail = async (value, id) => {

    var leadData = props.leadsBasicData.filter(el => +el.id === +id)[0];
        
    var body = {
        leadId: id,
        note: 'Zmieniono adres e-mail z '+leadData.mail+' na '+value,
        mail: value,
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/update_lead_mail', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Zmieniono adres e-mail",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const changePhone = async (e) => {
    
    swal({
        icon: "success",
        title: "Zmiana danych",
        text: 'Dodaj nowy numer telefonu',
        content: "input",
        button: {
          text: "Zmień",
          closeModal: true,
        },
      })
      .then(name => {
        if (!name) return;
        fetchAddPhone(name, e.target.value);
      });
  
    //await fetchAddNote(e);
}

const changeMail = async (e) => {
    
    swal({
        icon: "success",
        title: "Zmiana danych",
        text: 'Dodaj nowy adres e-mail',
        content: "input",
        button: {
          text: "Zmień",
          closeModal: true,
        },
      })
      .then(name => {
        if (!name) return;
        fetchAddMail(name, e.target.value);
      });
  
    //await fetchAddNote(e);
}

const fetchNewLeadMark = async (note, leadId) => {
        
    var body = {
        leadId: leadId,
        note: 'Oznaczono: '+note,
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/insert_lead_mark', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Oznaczono",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const markLeadUs = async (e) => {
    swal({
        icon: "success",
        title: "Powód",
        text: 'Dodaj powód oznaczenia leada (pojawi się w notatkach)',
        content: "input",
        button: {
          text: "Dodaj",
          closeModal: true,
        },
      })
      .then(name => {
        if (!name) return;
        fetchNewLeadMark(name, e.target.value);
      });
  
}

const fetchNewLeadDeleteUnUs = async (e) => {
        
    var body = {
        leadId: e.target.value
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/delete_lead_mark', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Odznaczono",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const unmarkLeadUs = async (e) => {
    await fetchNewLeadDeleteUnUs(e);
}

const checkIfMarkUs = (id) => {
    var arr = props.leadsMark.filter(el => +el.userId === +id).length;

    if(arr > 0){
        return true;
    }else{
        return false;
    }
}

const leadTable = props.leadsBasicData.map(lead => (
    <>
        <tr style={checkIfMarkUs(lead.id) ? {backgroundColor: "yellow", color: "black", fontWeight: "600"} : {}} key={lead.id}>
            <td>{lead.name}</td>
            <td>{lead.mail}</td>
            <td>{lead.phone}</td>
            <td>{lead.addDate}</td>
            <td>
                <div style={{justifyContent: "center"}} className="pendingLeadsDataFlexCellContainer">
                    <button className="pendingLeadsDataButton" value={lead.id} onClick={togglePopLeadDetail}>Szczegóły</button>    
                    {props.user.role === 1 ? 
                    <>
                        <button style={{width: "45%"}} className="pendingLeadsDataButton" value={lead.id} onClick={changePhone}>{'Zmień tel.'}</button>
                    </> : ''} 
                    {props.user.role === 1 ? 
                    <>
                        <button style={{width: "45%"}} className="pendingLeadsDataButton" value={lead.id} onClick={changeMail}>{'Zmień mail'}</button>
                    </> : ''}
                    {props.user.role === 1 ? 
                    <>
                        <button style={{width: "45%"}} className="pendingLeadsDataButton" value={lead.id} onClick={checkIfMarkUs(lead.id) ? unmarkLeadUs : markLeadUs}>{checkIfMarkUs(lead.id) ? 'Odznacz' : 'Oznacz'}</button>
                    </> : ''}
                </div>
            </td>
        </tr>
    </>
));

return(
    <>
        <div className="mainContainerConsultantpendingLeadsData">
        {loading ? 
            <ThreeDots color="black" height={50} width={50}/>  
        :
        <>
            <table className="leadsPendingDataTable">
                        <tbody>
                            <tr key="header">
                                <th>Imię i nazwisko</th>
                                <th>Adres e-mail</th>
                                <th>Numer telefonu</th>
                                <th>Data dodania</th>
                                <th>Akcja</th>
                            </tr>
                            {leadTable}
                        </tbody>
                </table> 
                </>}
                {seenPopUpLeadDetail ? 
                <PopUpLeadDetails 
                    id = {idPopUp}
                    toggle = {togglePopLeadDetail}
                    leadBasicData = {getLeadBasicData(idPopUp)}
                    leadDetailsData = {getLeadDetailData(idPopUp)}
                    leadsNote = {props.leadsNote}
                /> : null}
        </div>
    </>
)

}

export default ConsultantAssingmentsLeadsData;