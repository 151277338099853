class Company {
    constructor(id, name, minOffer, detailData, minCaseValue, winCases){
        this.id = id;
        this.name = name;
        this.minOffer = minOffer;
        this.detailData = detailData;
        this.minCaseValue = minCaseValue;
        this.winCases = winCases;
    }
}

export default Company;