class LeadObject {
    constructor(id, name, phone, mail, assingmentDate, assingmentDate2){
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.mail = mail;
        this.assingmentDate = assingmentDate;
        this.assingmentDate2 = assingmentDate2;
    }
}

export default LeadObject;