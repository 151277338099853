import React, { useState } from 'react';
import '../../../components/Styles/LoginPage.css';
import '../../../components/Styles/FormStyle.css';
import { faEnvelope } from '@fortawesome/fontawesome-free-regular';
import { faFlagCheckered, faMapPin, faMoneyBill, faPhone } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoginPage = (props) => {
    const [login, setLogin] = useState('');

    const changeLogin = (e) => {
        setLogin(e.target.value);
        props.changeUserPass(e);
    }

    const checkPass = (e) => {
        props.checkUserPass(e);
    }

    const focus = (e) => {
        e.target.select();
    }

    return(
        <>
            <div className="mainContainerLogin">
                <div className="contentLogin">
                    <div className="loginBlockBox">
                        <div className="loginDescTextContainer">
                            W ciągu maksymalnie 5 minut od wygenerowania analizy na wskazany adres otrzymasz wiadomość e-mail zawierającą hasło logowania do indywidualnego przeglądu kancelarii gotowych zająć się sprawą Twojego kredytu CHF.
                        </div>
                        <div className="formContainerLogin">
                            <input type="text" id={login ? "login" : "requiredInputLogin"} name="login" placeholder="Podaj hasło..." value={login} onChange={changeLogin} onFocus={focus}/>
                            <button className="submitButton" onClick={checkPass}>ZALOGUJ</button> 
                        </div>
                        <div className="rowSecondMobile">
                    <div className="formTitleASecond">
                                    <label htmlFor="bankChoose">Taki widok zobaczysz po przejściu dalej
                                    </label>
                    </div>
                    <div className="secondImageCenter">
                        <img src="https://porownywarkachfapi.ftpeople.pl/second_page_1.png" className="exampleImageSecond"/>
                    </div>
                    <div className="contact_first_page">
                                        <div className="contact_first_page_box1">
                                            <img className="contact_first_page_ico" src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"/>
                                        </div>
                                        <div className="contact_first_page_box2">
                                            <p className="contact_first_page_title">Potrzebujesz pomocy?</p>
                                            <p className="contact_first_page_desc">
                                                Karolina chętnie Ci pomoże
                                            </p>
                                            <div className="contact_first_page_details_box">
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faPhone}/></div>
                                                    71 331 79 02
                                                </div>
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faEnvelope}/></div>
                                                    <a className="contactHref" href="mailto: biuro@porownywarkachf.pl">biuro@porownywarkachf.pl</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                    </div>
                    </div>
                    <div className="descContainerLogin">
                <div className="formTitleASecond">
                                    <label htmlFor="bankChoose">Taki widok zobaczysz po przejściu dalej
                                    </label>
                    </div>
                    <img src="https://porownywarkachfapi.ftpeople.pl/second_page_1.png" className="exampleImageSecond"/>
                    <div className="contact_first_page">
                                        <div className="contact_first_page_box1">
                                            <img className="contact_first_page_ico" src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"/>
                                        </div>
                                        <div className="contact_first_page_box2">
                                            <p className="contact_first_page_title">Potrzebujesz pomocy?</p>
                                            <p className="contact_first_page_desc">
                                                Karolina chętnie Ci pomoże
                                            </p>
                                            <div className="contact_first_page_details_box">
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faPhone}/></div>
                                                    71 331 79 02
                                                </div>
                                                <div className="contact_first_page_details_col">
                                                    <div className="contactIco"><FontAwesomeIcon icon={faEnvelope}/></div>
                                                    <a className="contactHref" href="mailto: biuro@porownywarkachf.pl">biuro@porownywarkachf.pl</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;