import React from 'react';
import '../../../components/Styles/NextButtonBox.css';

const NextButtonBox = (props) => {

    const acceptChoose = (e) => {
        props.onChooseCompanies(props.chooseCompanyArray);
    }

    return(
        <>
        <div className="nextBox">
        <div className="nextBoxCol_second">
                Zatwierdź wybór kancelarii poprzez kliknięcie przycisku. Wybrane podmioty skontaktują się z Tobą, aby przedstawić swoją kancelarię oraz ofertę na prowadzenie sprawy CHF.
        </div>
            <div className="nextBoxCol_first">
                <button className="nextButton" onClick={acceptChoose}>POPROŚ O KONTAKT</button>
            </div>
            
        </div>  
        </>
    )
}

export default NextButtonBox;