import { useState } from 'react';
import { useEffect } from 'react';
import { ThreeDots } from  'react-loader-spinner';
import '../../../components/Styles/AdminDescriptionSection.css';
import swal from 'sweetalert';

const DescriptionSection = (props) => {
    const [companyDescription, setCompanyDescription] = useState(props.description);
    const [companyDescriptionChange, setCompanyDescriptionChange] = useState(false);

    const changeCompanyDescription = (e) => {
        setCompanyDescription(e.target.value);
        setCompanyDescriptionChange(true);
      }

      const fetchDescriptionUpdate = async () => {
        fetch('https://porownywarkachfapi.ftpeople.pl/update_description?company='+encodeURI(props.company), {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({desc: companyDescription})
        })
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

      const sendDescriptionInput = (e) => {
        props.startLoading(true);
        fetchDescriptionUpdate();
      }

return(
    <>
        <div className="mainContainerAdminCompanyData">
            <span className="sectionTitle"> Opis</span>
            <span className="sectionDesc">maksymalnie 1000 znaków. <br/> Dopuszczalne jest używanie znaczników html.</span>
            <textarea className="descritpionAdminInput" type="text" name="title" maxLength="1000" value={companyDescription} onChange={changeCompanyDescription}/>
            {companyDescriptionChange
            ?
            <button className="saveDescriptionButton" onClick={sendDescriptionInput}>Zapisz</button>
            :
            ''
            }
            
        </div>
    </>
)

}

export default DescriptionSection;