import React from "react";
import '../../../components/Styles/PopUpLeadAssingment.css';
import { useState } from 'react';
import { useEffect } from 'react';
import swal from 'sweetalert';
import emailjs from 'emailjs-com';

const PopUpLeadAssingment = (props) => {
    const [assingmentTable, setAssingmentTable] = useState([]);
    const [chooseCompany, setChooseCompany] = useState('');
    
    const handleClick = (e) => {
        props.toggle(e);
    };

    useEffect(() => {
        
    });

    const addToAssingmentTable = (e) => {
        console.log(assingmentTable);
        var arr = assingmentTable;
        var find = arr.length > 0 ? arr.find(element => element === +e.target.value) ? false : true : true;
        if(find){
            if(arr.length >= 5){
                swal({
                    icon: "error",
                    text: "Możesz podać maksymalnie 5 kancelari!",
                    button: {
                      text: "Ok",
                    },
                });
                e.target.checked = false;
                return;
            }
            arr.push(+e.target.value);
        }else{
            arr = removeFromArray(arr,+e.target.value);
        }
        setAssingmentTable(arr);
    }

    const removeFromArray = (arr, value) => {
        for( var i = 0; i < arr.length; i++){ 
            if ( arr[i] === value) { 
                arr.splice(i, 1); 
            }
        }
        return arr;
    }

    const companySelect = (
            props.companiesList.filter(el => +el.active === 1).map(company => (
                <>
                    <label id="company" className="container">
                    <input key={company.id} type="checkbox" value={company.id} onChange={addToAssingmentTable}/>
                    <span class="checkmark"></span>
                    {company.name}
                    </label>
                </>
            ))
    );

    const fetchNewLeadAssingments = async (leadAssingmentsData, leadId) => {
        
        var body = {
            assingments: leadAssingmentsData,
            leadId: leadId
        };

        var data = await fetch('https://porownywarkachfapi.ftpeople.pl/insert_lead_assingments', {
            method: 'POST', 
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            
        })
    }

    const sendAssingmentsToDatabase = (e) => {
        props.addAssingmentsToDatabase(assingmentTable, props.id);
        swal({
            icon: "success",
            text: "Pomyslnie przypisano do bazy",
            button: {
              text: "Ok",
            },
        });
        sendChooseMail();
        sendCompaniesMail();
        props.refreshPage();
    }

    const getCompanyMail = (companyId) => {
        var desc = props.companiesData.find(company => +company.companyId === companyId && company.keyV === "companyMail");
        return desc ? desc.value : '';
    }

    const getCompanyPhone = (companyId) => {
        var desc = props.companiesData.find(company => +company.companyId === companyId && company.keyV === "companyPhone");
        return desc ? desc.value : '';
    }

    const getCompanyTitle = (companyId) => {
        var desc = 'Kancelaria Frankowa';
        desc = props.companiesList.find(company => +company.id === companyId);
        return desc ? desc.name : 'Kancelaria';
    }

    const sendCompaniesMail = () => {
       assingmentTable.map(company => {
            sendCompanyMail(getCompanyMail(company));
        });
    }

    const sendCompanyMail = (companyMail) =>{
        emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
        var templateParams = {
          leadName: props.leadBasicData.name,
          leadMail: props.leadBasicData.mail,
          leadPhone: props.leadBasicData.phone,
          companyMail: companyMail,
        };    
             
        emailjs.send('Porównywarka CHF', 'template_kf0xw3b', templateParams)
        .then(function(response) {
            
        }, function(error) {
              
        });
    }

    const sendChooseMail = () =>{
        emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
        var templateParams = {
          name: props.leadBasicData.name,
          mail: props.leadBasicData.mail,
          company1: getCompanyTitle(assingmentTable[0]),
          company2: (assingmentTable.length >= 2 ? getCompanyTitle(assingmentTable[1]) : ''),
          company3: assingmentTable.length >= 3 ? getCompanyTitle(assingmentTable[2]) : '',
          company4: assingmentTable.length >= 4 ? getCompanyTitle(assingmentTable[3]) : '',
          company5: assingmentTable.length >= 5 ? getCompanyTitle(assingmentTable[4]) : '',
        };    
             
        emailjs.send('Porównywarka CHF', 'template_4e2o2ww', templateParams)
        .then(function(response) {
            
        }, function(error) {
              
        });
    }

    const getCompanyName = (id) => {
        return props.companiesList.filter(company => company.id === id)[0].name;
    }

    const choosenTable = props.assingmentTable.map(company => (
        <>
            <p key={company.id}>{getCompanyName(company)}</p>
        </>
    ))
    
    return (
     <div className="modalPopUpLeadAssingment">
       <div className="modal_contentPopUpLeadAssingment">
       <button className="closePopUpLeadAssingment" value='close' onClick={handleClick}>➜</button>
        <div className="mainContainerPopUpLeadAssingmentSection">
            <div className="asssingmentChoooseBox">
                <div className="asssingmentChoooseBoxFirstCol">
                    {companySelect}
                </div>
                <div className="asssingmentChoooseBoxSecondCol">
                    <button className="popUpLeadAssingmentButton" onClick={sendAssingmentsToDatabase}>ZAPISZ</button> 
                </div>
            </div>
            {choosenTable}
        </div>
      </div>
     </div>
    );
  }

export default PopUpLeadAssingment;