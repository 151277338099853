import { useState, useEffect } from 'react';
import '../../../components/Styles/AdminAddOffer.css';
import ToggleSwitch from './ToggleSwitch';
import swal from 'sweetalert';

const AddOffer = (props) => {
    const [entranceeCost, setEntranceeCost] = useState(0);
    const [successFee, setSuccessFee] = useState(0);
    const [installment, setInstallment] = useState(0);
    const [successFeeType, setSuccessFeeType] = useState('%');
    const [successFeeTypeInput, setSuccessFeeTypeInput] = useState(false);
    const [installmentSwitch, setInstallmentSwitch] = useState('NIE');
    const [installmentSwitchInput, setInstallmentSwitchInput] = useState(false);
    const [optionsSwitch, setOptionsSwitch] = useState('NIE');
    const [optionsSwitchInput, setOptionsSwitchInput] = useState(false);
    const [options, setOptions] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('benefitValue');

    const fetchOfferInsert = async () => {

        var body = {
            entranceeCost: entranceeCost,
            successFeeType: successFeeType,
            successFee: successFee,
            installmentOn: installmentSwitch === 'NIE' ? 0 : 1,
            installment: installment,
            optionsOn: optionsSwitch === 'NIE' ? 0 : 1,
            options: options,
            name: name,
            type: type,   
        };

        await fetch('https://porownywarkachfapi.ftpeople.pl/insert_offer?company='+encodeURI(props.company), {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.text())
        .then(data => {
            props.getData();
        })
        .catch(error => {
            
        })
    }

    const changeEntranceeCost = (e) => {
        setEntranceeCost(+e.target.value);
    }

    const changeSuccessFee = (e) => {
        setSuccessFee(+e.target.value);
    }

    const changeInstallment = (e) => {
        setInstallment(+e.target.value);
    }

    const changeSuccessFeeType = (e) => {
        if(e.target.checked)
        {
            setSuccessFeeType('PLN');
            setSuccessFeeTypeInput(e.target.checked);
            setSuccessFee(0);
        }else{
            setSuccessFeeType('%'); 
            setSuccessFeeTypeInput(e.target.checked);
            setSuccessFee(0);
        }
    }

    const changeInstallmentSwitch = (e) => {
        if(e.target.checked)
        {
            setInstallmentSwitch('TAK');
            setInstallmentSwitchInput(e.target.checked);
            setInstallment(0);
        }else{
            setInstallmentSwitch('NIE'); 
            setInstallmentSwitchInput(e.target.checked);
            setInstallment(0);
        } 
    }

    const changeOptionsSwitch = (e) => {
        if(e.target.checked)
        {
            setOptionsSwitch('TAK');
            setOptionsSwitchInput(e.target.checked);
            setOptions('');
        }else{
            setOptionsSwitch('NIE'); 
            setOptionsSwitchInput(e.target.checked);
            setOptions('');
        } 
    }

    const focus = (e) => {
        if(e.target.value === '0'){
            if(e.target.name === 'entranceeCost'){
                setEntranceeCost('');
            }else if(e.target.name === 'successFee'){
                setSuccessFee('');
            }else if(e.target.name === 'installment'){
                setInstallment('');
            }
        }
        e.target.select();
    }

    const checkDigit = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
    }

    const changeOptions = (e) => {
        setOptions(e.target.value);
    }

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changeOfferType = (e) => {
        setType(e.target.value);
        if(e.target.value === 'subscription'){
            setInstallmentSwitch('TAK');
            setInstallmentSwitchInput(true);
            setInstallment(0);
        }else{
            setInstallmentSwitch('NIE');
            setInstallmentSwitchInput(false);
            setInstallment(0);
        }
    }

    const insertOffer = (e) => {
        if(validateForm()){
            if(type === "subscription" && !(+installment>0)){
                swal({
                    icon: "error",
                    text: "W przypadku abonamentu naley wprowadzić ilość rat większą niż 0!",
                    button: {
                      text: "Ok",
                    },
                  }).then(() => {
                    return;   
                  });
            }else{
                props.startLoading(true);
                fetchOfferInsert();
                setEntranceeCost(0);
                setSuccessFeeType('%');
                setSuccessFee(0);
                setInstallmentSwitch('NIE');
                setInstallmentSwitchInput(false);
                setInstallment(0);
                setOptionsSwitch('NIE');
                setOptionsSwitchInput(false);
                setOptions('');
                setName('');
                setType('benefitValue');
            }
        }else{
            swal({
                icon: "error",
                text: "Należy uzupełnić wymagane pola formularza!",
                button: {
                  text: "Ok",
                },
              });
        }
        
    }

    const validateForm = () => {
        if((entranceeCost === 0 && successFee === 0) || name === ''){
            return false;
        }
        return true;
    }


return(
    <>
        <div className="companyAddOfferMainContainer">
            <div className="companyAddOfferBox">
                <div className="addOfferInputBreak">Warunki podstawowe</div>
                <div id="firstRowAddOffer" className="addOfferInputBox">
                    <div className="addOfferInputTitle">Rodzaj oferty</div>
                    <select name="offerType" id="offerType" className="addOfferSelect" value={type} onChange={changeOfferType}>
                            <option key={0} value='benefitValue'>SF od wartości korzyści</option>
                            <option key={1} value='creditValue'>SF od wartości kredytu</option>
                            <option key={2} value='subscription'>Abonament</option>
                    </select>
                </div>
                <div id="breakLine" className="addOfferInputBreak"></div>
                <div className="addOfferInputBox">
                    <div className="addOfferInputTitle">Wartośc opłaty wstepnej</div>
                    <span id="sign"><input pattern="[0-9]*" className="addOfferAdminInput" type="number" name="entranceeCost" value={entranceeCost} onChange={changeEntranceeCost} onFocus={focus} onKeyPress={checkDigit}/>PLN</span>  
                </div>
                <div className="addOfferInputBox">
                    <div className="addOfferInputTitle">Prowizja od sukcesu</div>
                    <div className="addOfferFlexBox">
                        <div className="addOfferFlexSubBoxFirst">
                            <ToggleSwitch
                                value = {successFeeTypeInput}
                                change = {changeSuccessFeeType}
                                class = "toggle-switch"
                            />
                        </div>
                        <div className="addOfferFlexSubBoxSecond">
                            {successFeeType === "%"
                            ?
                                <span id="signFlex"><input pattern="[0-9]*" className="addOfferAdminInput" type="number" name="successFee" value={successFee} onChange={changeSuccessFee} onFocus={focus}/>%</span>   
                            :
                                <span id="signFlex"><input pattern="[0-9]*" className="addOfferAdminInput" type="number" name="successFee" value={successFee} onChange={changeSuccessFee} onFocus={focus}/>PLN</span>   
                            }
                        </div>
                    </div>
                </div>
                <div className="addOfferInputBreak">Warunki dodatkowe</div>
                <div className="addOfferInputBox">
                    <div className="addOfferInputTitle">Płatność ratalna</div>
                    <div className="addOfferFlexBox">
                        <div className="addOfferFlexSubBoxFirst">
                            <ToggleSwitch
                                    value = {installmentSwitchInput}
                                    change = {changeInstallmentSwitch}
                                    class = "toggle-switchInstallment"
                                    disabled = {type === 'subscription' ? true : false} 
                            />
                        </div>
                        <div className="addOfferFlexSubBoxSecond">
                            {installmentSwitch === "TAK"
                            ?
                            <span id="signFlex"><input pattern="[0-9]*" className="addOfferAdminInput" max="10" type="number" name="installment" value={installment} onChange={changeInstallment} onFocus={focus}/>rat</span> 
                            :
                            ''
                            }
                        </div>   
                    </div>
                </div>
                <div id="breakLine" className="addOfferInputBreak"></div>
                <div className="addOfferInputBox">
                    <div className="addOfferInputTitle">Opcje dodatkowe</div>
                    <div className="addOfferFlexBox">
                        <div className="addOfferFlexSubBoxFirst">
                            <ToggleSwitch
                                    value = {optionsSwitchInput}
                                    change = {changeOptionsSwitch}
                                    class = "toggle-switchInstallment"
                            />
                        </div>
                    </div>
                    <div id="addOfferFullWidth" className="addOfferFlexSubBoxSecond">
                            {optionsSwitch === "TAK"
                            ?
                            <>
                            <span className="subTitleInput">Wprowadz listę po przecinku</span>
                            <textarea className="addOfferAdminOptionsInput" max="10" type="number" name="installment" value={options} onChange={changeOptions} onFocus={focus}/>
                            </>
                            :
                            ''
                            }
                        </div>   
                </div>
            </div>
            <div id="companyAddOfferSubmitPart" className="companyAddOfferBox">
                <div className="companyAddOfferSubmitBox">
                    <div className="addOfferInputBreak">Zapisz ofertę</div>
                    <div className="addOfferFlexBox">
                        <div id="addOfferSpecialFirst" className="addOfferFlexSubBoxFirst">
                            <div className="addOfferInputTitle">Nazwa oferty</div>
                            <input className="addOfferAdminInputDefault" type="text" name="entranceeCost" value={name} onChange={changeName} onFocus={focus}/> 
                        </div>
                        <div id="addOfferSpecialSecond" className="addOfferFlexSubBoxSecond">
                            <button className="offerSaveButton" onClick={insertOffer}>ZAPISZ</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>
)

}

export default AddOffer;