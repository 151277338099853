import React from "react";
import "../../../components/Styles/AdminMenu.css";

const AdminMenu = (props) => {
  return (
    <>
      <div className="mainContainerAdminMenu">
        <div className="contentAdminMenu">
          {
            //    props.userDetails.role === 2 ?
            //    <button name="leads" className="menuButton" onClick={props.handleAdminMenuChange}>Dashboard</button>
            //    : ''
          }
          {props.userDetails.role === 2 ? (
            <button
              name="leads"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Leady
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 2 ? (
            <button
              name="description"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Prezentacja kancelarii
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 2 ? (
            <button
              name="offer"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Oferta
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 2 ? (
            <button
              name="data"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Dane
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 2 ? (
            <button
              name="judgments"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Wyroki
            </button>
          ) : (
            ""
          )}

          {props.userDetails.role === 3 || props.userDetails.role === 1 ? (
            <button
              name="leadsDashboard"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Dashboard
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 3 || props.userDetails.role === 1 ? (
            <button
              name="leadsPendingConsultant"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Leady (oczekujące)
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 3 || props.userDetails.role === 1 ? (
            <button
              name="leadsAssingmentsConsultant"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Leady (przypisane)
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 3 || props.userDetails.role === 1 ? (
            <button
              name="leadsIndividualCampaign"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Kampanie indywidualne
            </button>
          ) : (
            ""
          )}
          {props.userDetails.role === 1 ? (
            <button
              name="leadsArchiveConsultant"
              className="menuButton"
              onClick={props.handleAdminMenuChange}
            >
              Archiwum
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default AdminMenu;
