import React from 'react';
import '../../../components/Styles/FifthPage.css';
import swal from 'sweetalert';
import emailjs from 'emailjs-com';
import { Markup } from 'interweave';

const FifthPage = (props) => {

    const clickPrevious = (e) => {
        props.changeStep(e);
    }

    const acceptChoose = (e) => {
        props.addAssingmentsToDatabase(props.chooseCompanies, props.leadId);
        swal({
            title: 'Dziękujemy!',
            text: 'wysłaliśmy Twoje zgłoszenie do kancelarii',
            icon: 'success',
            timer: 3000,
            buttons: false,
        }).then(() => {
            window.location.reload(false);
        });
        sendCompaniesMail();
        sendChooseMail();
        props.changeStep(e);
    }

    const getCompanyDescription = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "description");
        return desc ? desc.descText : 'Kancelaria';
    }

    const getCompanyOfferMin = (companyId) => {
        var desc = props.companiesOffer.filter(company => company.companyId === companyId).shift();
        return desc ? desc.entranceeCost : 0;
    }

    const getCompanyWinCases = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "winCases");
        return desc ? desc.descText : '0';
    }

    const getCompanyWinRealCases = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "winRealCases");
        return desc ? desc.descText : '0';
    }

    const getCompanyCaseCount = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "caseCount");
        return desc ? desc.descText : '0';
    }

    const getCompanyMarketYear = (companyId) => {
        var desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "marketYear");
        return desc ? desc.descText : '0';
    }

    const getCompanyMail = (companyId) => {
        var desc = props.companiesData.find(company => +company.companyId === companyId && company.keyV === "companyMail");
        return desc ? desc.value : '';
    }

    const getCompanyPhone = (companyId) => {
        var desc = props.companiesData.find(company => +company.companyId === companyId && company.keyV === "companyPhone");
        return desc ? desc.value : '';
    }

    const getCompanyTitle = (companyId) => {
        var desc = 'Kancelaria Frankowa';
        desc = props.companiesDescription.find(company => +company.companyId === companyId && company.descType === "title");
        return desc ? desc.descText.replace("<br>"," ") : 'Kancelaria';
    }

    const getCompanyLogo = (companyId) => {
        var logo = props.companiesFiles.find(company => company.companyId === companyId && company.fileType === "logo");
        return logo.fileUrl;
    }

    const getCompanyImage = (companyId) => {
        var image = props.companiesFiles.find(company => company.companyId === companyId && company.fileType === "image");
        return image.fileUrl;
    }

    const companiesList = props.chooseCompanies.map(company => (
        <>
            <div className="companyL">
                <div className="companyChooseLogo">
                    <img className="companyChooseLogoImg" alt={getCompanyTitle(company)} src={getCompanyLogo(company)}/>
                </div>
                <div className="companyChooseTitle">
                    <Markup content={getCompanyTitle(company).replace('<br>',' ')}/><br/>
                </div>
            </div>
        </>
    ));

    const sendChooseMail = () =>{
        emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
        var templateParams = {
          name: props.userName,
          mail: props.userMail,
          company1: getCompanyTitle(props.chooseCompanies[0]),
          company2: props.chooseCompanies.length >= 2 ? getCompanyTitle(props.chooseCompanies[1]) : '',
          company3: props.chooseCompanies.length >= 3 ? getCompanyTitle(props.chooseCompanies[2]) : '',
        };    
             
        emailjs.send('Porównywarka CHF', 'template_ubfbq9z', templateParams)
        .then(function(response) {
            
        }, function(error) {
              
        });
    }

    const sendCompaniesMail = () => {
        props.chooseCompanies.map(company => {
            sendCompanyMail(getCompanyMail(company));
        });
    }

    const sendCompanyMail = (companyMail) =>{
        emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
        var templateParams = {
          leadName: props.userName,
          leadMail: props.userMail,
          leadPhone: props.userPhone,
          companyMail: companyMail,
        };    
             
        emailjs.send('Porównywarka CHF', 'template_kf0xw3b', templateParams)
        .then(function(response) {
            
        }, function(error) {
              
        });
    }

    return(
        <>
             <div className="mainContainerFifth">
                <div className="contentFifth">
                    <div className="descContainerFifth">
                        <p className="chooseText">WYBRANO</p>
                        <div className="companyBoxFifth">
                            {companiesList}
                        </div>
                        <div className="chooseButtonsBox">
                            <button className="choosePreviousButton" value={4} onClick={clickPrevious}>WSTECZ</button>
                            <button className="chooseAcceptButton" value={1} onClick={acceptChoose}>POTWIERDŹ WYBÓR</button>
                        </div>              
                </div>
                </div>
            </div>
        </>
    )
}

export default FifthPage;