import React, { useEffect, useState } from "react";
import Company from "../../../classes/Company";
import "../../../components/Styles/FourthPage.css";
import CompaniesList from "./CompaniesList";
import {
  faEnvelope,
  faCheckCircle,
} from "@fortawesome/fontawesome-free-regular";
import { faPhone } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThreeDots } from "react-loader-spinner";

const FourthPage = (props) => {
  const [chooseSort, setChooseSort] = useState("cena");
  const [sortedCompanies, setSortedCompanies] = useState(null);
  const [companiesFullList, setCompaniesFullList] = useState(null);
  const [showIntroFlag, setShowIntroFlag] = useState(false);
  const [showPanelFlag, setShowPanelFlag] = useState(1);
  const [assingmentPossibilityFlag, setAssingmentPossibilityFlag] = useState(1);
  const [loadingData, setLoadingData] = useState(false);

  const changeSort = (e) => {
    setChooseSort(e.target.value);
    if (e.target.value === "cena") {
      setSortedCompanies(sortCompaniesByEntranceePrice());
    } else if (e.target.value === "koszt") {
      setSortedCompanies(sortCompaniesByCaseCost());
    } else if (e.target.value === "sprawy") {
      setSortedCompanies(sortCompaniesByWinCases());
    }
  };

  var companiesOfferSorted = props.companiesOffer.sort(function (a, b) {
    return a.entranceeCost - b.entranceeCost;
  });

  const sortCompaniesByEntranceePrice = () => {
    var companiesSorted = companiesFullList.sort(function (a, b) {
      return a.minOffer.entranceeCost - b.minOffer.entranceeCost;
    });
    return companiesSorted;
  };

  const sortCompaniesByEntranceePriceFirst = (companies) => {
    var companiesSorted = companies.sort(function (a, b) {
      return a.minOffer.entranceeCost - b.minOffer.entranceeCost;
    });
    return companiesSorted;
  };

  const sortCompaniesByCaseCost = () => {
    var companiesSorted = companiesFullList.sort(function (a, b) {
      return a.minCaseValue - b.minCaseValue;
    });
    return companiesSorted;
  };

  const sortCompaniesByWinCases = () => {
    var companiesSorted = companiesFullList.sort(function (a, b) {
      return b.winCases - a.winCases;
    });
    return companiesSorted;
  };

  const getCompanyMinOffer = (companyId) => {
    var offer = companiesOfferSorted
      .filter((company) => company.companyId === companyId)
      .shift();
    return offer ? offer : null;
  };

  const getCompanyMinCaseValue = (companyId) => {
    var companyOffer = getCompanyOffer(companyId);
    var minCaseValues = [];

    companyOffer.map((offer) => {
      console.log(offer.type);
      if (offer.type === "subscription") {
        if (offer.successFeeType === "PLN") {
          minCaseValues.push(
            (
              offer.entranceeCost * offer.installment +
              offer.successFee
            ).toFixed(0)
          );
        } else {
          minCaseValues.push(
            (
              offer.entranceeCost * offer.installment +
              (offer.successFee / 100) *
                (props.analysis === 1
                  ? props.userDetailData[0].cancelTotalBenefit
                  : props.creditAmmount)
            ).toFixed(0)
          );
        }
      } else if (offer.type === "benefitValue") {
        if (offer.successFeeType === "PLN") {
          minCaseValues.push(
            (offer.entranceeCost + offer.successFee).toFixed(0)
          );
        } else {
          minCaseValues.push(
            (
              offer.entranceeCost +
              (offer.successFee / 100) *
                (props.analysis === 1
                  ? props.userDetailData[0].cancelTotalBenefit
                  : props.creditAmmount)
            ).toFixed(0)
          );
        }
      } else if (offer.type === "creditValue") {
        if (offer.successFeeType === "PLN") {
          minCaseValues.push(
            (
              offer.entranceeCost * offer.installment +
              offer.successFee
            ).toFixed(0)
          );
        } else {
          minCaseValues.push(
            (
              offer.entranceeCost +
              (offer.successFee / 100) * props.creditAmmount
            ).toFixed(0)
          );
        }
      }
    });
    var minCaseValuesSorted = minCaseValues.sort(function (a, b) {
      return a - b;
    });
    return +minCaseValuesSorted.shift();
  };

  const getCompanyOffer = (companyId) => {
    var offer = companiesOfferSorted.filter(
      (company) => company.companyId === companyId
    );
    return offer ? offer : null;
  };

  const getCompanyData = (companyId) => {
    var data = props.companiesData.filter(
      (company) => company.companyId === companyId
    );
    return data ? data : null;
  };

  const getCompanyWinCases = (companyId) => {
    var desc = props.companiesDescription.find(
      (company) =>
        +company.companyId === companyId && company.descType === "winCases"
    );
    return desc ? +desc.descText : 0;
  };

  const checkCompanyAllowedRegion = (companyId) => {
    var data = props.companiesData
      .filter((company) => company.keyV === "companyAllowedRegions")
      .filter((company) => company.companyId === companyId);
    if (data.length > 0) {
      var regions = data[0].value.split("/");
      var result = regions.filter(
        (region) => region === props.userLocalization
      );
      return result.length > 0 ? true : false;
    } else {
      return false;
    }
  };

  const checkCompanyAllowedBanks = (companyId) => {
    var data = props.companiesData
      .filter((company) => company.keyV === "companyAllowedBanks")
      .filter((company) => company.companyId === companyId);
    if (data.length > 0) {
      var regions = data[0].value.split("/");
      var result = regions.filter((region) => region === props.creditBank);
      return result.length > 0 ? true : false;
    } else {
      return false;
    }
  };

  const checkCompanyIsActive = (companyId) => {
    var data = props.companies.filter((company) => company.id === companyId);
    if (data.length > 0) {
      if (data[0].active === 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const buildCompaniesFullList = () => {
    var fullCompaniesList = [];
    props.companies.map((company) => {
      if (
        checkCompanyAllowedRegion(company.id) &&
        checkCompanyAllowedBanks(company.id) &&
        checkCompanyIsActive(company.id)
      ) {
        fullCompaniesList.push(
          new Company(
            company.id,
            company.name,
            getCompanyMinOffer(company.id),
            getCompanyData(company.id),
            getCompanyMinCaseValue(company.id),
            getCompanyWinCases(company.id)
          )
        );
      }
    });
    setCompaniesFullList(sortCompaniesByEntranceePriceFirst(fullCompaniesList));
  };

  const sortSelect = (
    <select
      name="sortChoose"
      className="sortSelect"
      value={chooseSort}
      onChange={changeSort}
    >
      {!chooseSort ? <option value="null">Wybierz...</option> : ""}
      <option key="1" value="cena">
        Najnizsza stawka OW
      </option>
      <option key="2" value="koszt">
        Najnizszy szacowany koszt sprawy
      </option>
      <option key="3" value="sprawy">
        Ilość wygranych spraw
      </option>
    </select>
  );

  useEffect(() => {
    buildCompaniesFullList();
    if (props.userDetailData.length > 0) {
      props.fetchLastAssingmentDate(props.userDetailData[0].leadId);
    }
  }, []);

  useEffect(() => {
    if (!showIntroFlag) {
      const interval = setInterval(() => {
        if (companiesFullList.length > 0) {
          setShowIntroFlag(true);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  });

  const showIntro = () => {
    setShowIntroFlag(true);
  };

  const clickShowPanelFlag = (e) => {
    setShowPanelFlag(+e.target.value);
  };

  const getNewAssingmentDate = () => {
    if (props.newAssingmentDate !== "") {
      return (
        props.newAssingmentDate.getFullYear() +
        "-" +
        ((props.newAssingmentDate.getMonth() + 1).length > 1
          ? props.newAssingmentDate.getMonth() + 1
          : "0" + (props.newAssingmentDate.getMonth() + 1)) +
        "-" +
        (props.newAssingmentDate.getDate().length > 1
          ? props.newAssingmentDate.getDate()
          : "0" + props.newAssingmentDate.getDate())
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="mainContainerFourth">
        <div className="contentFourth">
          <div className="descContainerFourth">
            {props.analysis === 1 ? (
              <div className="panelChooseBox">
                <button
                  className={
                    showPanelFlag === 1
                      ? "submitButtonTabSwitchActive"
                      : "submitButtonTabSwitch"
                  }
                  value={1}
                  onClick={clickShowPanelFlag}
                >
                  PORÓWNANIE OFERT
                </button>
                <button
                  className={
                    showPanelFlag === 2
                      ? "submitButtonTabSwitchActive"
                      : "submitButtonTabSwitch"
                  }
                  value={2}
                  onClick={clickShowPanelFlag}
                >
                  ANALIZA TWOJEGO KREDYTU
                </button>
              </div>
            ) : (
              ""
            )}
            {showPanelFlag === 1 || props.analysis === 0 ? (
              showIntroFlag ? (
                <>
                  <div className="sortBoxFourth">
                    <div className="sortBoxFourth_col1">
                      <div
                        id="twoCol_columnFirst_titleA"
                        className="twoCol_columnFirst"
                      >
                        Sortuj według:
                      </div>
                      {sortSelect}
                    </div>
                    <div className="sortBoxFourth_col2">
                      {assingmentPossibilityFlag === 0 ? (
                        <div className="assingmentInfoText">
                          Dokonali już Państwo wyboru kancelarii CHF gotowych
                          poprowadzić Państwa sprawę. Wybór kolejnych podmiotów
                          będzie możliwy dnia {getNewAssingmentDate()}.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <CompaniesList
                    companies={
                      sortedCompanies ? sortedCompanies : companiesFullList
                    }
                    companiesFiles={props.companiesFiles}
                    companiesDescription={props.companiesDescription}
                    companiesOffer={props.companiesOffer}
                    onClickDetailsAnalysis={props.onClickDetailsAnalysis}
                    analysis={props.analysis}
                    sortMode={chooseSort}
                    onChooseCompanies={props.onChooseCompanies}
                    analysis={props.analysis}
                    userDetailData={props.userDetailData}
                    newAssingmentDate={props.newAssingmentDate}
                    setAssingmentPossibilityFlag={setAssingmentPossibilityFlag}
                    userCreditValue={props.creditAmmount}
                    companyJudgments={props.companyJudgments}
                  />
                  <div className="additionalBoxComparerFourth">
                    <div
                      className={
                        props.analysis === 0
                          ? "additionalBoxComparerFourthCol1"
                          : "additionalBoxComparerFourthCol1None"
                      }
                    >
                      <div className="analysisButtonBoxFourth">
                        <button
                          id="specialTriggerBox"
                          className="analysisTriggerBox"
                          onClick={props.onClickDetailsAnalysis}
                        >
                          <div className="analysisTriggerBoxDescSection">
                            <p className="analysisTriggerBoxDescSectionTitle">
                              WYKONAJ SZCZEGŁOWĄ ANALIZĘ
                            </p>
                            <p className="analysisTriggerBoxDescSectionDesc">
                              i otrzymaj za darmo egzemplarz naszego e-booka
                              "Jak wygrać sprawę frankową?"
                            </p>
                          </div>
                          <div className="analysisTriggerBoxImageBox">
                            <img
                              className="analysisTriggerBoxImage"
                              src="https://porownywarkachfapi.ftpeople.pl/second_page_2.png"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="additionalBoxComparerFourthCol2">
                      <div id="wide_fourth" className="contact_first_page">
                        <div className="contact_first_page_box1">
                          <img
                            className="contact_first_page_ico"
                            src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"
                          />
                        </div>
                        <div className="contact_first_page_box2">
                          <p className="contact_first_page_title">
                            Potrzebujesz pomocy?
                          </p>
                          <p className="contact_first_page_desc">
                            Karolina chętnie Ci pomoże
                          </p>
                          <div className="contact_first_page_details_box">
                            <div className="contact_first_page_details_col">
                              <div className="contactIco">
                                <FontAwesomeIcon icon={faPhone} />
                              </div>
                              71 331 78 21
                            </div>
                            <div className="contact_first_page_details_col">
                              <div className="contactIco">
                                <FontAwesomeIcon icon={faEnvelope} />
                              </div>
                              <a
                                className="contactHref"
                                href="mailto: biuro@porownywarkachf.pl"
                              >
                                biuro@porownywarkachf.pl
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="explainerTextOfferList">
                    <div className="explainerTextOfferListTitle">
                      <ThreeDots color="black" height={50} width={50} />
                      <span className="loadingTextLoadingPopup">
                        Ładowanie ofert kancelarii CHF
                      </span>
                    </div>
                  </div>
                </>
              )
            ) : showPanelFlag === 2 ? (
              <>
                <div className="creditDetailBox">
                  <div className="creditDetailBoxData">
                    <p className="creditDetailBoxDataTitle">
                      Dane kredytobiorcy
                    </p>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Imię i nazwisko:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.userName}
                        </p>
                      </div>
                    </div>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Adres e-mail:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.userMail}
                        </p>
                      </div>
                    </div>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Numer telefonu:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.userPhone}
                        </p>
                      </div>
                    </div>
                    <div className="sepCreditDataBox"></div>
                    <p className="creditDetailBoxDataTitle">Dane kredytu</p>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">Umowa:</p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.creditBank}
                        </p>
                      </div>
                    </div>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Kwota kredytu:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.creditAmmount} {props.creditCurrency}
                        </p>
                      </div>
                    </div>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Data wypłaty kredytu:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.creditDate}
                        </p>
                      </div>
                    </div>
                    <div className="sepCreditDataBox"></div>
                    <p className="creditDetailBoxDataTitle">Analiza kredytu</p>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Pozostały kapitał do spłaty:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.userDetailData[0].plnCreditBalanceNow} PLN
                        </p>
                      </div>
                    </div>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Kapitał wpłacony do banku:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBold">
                          {props.userDetailData[0].plnPayNow} PLN
                        </p>
                      </div>
                    </div>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Analiza prawna umowy:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBoldGreen">
                          UMOWA KREDYTOWA ZAWIERA ZAPISY ABUZYWNE - KWALIFIKUJE
                          SIĘ DO ODZYSKANIA NADPŁACONYCH RAT KREDYTU
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="creditDetailBoxAdd">
                    <p className="creditDetailBoxDataTitle">
                      Korzyści kredytobiorcy
                    </p>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Uniewanienie kredytu:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBoldGreen">
                          {props.userDetailData[0].cancelTotalBenefit} PLN
                        </p>
                      </div>
                    </div>
                    <div className="creditDetailBoxDataDescRow">
                      <div className="creditDetailBoxDataDescRowCol1">
                        <p className="creditDetailBoxDataDescLight">
                          Odfrankowienie kredytu:
                        </p>
                      </div>
                      <div className="creditDetailBoxDataDescRowCol2">
                        <p className="creditDetailBoxDataDescBoldGreen">
                          {props.userDetailData[0].unfranksTotalBenefit} PLN
                        </p>
                      </div>
                    </div>
                    <div className="sepCreditDataBox"></div>
                    <div className="downloadBox">
                      <div className="downloadBox_col1">
                        <p className="creditDetailBoxDataTitle">
                          Pobierz analizę
                        </p>
                        <div className="downloadImageBox">
                          <a
                            href={props.userDetailData[0].analysisLink}
                            target="_blank"
                            download="Analiza Kredytu CHF.pdf"
                          >
                            <img
                              className="downloadImage"
                              src="https://porownywarkachfapi.ftpeople.pl/fourth_page_1.png"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="downloadBox_col2">
                        <p className="creditDetailBoxDataTitle">
                          Pobierz e-book
                        </p>
                        <div className="downloadImageBox">
                          <a
                            href="https://porownywarkachfapi.ftpeople.pl/E-Book_Sprawy_frankowiczów-czyli_jak_wygrać_z_bankiem.pdf"
                            target="_blank"
                            download="E-Book - Jak wygrać z bankiem.pdf"
                          >
                            <img
                              className="downloadImage"
                              src="https://porownywarkachfapi.ftpeople.pl/fourth_page_2.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="sepCreditDataBox"></div>
                    <div id="wide_fourth" className="contact_first_page">
                      <div className="contact_first_page_box1">
                        <img
                          className="contact_first_page_ico"
                          src="https://porownywarkachfapi.ftpeople.pl/first_page_2.png"
                        />
                      </div>
                      <div className="contact_first_page_box2">
                        <p className="contact_first_page_title">
                          Potrzebujesz pomocy?
                        </p>
                        <p className="contact_first_page_desc">
                          Karolina chętnie Ci pomoże
                        </p>
                        <div className="contact_first_page_details_box">
                          <div className="contact_first_page_details_col">
                            <div className="contactIco">
                              <FontAwesomeIcon icon={faPhone} />
                            </div>
                            71 331 78 21
                          </div>
                          <div className="contact_first_page_details_col">
                            <div className="contactIco">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <a
                              className="contactHref"
                              href="mailto: biuro@porownywarkachf.pl"
                            >
                              biuro@porownywarkachf.pl
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthPage;
