import React from "react";
import "../../../components/Styles/CompanyOffer.css";
import { Markup } from "interweave";
import { useState } from "react";

const CompanyOffer = (props) => {
  const [chooseOffer, setChooseOffer] = useState(null);

  const changeOffer = (e) => {
    setChooseOffer(+e.target.value);
  };

  const getOfferSuccessFee = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    return offer ? offer.successFee : 0;
  };

  const getOfferSuccessFeeType = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    return offer ? offer.successFeeType : 0;
  };

  const getOfferEntranceeCost = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    return offer ? offer.entranceeCost : 0;
  };

  const getOfferInstallmentOn = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    return offer ? offer.installmentOn : "";
  };

  const getOfferInstallmentCount = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    return offer ? offer.installment : "";
  };

  const getOfferOptionsOn = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    return offer ? offer.optionsOn : "";
  };

  const getOfferOptions = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    var optionsArr = offer.options.split(",");
    return optionsArr ? (
      <ol className="optionsList">
        {optionsArr.map((option) => (
          <li key={option}>{option}</li>
        ))}
      </ol>
    ) : (
      ""
    );
  };

  const getOfferTypeName = (offerId) => {
    var type = props.companyOfferList.find(
      (offer) => offer.id === offerId
    ).type;

    switch (type) {
      case "benefitValue":
        return "SF od wartości korzyści";
      case "creditValue":
        return "SF od wartości kredytu";
      case "subscription":
        return "Abonament";
      default:
        return "Nie znaleziono";
    }
  };

  const getOfferType = (offerId) => {
    var type = props.companyOfferList.find(
      (offer) => offer.id === offerId
    ).type;
    return type;
  };

  const getOfferTotalValue = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);

    if (offer.type === "subscription") {
      return (
        getOfferEntranceeCost(offer.id) * getOfferInstallmentCount(offer.id) +
        (getOfferSuccessFeeType(offerId) === "PLN"
          ? getOfferSuccessFee(offer.id)
          : (getOfferSuccessFee(offer.id) / 100) *
            (props.analysis === 1
              ? props.userDetailData[0].cancelTotalBenefit
              : props.userCreditValue))
      ).toFixed(0);
    } else if (offer.type === "benefitValue") {
      return (
        getOfferEntranceeCost(offer.id) +
        (getOfferSuccessFeeType(offerId) === "PLN"
          ? getOfferSuccessFee(offer.id)
          : (getOfferSuccessFee(offer.id) / 100) *
            (props.analysis === 1
              ? props.userDetailData[0].cancelTotalBenefit
              : props.userCreditValue))
      ).toFixed(0);
    } else if (offer.type === "creditValue") {
      return (
        getOfferEntranceeCost(offer.id) +
        (getOfferSuccessFeeType(offer.id) === "PLN"
          ? getOfferSuccessFee(offer.id)
          : (getOfferSuccessFee(offer.id) / 100) *
            (props.analysis === 1
              ? props.userDetailData[0].cancelTotalBenefit
              : props.userCreditValue))
      ).toFixed(0);
    }
  };

  const getOfferTotalSFValue = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);
    var offerType = offer.successFeeType;

    if (offer.type === "subscription") {
      if (offerType === "PLN") {
        return getOfferSuccessFee(chooseOffer).toFixed(0);
      } else {
        return (
          (getOfferSuccessFee(chooseOffer) / 100) *
          (props.analysis === 1
            ? props.userDetailData[0].cancelTotalBenefit
            : props.userCreditValue)
        ).toFixed(0);
      }
    } else if (offer.type === "benefitValue") {
      if (offerType === "PLN") {
        return getOfferSuccessFee(chooseOffer).toFixed(0);
      } else {
        return (
          (getOfferSuccessFee(chooseOffer) / 100) *
          (props.analysis === 1
            ? props.userDetailData[0].cancelTotalBenefit
            : props.userCreditValue)
        ).toFixed(0);
      }
    } else if (offer.type === "creditValue") {
      if (offerType === "PLN") {
        return getOfferSuccessFee(chooseOffer).toFixed(0);
      } else {
        return (
          (getOfferSuccessFee(offer.id) / 100) *
          props.userCreditValue
        ).toFixed(0);
      }
    }
  };

  const getTotalLabel = (offerId) => {
    var offer = props.companyOfferList.find((offer) => offer.id === offerId);

    if (offer.type === "creditValue") {
      return `PRZYKŁADOWE ROZLICZENIE DLA KWOTY KREDYTU ${props.userCreditValue} ZŁ`;
    } else if (offer.type === "benefitValue") {
      return props.analysis === 1
        ? `PRZYKŁADOWE ROZLICZENIE DLA KWOTY KORZYŚCI ${props.userDetailData[0].cancelTotalBenefit} ZŁ`
        : `PRZYKŁADOWE ROZLICZENIE DLA KWOTY KORZYŚCI ${props.userCreditValue} ZŁ`;
    } else if (offer.type === "subscription") {
      return props.analysis === 1
        ? `PRZYKŁADOWE ROZLICZENIE DLA KWOTY KORZYŚCI ${props.userDetailData[0].cancelTotalBenefit} ZŁ`
        : `PRZYKŁADOWE ROZLICZENIE DLA KWOTY KORZYŚCI ${props.userCreditValue}  ZŁ`;
    }
  };

  return (
    <>
      <div className="offerBox">
        <div className="offerFirstCol">
          <select
            name="offerChoose"
            className="offerSelect"
            value={chooseOffer}
            onChange={changeOffer}
          >
            {!chooseOffer ? (
              <option value="null">Wybierz wariant...</option>
            ) : (
              ""
            )}
            {props.companyOfferList.map((companyOffer) => (
              <option key={companyOffer.id} value={companyOffer.id}>
                {companyOffer.name}
              </option>
            ))}
          </select>
        </div>
        {chooseOffer ? (
          <div className="offerSecondCol">
            <div className="offerSecondColFirstCol">
              <b>PODSTAWOWE INFORMACJE O OFERCIE</b>
              <br />
              <br />
              Rodzaj oferty: <strong>{getOfferTypeName(chooseOffer)}</strong>
              <br />
              {getOfferType(chooseOffer) === "subscription"
                ? "Opłata miesięczna: "
                : "Opłata wstepna: "}{" "}
              <strong>{getOfferEntranceeCost(chooseOffer)} zł</strong>
              <br />
              Success Fee:{" "}
              <strong>
                {getOfferSuccessFee(chooseOffer)}
                {getOfferSuccessFeeType(chooseOffer) === "PLN" ? " zł" : "%"}
              </strong>
              <br />
              {getOfferInstallmentOn(chooseOffer) === 1 ? "Raty: " : ""}{" "}
              <strong>
                {getOfferInstallmentOn(chooseOffer) === 1
                  ? getOfferInstallmentCount(chooseOffer)
                  : ""}{" "}
              </strong>
            </div>
            {getOfferOptionsOn(chooseOffer) === 1 ? (
              <div className="offerSecondColSecondCol">
                <b>OPCJE DODATKOWE</b>
                <br />
                <br />
                {getOfferOptions(chooseOffer)}
              </div>
            ) : (
              ""
            )}

            <div
              className={
                getOfferOptionsOn(chooseOffer) === 1
                  ? "offerSecondColThirdCol"
                  : "offerSecondColThirdColWide"
              }
            >
              <b>{getTotalLabel(chooseOffer)}</b>
              <br />
              {getOfferType(chooseOffer) === "subscription"
                ? "Opłata miesięczna: "
                : "Opłata wstepna: "}{" "}
              {getOfferEntranceeCost(chooseOffer) + " zł"}
              <br />
              Success Fee: {getOfferTotalSFValue(chooseOffer) + " zł"}
              <br />
              <b>RAZEM: {getOfferTotalValue(chooseOffer) + " ZŁ"}</b>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CompanyOffer;
