import React from 'react';
import '../../../components/Styles/ConsultantPendingLeadsData.css';
import { useState } from 'react';
import { useEffect } from 'react';
import PopUpLeadDetails from "./PopUpLeadDetails"; 
import swal from 'sweetalert';
import { ThreeDots } from  'react-loader-spinner';
import emailjs from 'emailjs-com';

const ConsultantIndividualCampaignData = (props) => {
    const [seenPopUpLeadDetail, setSeenPopUpLeadDetail] = useState(false);
    const [idPopUp, setIdPopUp] = useState(1);
    const [loading, setLoading] = useState(false);

const click = (e) => {
    console.log('click');
}

const togglePopLeadDetail = (e) => {
    setSeenPopUpLeadDetail(!seenPopUpLeadDetail);
    setIdPopUp(+e.target.value);
};

const getLeadBasicData = (id) => {
    return props.leadsBasicData.filter(lead => lead.id === id)[0];
}

const getLeadDetailData = (id) => {
    return props.getLeadDetailData(id);
}

const fetchNewLeadAssingment = async (e) => {
        
    var body = {
        company: props.companyId,
        leadId: e.target.value
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/insert_lead_assingments_individual', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Pomyslnie przypisano do bazy",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            sendCompanyMail(e.target.value);
            sendChooseMail(e.target.value);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const checkIfMarkUs = (id) => {
    var arr = props.leadsMark.filter(el => +el.userId === +id).length;

    if(arr > 0){
        return true;
    }else{
        return false;
    }
}

const sendCompanyMail = (leadId) =>{
    emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
    var templateParams = {
      leadName: props.leadsBasicData.filter(el => +el.id === +leadId)[0].name,
      leadMail: props.leadsBasicData.filter(el => +el.id === +leadId)[0].mail,
      leadPhone: props.leadsBasicData.filter(el => +el.id === +leadId)[0].phone,
      companyMail: props.companiesData.filter(el => +el.companyId === +props.companyId && el.keyV === 'companyMail')[0].value,
    };    
         
    emailjs.send('Porównywarka CHF', 'template_kf0xw3b', templateParams)
    .then(function(response) {
        
    }, function(error) {
          
    });
}

const getCompanyTitle = (companyId) => {
    var desc = 'Kancelaria Frankowa';
    desc = props.companiesList.find(company => +company.id === companyId);
    return desc ? desc.name : 'Kancelaria';
}

const sendChooseMail = (leadId) =>{
    emailjs.init("user_7x2uGk2osoyVegzDMAi4G");
    var templateParams = {
      name: props.leadsBasicData.filter(el => +el.id === +leadId)[0].name,
      mail: props.leadsBasicData.filter(el => +el.id === +leadId)[0].mail,
      company1: getCompanyTitle(props.companyId),
      company2: '',
      company3: '',
      company4: '',
      company5: '',
    };    
         
    emailjs.send('Porównywarka CHF', 'template_4e2o2ww', templateParams)
    .then(function(response) {
        
    }, function(error) {
          
    });
}

const sendLead = async (e) => {
    await fetchNewLeadAssingment(e);
}

const fetchAddNote = async (note, id) => {
        
    var body = {
        leadId: id,
        note: note,
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/insert_lead_note', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Dodano notatkę",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const addNote = async (e) => {
    
    swal({
        icon: "success",
        title: "Notatka",
        text: 'Dodaj notatkę związaną z kontaktem z klientem',
        content: "input",
        button: {
          text: "Dodaj",
          closeModal: true,
        },
      })
      .then(name => {
        if (!name) return;
        fetchAddNote(name, e.target.value);
      });
  
    //await fetchAddNote(e);
}

const fetchNewLeadMark = async (note, leadId) => {
        
    var body = {
        leadId: leadId,
        note: 'Oznaczono: '+note,
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/insert_lead_mark', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Oznaczono",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const markLeadUs = async (e) => {
    swal({
        icon: "success",
        title: "Powód",
        text: 'Dodaj powód oznaczenia leada (pojawi się w notatkach)',
        content: "input",
        button: {
          text: "Dodaj",
          closeModal: true,
        },
      })
      .then(name => {
        if (!name) return;
        fetchNewLeadMark(name, e.target.value);
      });
  
}

const fetchNewLeadDeleteUnUs = async (e) => {
        
    var body = {
        leadId: e.target.value
    };

    await fetch('https://porownywarkachfapi.ftpeople.pl/delete_lead_mark', {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(async data => {
        swal({
            icon: "success",
            text: "Odznaczono",
            button: {
              text: "Ok",
            },
        }).then(async (result) => {
            setLoading(true);
            await props.refreshPage();
          })
    })
    .catch(error => {
        
    })
}

const unmarkLeadUs = async (e) => {
    await fetchNewLeadDeleteUnUs(e);
}

const leadTable = props.leadsBasicData.map(lead => (
    <>
        <tr style={checkIfMarkUs(lead.id) ? {backgroundColor: "yellow", color: "black", fontWeight: "600"} : {}} key={lead.id}>
            <td>{lead.name}</td>
            <td>{lead.mail}</td>
            <td>{lead.phone}</td>
            <td>{lead.addDate}</td>
            <td>
                <div style={{justifyContent: "center"}} className="pendingLeadsDataFlexCellContainer">
                    <button className="pendingLeadsDataButton" value={lead.id} onClick={togglePopLeadDetail}>Szczegóły</button> 
                    <button className="pendingLeadsDataButton" value={lead.id} onClick={sendLead}>Przypisz</button>  
                    {props.user.role === 3 || props.user.role === 1 ? 
                    <>
                        <button style={{width: "100%"}} className="pendingLeadsDataButton" value={lead.id} onClick={addNote}>{'Dodaj notatkę'}</button>
                    </> : ''} 
                    {props.user.role === 1 || props.user.role === 3 ? 
                    <>
                        <button style={{width: "45%"}} className="pendingLeadsDataButton" value={lead.id} onClick={checkIfMarkUs(lead.id) ? unmarkLeadUs : markLeadUs}>{checkIfMarkUs(lead.id) ? 'Odznacz' : 'Oznacz'}</button>
                    </> : ''}  
                </div>
            </td>
        </tr>
    </>
));

return(
    <>
        <div className="mainContainerConsultantpendingLeadsData">
        {loading ? 
            <ThreeDots color="black" height={50} width={50}/>  
        :
        <>
            <table className="leadsPendingDataTable">
                        <tbody>
                            <tr key="header">
                                <th>Imię i nazwisko</th>
                                <th>Adres e-mail</th>
                                <th>Numer telefonu</th>
                                <th>Data dodania</th>
                                <th>Akcja</th>
                            </tr>
                            {leadTable}
                        </tbody>
                </table> 
                </>}
                {seenPopUpLeadDetail ? 
                <PopUpLeadDetails 
                    id = {idPopUp}
                    toggle = {togglePopLeadDetail}
                    leadBasicData = {getLeadBasicData(idPopUp)}
                    leadDetailsData = {getLeadDetailData(idPopUp)}
                    leadsNote = {props.leadsNote}
                /> : null}
        </div>
    </>
)

}

export default ConsultantIndividualCampaignData;